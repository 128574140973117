import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAdmin } from "../common/Permissions";

const DashboardNav = () => {
  const active = window.location.pathname;
  const { auth } = useSelector((state) => ({ ...state }));
  //   console.log(active);
  return (
    <ul className="nav nav-tabs">
      {auth && isAdmin(auth.user) ? (
        <>
          <li className="nav-item">
            <Link
              className={`nav-link ${active === "/dashboard" && "active"}`}
              to="/dashboard"
            >
              Revisar
            </Link>
          </li>
        </>
      ) : (
        <li className="nav-item">
          <Link
            className={`nav-link ${active === "/dashboard" && "active"}`}
            to="/dashboard"
          >
            Comentarios
          </Link>
        </li>
      )}

      <li>
        <Link
          className={`nav-link ${active === "/dashboard/comments" && "active"}`}
          to="/dashboard/comments"
        >
          Comentarios del administrador
        </Link>
      </li>
    </ul>
  );
};

export default DashboardNav;

import React, { useState, useEffect } from "react";
import {
  readUser,
  giveAdminPermissions,
  removeAdminPermissions,
} from "../actions/auth";
import { useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { hasImage } from "../actions/auth";
import ClipLoader from "react-spinners/ClipLoader";
import { GrLinkPrevious } from "react-icons/gr";
import { toast } from "react-toastify";
import { Colors, ProfileColorList } from "../common/Colors";
import { isAdmin, isSuperAdmin } from "../common/Permissions";

const ViewPet = ({ match, history }) => {
  // console.log(match, history);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);

  const ColorList = ProfileColorList;

  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    loadImage();
  }, [user]);

  const loadUser = async () => {
    try {
      let res = await readUser(match.params.userId);
      setUser(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadImage = () => {
    if (user.image && user.image.contentType) {
      setImage(
        `${process.env.REACT_APP_API}/user/image/${match.params.userId}`
      );
      return;
    }

    setImage(null);
    setLoading(false);
  };

  const removePermissions = async () => {
    let userData = new FormData();
    userData.append("userRole", "user");
    try {
      await removeAdminPermissions(user._id, userData, auth.token);
      toast.success(`Permisos revocados a ${user.name}`);
      history.push("/users");
    } catch (error) {
      toast.error(error.response.data);
      console.log(error);
    }
  };

  const givePermissions = async () => {
    let userData = new FormData();
    userData.append("userRole", "admin");
    try {
      await giveAdminPermissions(user._id, userData, auth.token);
      toast.success(`Permisos otorgados a ${user.name}`);
      history.push("/users");
    } catch (error) {
      toast.error(error.response.data);
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">{user.name}</h2>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="d-flex justify-content-center align-items-center mt-4">
            <br />
            {loading ? (
              <ClipLoader loading={loading} size={200} className="m-3" />
            ) : image ? (
              <Avatar
                src={image}
                style={{
                  width: 310,
                  height: 310,
                }}
              />
            ) : (
              <Avatar
                style={{
                  width: 310,
                  height: 310,
                  fontSize: 30,
                  textAlign: "center",
                  padding: "3rem",
                  backgroundColor:
                    ColorList[Math.floor(Math.random() * ColorList.length)],
                }}
              >
                {user.name}
              </Avatar>
            )}
          </div>

          <div className="container-fluid">
            <br />
            <h4 className="text-center">{user.email}</h4>
            <p className="text-center">
              Rol:{" "}
              {isSuperAdmin(user)
                ? "Super-Admin"
                : isAdmin(user)
                ? "Administrador"
                : "Colaborador"}{" "}
              {isAdmin(user)}
            </p>
          </div>

          <div className="d-flex justify-content-center">
            {auth && isSuperAdmin(auth.user) && auth.user._id !== user._id ? (
              isAdmin(user) ? (
                <button
                  onClick={removePermissions}
                  className="btn-turq"
                  style={{ backgroundColor: Colors.blue }}
                >
                  Revocar permisos de administrador
                </button>
              ) : (
                <button
                  onClick={givePermissions}
                  className="btn-turq"
                  style={{ backgroundColor: Colors.blue }}
                >
                  Dar permisos de administrador
                </button>
              )
            ) : null}
            {auth.user._id === user._id ? (
              <p className="text-center tag-blue">Tú</p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPet;

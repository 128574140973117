import { TextField } from "@material-ui/core";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import { isAdmin } from "../../common/Permissions";

const WeightCreateForm = ({
  values,
  setValues,
  handleChange,
  handleSubmit,
}) => {
  const { weight } = values;
  const { auth } = useSelector((state) => ({ ...state }));

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <TextField
          onChange={handleChange}
          fullWidth
          type="number"
          name="weight"
          placeholder="Peso (kg)"
          value={weight}
          id="outlined-name"
        />

        {isAdmin(auth.user) ? (
          <div className="d-flex justify-content-center flex-wrap">
            <DatePicker
              placeholder="Fecha de desparasitación"
              className="form-control mt-3"
              onChange={(date, dateString) =>
                setValues({ ...values, createdAt: dateString })
              }
            />
            <span className="font-italic">
              Si no se selecciona la fecha, se asignará la actual
            </span>
          </div>
        ) : null}
      </div>

      <div className="d-flex justify-content-center">
        <button className="btn-turq">Guardar</button>
      </div>
    </form>
  );
};

export default WeightCreateForm;

const LoginForm = ({
  handleSubmit,
  email,
  setEmail,
  password,
  setPassword,
  goRegister,
  goForgotPw,
}) => (
  <form onSubmit={handleSubmit} className="mt-3">
    <div className="form-group mb-3">
      <label className="form-label">Ingrese su correo electrónico</label>
      <input
        type="email"
        className="form-control"
        placeholder="usuario@maka.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>

    <div className="form-group mb-3">
      <label className="form-label">Ingrese su contraseña</label>
      <input
        type="password"
        className="form-control"
        placeholder="********"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>

    <div className="d-flex justify-content-between align-items-center">
      <button type="button" className="btn-red-txt" onClick={goForgotPw}>
        Olvidé mi contraseña
      </button>
      <button type="button" className="btn-turq" onClick={goRegister}>
        Registrarse
      </button>
      <button type="submit" disabled={!email || !password} className="btn-turq">
        Entrar
      </button>
    </div>
  </form>
);

export default LoginForm;

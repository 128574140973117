// import those pages in App.js
// then based on the path show each components using react-router components
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopNav from "./components/TopNav";
import PrivateRoute from "./components/PrivateRoute";
// components
import Home from "./home/Home";
import Login from "./auth/Login";
import Forgot from "./auth/ForgotPassword";
import NewPassword from "./auth/NewPassword";
import Register from "./auth/Register";
import Dashboard from "./user/Dashboard";
import NewCage from "./cages/NewCage";
import EditCage from "./cages/EditCage";
import ViewCage from "./cages/ViewCage";
import ViewPet from "./pets/ViewPet";
import NewPet from "./pets/NewPet";
import EditPet from "./pets/EditPet";
import EditUser from "./user/EditUser";
import Pets from "./pets/Pets";
import Cages from "./cages/Cages";
import Users from "./user/Users";
import ReadQR from "./qr/ReadQR";
import ViewUser from "./user/ViewUser";
import NotFound from "./components/NotFound";
import TransferPet from "./pets/TransferPet";
import Dewormings from "./control/Dewormings";
import NewDeworming from "./control/NewDeworming";
import NewConsultation from "./control/NewConsultation";
import NewComment from "./control/NewComment";
import NewWeight from "./control/NewWeight";
import NewVaccine from "./control/NewVaccine";
import NewDaily from "./control/NewDaily";
import Actions from "./control/Actions";
import PetActions from "./control/PetActions";
import Showers from "./control/Showers";
import ViewDeworming from "./control/ViewDeworming";
import ViewConsultation from "./control/ViewConsultation";
import ViewShower from "./control/ViewShower";
import ViewClean from "./control/ViewClean";
import ViewComment from "./control/ViewComment";
import ViewDaily from "./control/ViewDaily";
import Consultations from "./control/Consultations";
import ActionsCalendar from "./control/ActionsCalendar";
import StoolRegisters from "./stool/StoolRegisters";
import NewStoolRegister from "./stool/NewStoolRegister";
import DashboardComments from "./user/Comments";
import TestingPage from "./pages/Testing";
import DrivePage from "./pages/Drive";
import TrainingPage from "./pages/Training";
import VisualizeTest from "./components/VisualizeTest";

function App() {
  return (
    <BrowserRouter>
      <TopNav style={{ zIndex: 1000 }} />
      <ToastContainer position="top-center" />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={Forgot} />
        <Route exact path="/register" component={Register} />
        <Route
          path="/reset/:token"
          render={({ match }) => <NewPassword token={match.params.token} />}
        />
        <PrivateRoute exact path="/pets" component={Pets} />
        <PrivateRoute exact path="/cages" component={Cages} />
        <PrivateRoute exact path="/consultations" component={Home} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/actions" component={Actions} />
        <PrivateRoute exact path="/readqr" component={ReadQR} />
        <PrivateRoute exact path="/testing" component={TestingPage} />
        <PrivateRoute exact path="/drive" component={DrivePage} />
        <PrivateRoute exact path="/training" component={TrainingPage} />
        <PrivateRoute
          exact
          path="/actions-calendar"
          component={ActionsCalendar}
        />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute
          exact
          path="/dashboard/comments"
          component={DashboardComments}
        />
        <PrivateRoute exact path="/cages/new" component={NewCage} />
        <PrivateRoute exact path="/pet/actions/:petId" component={PetActions} />
        <PrivateRoute exact path="/pets/new" component={NewPet} />
        <PrivateRoute exact path="/cage/edit/:cageId" component={EditCage} />
        <PrivateRoute exact path="/user/edit/:userId" component={EditUser} />
        <PrivateRoute exact path="/pet/edit/:petId" component={EditPet} />
        <PrivateRoute exact path="/testing/:testId" component={VisualizeTest} />
        <PrivateRoute
          exact
          path="/pet/transferpet/:petId"
          component={TransferPet}
        />
        <PrivateRoute
          exact
          path="/pet/dewormings/:petId"
          component={Dewormings}
        />
        <PrivateRoute
          exact
          path="/pet/consultations/:petId"
          component={Consultations}
        />
        <PrivateRoute
          exact
          path="/pet/newdeworming/:petId"
          component={NewDeworming}
        />
        <PrivateRoute
          exact
          path="/pet/newweight/:petId"
          component={NewWeight}
        />
        <PrivateRoute
          exact
          path="/pet/newconsultation/:petId"
          component={NewConsultation}
        />
        <PrivateRoute
          exact
          path="/addcomment/:actionId"
          component={NewComment}
        />
        <PrivateRoute
          exact
          path="/pet/newvaccine/:petId"
          component={NewVaccine}
        />
        <PrivateRoute exact path="/pet/newdaily/:petId" component={NewDaily} />
        <PrivateRoute exact path="/cage/:cageId" component={ViewCage} />
        <PrivateRoute exact path="/pet/showers/:petId" component={Showers} />
        <PrivateRoute
          exact
          path="/deworming/:actionId"
          component={ViewDeworming}
        />
        <PrivateRoute exact path="/shower/:actionId" component={ViewShower} />
        <PrivateRoute exact path="/clean/:actionId" component={ViewClean} />
        <PrivateRoute exact path="/comment/:actionId" component={ViewComment} />
        <PrivateRoute exact path="/daily/:actionId" component={ViewDaily} />
        <PrivateRoute
          exact
          path="/consultation/:actionId"
          component={ViewConsultation}
        />
        <PrivateRoute exact path="/pet/:petId" component={ViewPet} />
        <PrivateRoute exact path="/user/:userId" component={ViewUser} />
        <PrivateRoute exact path="/stool/:petId" component={StoolRegisters} />
        <PrivateRoute
          exact
          path="/new/stool/:petId"
          component={NewStoolRegister}
        />
        <PrivateRoute path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

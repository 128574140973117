import { useState } from "react";
import { toast } from "react-toastify";
import { forgot } from "../actions/auth";
import ForgotForm from "../components/ForgotForm";
import { useDispatch } from "react-redux";
import { Colors } from "../common/Colors";

const Forgot = ({ history }) => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (email.length <= 0) {
        toast.error("Por favor ingrese su correo electrónico");
        return;
      }
      let res = await forgot({ email });
      if (res.data) {
        dispatch({
          type: "PW_RECOVERED",
          payload: res.data,
        });
        toast.success(
          "Correo enviado con éxito a la dirección ingresada, por favor siga los pasos para recuperar su contraseña."
        );
        setEmail("");
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error(
          "Hubo un error, por favor revise si el correo electrónico se registró previamente en Maka."
        );
      } else if (err.response.status === 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <h2 className="text-white text-center">Olvidé mi Contraseña</h2>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 mb-3">
            <ForgotForm
              handleSubmit={handleSubmit}
              email={email}
              setEmail={setEmail}
              goRegister={() => history.push("/register")}
              goForgotPw={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;

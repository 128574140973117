import React from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import "./Pagination.styles.css";

const Pagination = ({ page, setPage, showNext }) => {
  return (
    <div className="page-switch">
      {page > 0 && (
        <AiFillCaretLeft
          onClick={() => setPage(page - 1)}
          size={50}
          className="icon-pagination"
        />
      )}
      <h3 className="page">Pagina {page + 1}</h3>
      {showNext && (
        <AiFillCaretRight
          onClick={() => setPage(page + 1)}
          size={50}
          className="icon-pagination"
        />
      )}
    </div>
  );
};

export default Pagination;

import React, { useState, useEffect } from "react";
import { getPet } from "../actions/pet";
import { getDeworming } from "../actions/control";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { Avatar } from "@material-ui/core";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const ViewDeworming = ({ match, history }) => {
  const [deworming, setDeworming] = useState({});
  const [pet, setPet] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");

  useEffect(() => {
    loadDeworming();
    loadPet();
    if (!pet) {
      history.back();
    }
  }, []);

  useEffect(() => {
    loadImage();
  }, [pet]);

  const loadDeworming = async () => {
    let res = await getDeworming(match.params.actionId);
    setDeworming(res.data);
  };

  const loadPet = async () => {
    let res = await getPet(history.location.state.petId);
    setPet(res.data);
  };

  const loadImage = () => {
    if (pet.image && pet.image.contentType) {
      setImage(`${process.env.REACT_APP_API}/pet/image/${pet._id}`);
      setLoading(false);
      return;
    }
    setImage(null);
    setLoading(false);
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">{deworming.actionType}</h2>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="d-flex justify-content-center align-items-center mt-4">
            <br />
            {loading ? (
              <ClipLoader loading={loading} size={200} className="m-3" />
            ) : image ? (
              <Avatar
                src={image}
                style={{
                  width: 250,
                  height: 250,
                }}
              />
            ) : (
              <Avatar style={{ width: 250, height: 250, fontSize: 20 }}>
                {pet.name}
              </Avatar>
            )}
          </div>

          <h2 className="text-center mt-3">Perro: {pet.name}</h2>

          <div className="col">
            <br />
            <p className="font-weight-bold">
              Realizado {moment(deworming.createdAt).fromNow()}
            </p>
            <p>
              <strong>Medicamento administrado: </strong>
              {deworming.deworming}
            </p>
            <p>
              <strong>Próxima desparasitasión: </strong>
              {moment(deworming.nextDeworming).fromNow()} (
              {new Date(deworming.nextDeworming).toLocaleDateString()})
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDeworming;

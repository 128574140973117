import axios from "axios";

export const getTrainings = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/training`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createTraining = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/training`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteTraining = async (token, trainingId) =>
  await axios.delete(`${process.env.REACT_APP_API}/training/${trainingId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

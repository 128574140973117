import { useEffect, useState } from "react";
import { getActions, deleteAction } from "../actions/control";
import { useSelector } from "react-redux";
import ActionCard from "../components/cards/ActionCard";
import { toast } from "react-toastify";
import { GrLinkPrevious } from "react-icons/gr";
import { BsFillCalendarFill } from "react-icons/bs";
import { Colors } from "../common/Colors";
import SquareLoader from "react-spinners/SquareLoader";

const Actions = ({ history }) => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const [actions, setActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadActions();
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [actions]);

  const loadActions = async () => {
    try {
      const res = await getActions();
      setActions(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionDelete = async (actionId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Acción?")) return;
    deleteAction(token, actionId).then((res) => {
      toast.success("Acción eliminada");
      window.location.reload();
    });
  };

  const handleShowMore = (actionId, actionType, petId) => {
    if (actionType === "Desparasitación") {
      history.push({
        pathname: `/deworming/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Consulta") {
      history.push({
        pathname: `/consultation/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Vacuna") {
      history.push({
        pathname: `/pet/newvaccine/${petId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Limpieza") {
      history.push({
        pathname: `/cage/${actionId}`,
      });
    }
    if (actionType === "Chequeo Diario") {
      history.push({
        pathname: `/daily/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Baño") {
      history.push({
        pathname: `/shower/${actionId}`,
        state: {
          petId,
        },
      });
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Acciones</h2>
      </div>

      <div className="d-flex justify-content-center">
        <button
          className="btn-turq m-3"
          onClick={() => history.push("/actions-calendar")}
          style={{ fontSize: 20 }}
        >
          Ver calendario
          <BsFillCalendarFill className="ml-2" />
        </button>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <SquareLoader color={Colors.orange} loading={isLoading} size={100} />
          <h1 className="m-3">Cargando...</h1>
        </div>
      ) : (
        <div>
          {actions.map((action) => (
            <ActionCard
              key={action._id}
              action={action}
              handleActionDelete={handleActionDelete}
              handleShowMore={handleShowMore}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Actions;

import React, { useEffect, useState } from "react";
import { Colors } from "../../common/Colors";
import { AiFillDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import { isAdmin, isSuperAdmin } from "../../common/Permissions";
import { deleteTraining } from "../../actions/trainings";
import { DATE_OPTIONS } from "../../common/Dates";
import { Tooltip } from "@material-ui/core";

export default function TrainingCard({ training, loadTrainings }) {
  const [petsStr, setPetsStr] = useState("");
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleDeleteTraining = async (trainingId) => {
    await deleteTraining(token, trainingId);
    loadTrainings();
  };

  const getPetsAsStr = () => {
    const MAX_SHOW_PETS = 3;
    const petsNames = training.pets.map((pet) => pet.name);
    setPetsStr(
      petsNames.length > MAX_SHOW_PETS
        ? petsNames.slice(0, MAX_SHOW_PETS).join(", ") +
            ` y ${petsNames.length - MAX_SHOW_PETS} mas...`
        : petsNames.join(", ")
    );
  };

  useEffect(() => {
    getPetsAsStr();
  });

  return (
    <tr>
      <td>
        {auth && (isAdmin(auth.user) || isSuperAdmin(auth.user)) && (
          <AiFillDelete
            className="pointer"
            onClick={() => handleDeleteTraining(training._id)}
            style={{ color: Colors.orangeDark }}
          />
        )}
      </td>
      <td>
        <Tooltip
          title={
            <p
              className="text-center align-middle"
              style={{ fontSize: "120%" }}
            >
              {petsStr}
            </p>
          }
          placement="top"
          arrow
          enterTouchDelay={0}
        >
          <p className="text-center text-decoration-underline m-2">
            {training.pets.length}
          </p>
        </Tooltip>
      </td>
      <td>
        <p className="text-center m-2">
          {new Date(training.date).toLocaleString("es-MX", DATE_OPTIONS)}
        </p>
      </td>
      <td>
        <p className="text-justify m-2">
          {training.comments ? training.comments : "Sin comentarios."}
        </p>
      </td>
    </tr>
  );
}

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose, AiFillStar } from "react-icons/ai";
import { useState, useEffect } from "react";
import { SidebarData } from "./SidebarData";
import { Avatar, Grid } from "@material-ui/core";
import logo from "../assets/img/chantli_logo.png";
import "./TopNav.css";
import { hasImage } from "../actions/auth";
import { Colors } from "../common/Colors";
import { isAdmin, isSuperAdmin } from "../common/Permissions";

const TopNav = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({ ...state }));
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const ColorList = [
    Colors.orange,
    Colors.yellow,
    Colors.blue,
    Colors.turquise,
  ];
  const [imgWidth, setImgWidth] = useState();
  const [image, setImage] = useState(null);

  const setIconSizes = () => {
    if (window.innerWidth < 1024) {
      setImgWidth(window.innerWidth / 3);
    } else {
      setImgWidth(window.innerWidth / 6);
    }
  };

  useEffect(() => {
    setIconSizes();
    loadImg();
  }, [imgWidth]);

  const loadImg = async () => {
    try {
      let hi = await hasImage(auth.user._id);
      hi.data.ok
        ? setImage(`${process.env.REACT_APP_API}/user/image/${auth.user._id}`)
        : setImage(null);
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    window.localStorage.removeItem("auth");
    history.push("/login");
  };

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <div
      className="navbar"
      style={{
        backgroundColor: Colors.black,
        display: "flex",
        justifyContent: "space-between",
        height: "110%",
      }}
    >
      <Link className="menu-bars" to="#">
        <FaBars
          size={25}
          color={Colors.white}
          onClick={showSidebar}
          style={{ padding: 1 }}
        />
      </Link>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul
          className="nav-menu-items"
          onClick={showSidebar}
          style={{ listStyle: "none" }}
        >
          <li className="navbar-toggle">
            <Link to="#" className="close-icon">
              <AiOutlineClose />
            </Link>
          </li>
          {SidebarData.map((item, index) => {
            if (
              auth &&
              (isAdmin(auth.user) || isSuperAdmin(auth.user)) &&
              item.private
            ) {
              return (
                <li key={index} className={item.cName}>
                  <Link to={auth ? item.path : "/login"}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            } else if (!item.private) {
              return (
                <li key={index} className={item.cName}>
                  <Link to={auth ? item.path : "/login"}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </nav>

      <div>
        <img src={logo} width={imgWidth} alt="logo_maka" />
      </div>

      <div>
        {auth !== null && (
          <Dropdown>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ paddingLeft: 0, paddingRight: 15 }}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  backgroundColor: Colors.black,
                  color: Colors.white,
                  border: "none",
                  padding: 0,
                }}
              >
                <div style={{ position: "relative" }}>
                  {image ? (
                    <Avatar
                      src={image}
                      style={{
                        width: 33,
                        height: 33,
                        fontSize: 50,
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        fontSize: 16,
                        padding: 5,
                        backgroundColor:
                          ColorList[
                            Math.floor(Math.random() * ColorList.length)
                          ],
                      }}
                    >
                      {auth.user.name[0]}
                    </Avatar>
                  )}
                  {auth && isAdmin(auth.user) && (
                    <AiFillStar
                      color={Colors.gold}
                      style={{ position: "absolute", bottom: -3, right: -3 }}
                    />
                  )}
                </div>
              </Dropdown.Toggle>
            </Grid>

            <Dropdown.Menu align="right">
              <Dropdown.Item
                onClick={() => history.push(`/user/edit/${auth.user._id}`)}
              >
                Editar Perfil
              </Dropdown.Item>
              <Dropdown.Item onClick={() => history.push(`/dashboard`)}>
                Revisiones
              </Dropdown.Item>
              <Dropdown.Item onClick={logout}>Salir</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {auth === null && (
          <>
            <Link
              className="text-nav"
              to="/login"
              style={{ textDecoration: "none", color: Colors.white }}
            >
              Iniciar Sesión
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default TopNav;

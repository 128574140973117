import React, { useState, useEffect } from "react";
import { getPet } from "../actions/pet";
import { getShower } from "../actions/control";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { Avatar } from "@material-ui/core";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const ViewShower = ({ match, history }) => {
  const [shower, setShower] = useState({});
  const [pet, setPet] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");

  useEffect(() => {
    // console.log(history.location.state);
    loadShower();
    loadPet();
    if (!pet) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    loadImage();
  }, [pet]);

  const loadShower = async () => {
    let res = await getShower(match.params.actionId);
    setShower(res.data);
  };

  const loadPet = async () => {
    let res = await getPet(history.location.state.petId);
    setPet(res.data);
  };

  const loadImage = () => {
    if (pet.image && pet.image.contentType) {
      setImage(`${process.env.REACT_APP_API}/pet/image/${pet._id}`);
      return;
    }
    setImage(null);
    setLoading(false);
  };

  const getColor = (beh) => {
    if (beh === "Normal") return Colors.green;
    if (beh === "Regular") return Colors.yellow;
    if (beh === "Crítico") return Colors.orange;
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">{shower.actionType}</h2>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="d-flex justify-content-center align-items-center mt-4">
            <br />
            {loading ? (
              <ClipLoader loading={loading} size={200} className="m-3" />
            ) : image ? (
              <Avatar
                src={image}
                style={{
                  width: 250,
                  height: 250,
                }}
              />
            ) : (
              <Avatar style={{ width: 250, height: 250, fontSize: 20 }}>
                {pet.name}
              </Avatar>
            )}
          </div>

          <h2 className="text-center mt-3">Perro: {pet.name}</h2>

          <div className="col">
            <br />
            <p className="font-weight-bold">
              Realizado {moment(shower.createdAt).fromNow()}
            </p>

            <div>
              <p className="font-weight-bold text-center">
                Comportamiento al bañar
              </p>
              <p
                className="text-center"
                style={{ color: getColor(shower.showerBehavior) }}
              >
                {shower.showerBehavior}
              </p>
              <p className="font-weight-bold text-center">
                Comportamiento al cepillar
              </p>
              <p
                className="text-center"
                style={{ color: getColor(shower.brushBehavior) }}
              >
                {shower.brushBehavior}
              </p>
              <p className="font-weight-bold text-center">
                Comportamiento al secar
              </p>
              <p
                className="text-center"
                style={{ color: getColor(shower.dryBehavior) }}
              >
                {shower.dryBehavior}
              </p>
            </div>

            {shower.comments ? (
              <p>
                <strong>Comentarios: </strong>
                {shower.comments}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewShower;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTest } from "../actions/tests";
import VisualizeStoolControl from "./VisualizeStoolControl";
import VisualizePalatability from "./VisualizePalatability";
import VisualizeVersus from "./VisualizeVersus";

const VisualizeTest = ({ match }) => {
  const [test, setTest] = useState({});
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const loadTest = async () => {
    const res = await getTest(token, match.params.testId);
    console.log("loadTest res: ", res);

    setTest(res.data.test);
  };

  useEffect(() => {
    loadTest();
  }, []);

  return (
    <>
      {test.type === "calidad-heces" && (
        <VisualizeStoolControl
          test={test.stoolQuality}
          testId={test._id}
          panel={test.type}
        />
      )}

      {test.type === "palatabilidad" && (
        <VisualizePalatability
          test={test.palatability}
          testId={test._id}
          panel={test.type}
        />
      )}

      {test.type === "versus" && (
        <VisualizeVersus
          test={test.versus}
          testId={test._id}
          panel={test.type}
        />
      )}
    </>
  );
};

export default VisualizeTest;

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getPet, rotateImage, updatePet } from "../actions/pet";
import { useSelector } from "react-redux";
import PetEditForm from "../components/forms/PetEditForm";
import { Avatar } from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
import { GrLinkPrevious } from "react-icons/gr";
import { AiOutlineRotateRight, AiOutlineRotateLeft } from "react-icons/ai";
import { Colors } from "../common/Colors";

const EditPet = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const [pet, setPet] = useState({});

  const [image, setImage] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [loadingImage, setLoadingImage] = useState(true);
  const [preview, setPreview] = useState("");
  const [degrees, setDegrees] = useState(0);

  const { name, comments, breed, color, size, panel, sex, state } = pet;

  useEffect(() => {
    loadPet();
  }, []);

  useEffect(() => {
    loadImage();
  }, [pet, degrees]);

  const loadPet = async () => {
    let res = await getPet(match.params.petId);

    setPet(res.data);
    setLoadingData(false);
  };

  const loadImage = () => {
    if (pet.image && pet.image.contentType) {
      setPreview(
        `${process.env.REACT_APP_API}/pet/image/${match.params.petId}`
      );
      return;
    }

    setPreview(null);
    setLoadingImage(false);
  };

  const rotateImageRight = () => {
    if (degrees >= 270) {
      setDegrees(0);
      return;
    }
    setDegrees(degrees + 90);
  };

  const rotateImageLeft = () => {
    if (degrees <= 0) {
      setDegrees(270);
      return;
    }
    setDegrees(degrees - 90);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let petData = new FormData();
    petData.append("name", name);
    petData.append("comments", comments);
    petData.append("sex", sex);
    image && petData.append("image", image);
    petData.append("breed", breed);
    petData.append("color", color);
    petData.append("size", size);
    petData.append("panel", panel);
    petData.append("state", state);

    try {
      let res = await updatePet(token, petData, match.params.petId);
      await rotateImage(match.params.petId, degrees);
      toast.success(`Los datos de ${res.data.name} fueron actualizados`);
      setTimeout(() => {
        window.location.href = "/pets";
      }, 1000);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.err);
    }
  };

  const handleImageChange = (e) => {
    // console.log(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const handleChange = (e) => {
    setPet({ ...pet, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Editar datos de {name}</h2>
      </div>
      <div className="container-fluid col-md-8 col-md-offset-4 p-4">
        <div className="d-flex justify-content-center mt-3 flex-wrap">
          {loadingImage ? (
            <ClipLoader loading={loadImage} size={200} className="m-3" />
          ) : preview ? (
            <Avatar
              src={preview}
              style={{
                width: 310,
                height: 310,
                transform: `rotate(${degrees}deg)`,
              }}
            />
          ) : (
            <Avatar
              style={{
                width: 310,
                height: 310,
                fontSize: 30,
              }}
            >
              {name}
            </Avatar>
          )}
          <div className="container d-flex justify-content-around mt-3">
            <AiOutlineRotateLeft
              size={30}
              style={{ cursor: "pointer" }}
              onClick={rotateImageLeft}
            />
            <AiOutlineRotateRight
              size={30}
              style={{ cursor: "pointer" }}
              onClick={rotateImageRight}
            />
          </div>
        </div>

        {loadingData ? (
          <ClipLoader loading={loadingData} size={200} className="m-3" />
        ) : (
          <div className="row d-flex justify-content-center flex-wrap">
            <div className="col-md-10">
              <br />
              <PetEditForm
                values={pet}
                setValues={setPet}
                handleChange={handleChange}
                handleImageChange={handleImageChange}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditPet;

import { useState } from "react";
import { Colors } from "../common/Colors";
import { PANELS_DATA } from "../common/Panels";
import SquareLoader from "react-spinners/SquareLoader";
import NewRecordModal from "../components/modals/NewRecordModal";
import { Avatar } from "@material-ui/core";
import { deleteTest, downloadTest, getTestsByPanel } from "../actions/tests";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import { isAdmin, isSuperAdmin } from "../common/Permissions";
import { AiFillDelete, AiOutlineDownload } from "react-icons/ai";
import { GrLinkPrevious } from "react-icons/gr";
import { toast } from "react-toastify";
import { DATE_OPTIONS, GET_TIME_DIFFERENCE } from "../common/Dates";
import { useHistory } from "react-router-dom";
import moment from "moment";

const Testing = () => {
  const [selectedPanel, setSelectedPanel] = useState();
  const [panelRecords, setPanelRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const history = useHistory();

  const loadPanelRecords = async (panel) => {
    setLoading(true);
    const res = await getTestsByPanel(token, panel);
    setPanelRecords(res.data.data);
    setLoading(false);
  };

  const handleDownloadExcelFile = async (record) => {
    try {
      await downloadTest(token, record);
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  const handleDeleteRecord = async (record) => {
    try {
      await deleteTest(token, record._id);
      toast.success("Prueba eliminada correctamente!");
      loadPanelRecords(selectedPanel._id);
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  const handleChangePanel = async (panel) => {
    setSelectedPanel(panel);
    await loadPanelRecords(panel._id);
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Pruebas</h2>
      </div>
      <div className="container-fluid w-100 h-100 mt-5">
        <div className="row d-flex justify-content-center text-center align-items-center">
          {PANELS_DATA.map((panel) => {
            return (
              <div
                key={"panel-" + panel._id}
                className="btn-turq my-3 mx-3 h4"
                style={
                  selectedPanel && selectedPanel._id === panel._id
                    ? {
                        color: Colors.turquise,
                        backgroundColor: Colors.white,
                      }
                    : {}
                }
                onClick={() => handleChangePanel(panel)}
              >
                {panel.name}
              </div>
            );
          })}
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <SquareLoader color={Colors.orange} loading={loading} size={100} />
          <h1 className="m-3">Cargando...</h1>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center flex-wrap my-5">
            {selectedPanel && (
              <button
                className="btn-orange mb-3 h6"
                onClick={() => setShowModal(true)}
                // disabled={!selectedPanel}
              >
                + Agregar nueva prueba
              </button>
            )}
            {showModal && (
              <NewRecordModal
                panel={selectedPanel}
                showModal={showModal}
                setShowModal={setShowModal}
                loadPanelRecords={loadPanelRecords}
              />
            )}
          </div>
          {panelRecords.length > 0 ? (
            <div className="my-5">
              <div className="container tbl-container">
                <div className="row tbl-fixed">
                  <div className="table-responsive">
                    <table className="table table-sm table-hover table-borderless">
                      <thead>
                        {selectedPanel._id === "digestibilidad" ? (
                          <tr>
                            {auth && isAdmin(auth.user) && (
                              <th scope="col">
                                <p className="text-center tag-blue m-2">
                                  Acciones
                                </p>
                              </th>
                            )}

                            <th scope="col">
                              <p className="text-center tag-blue m-2">#</p>
                            </th>

                            <th scope="col">
                              <p className="text-center tag-blue m-2">
                                Comentarios
                              </p>
                            </th>

                            <th scope="col">
                              <p className="text-center tag-blue m-2">
                                Archivo de Excel
                              </p>
                            </th>
                          </tr>
                        ) : (
                          <tr>
                            {auth && isAdmin(auth.user) && (
                              <th scope="col">
                                <p className="text-center tag-blue m-2">
                                  Acciones
                                </p>
                              </th>
                            )}
                            <th scope="col">
                              <p className="text-center tag-blue m-2">#</p>
                            </th>
                            <th scope="col">
                              <p className="text-center tag-blue m-2">
                                Fecha de inicio
                              </p>
                            </th>
                            <th scope="col">
                              <p className="text-center tag-blue m-2">
                                Fecha de fin
                              </p>
                            </th>
                            <th scope="col">
                              <p className="text-center tag-blue m-2">
                                Archivo de Excel
                              </p>
                            </th>
                          </tr>
                        )}
                      </thead>

                      <tbody>
                        {panelRecords.map((record, index) => {
                          const id = record._id;
                          const filename = record.filename;
                          if (selectedPanel._id === "calidad-heces") {
                            record = record.stoolQuality;
                          } else if (selectedPanel._id === "palatabilidad") {
                            record = record.palatability;
                          } else if (selectedPanel._id === "versus") {
                            record = record.versus;
                          }
                          record._id = id;
                          record.filename = filename;
                          const startDate = new Date(record.startDate);
                          const startFormattedDate = new Date(
                            startDate.setHours(
                              startDate.getHours() +
                                GET_TIME_DIFFERENCE(startDate)
                            )
                          ).toLocaleDateString("es-MX", DATE_OPTIONS);
                          const endDate = new Date(record.endDate);
                          const endFormattedDate = new Date(
                            endDate.setHours(
                              endDate.getHours() + GET_TIME_DIFFERENCE(endDate)
                            )
                          ).toLocaleDateString("es-MX", DATE_OPTIONS);
                          return (
                            <tr key={`row-testing-record-${index}`}>
                              {selectedPanel._id === "digestibilidad" ? (
                                <>
                                  {auth && isAdmin(auth.user) && (
                                    <td>
                                      <AiFillDelete
                                        className="pointer"
                                        onClick={() =>
                                          handleDeleteRecord(record)
                                        }
                                        style={{ color: Colors.orangeDark }}
                                      />
                                    </td>
                                  )}
                                  <td>
                                    <p>{index + 1}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {record.comments || "Sin comentarios."}
                                    </p>
                                  </td>
                                  <td>
                                    <button
                                      className="btn-orange my-1"
                                      onClick={() =>
                                        handleDownloadExcelFile(record)
                                      }
                                    >
                                      Descargar
                                    </button>
                                  </td>
                                </>
                              ) : (
                                <>
                                  {auth && isAdmin(auth.user) && (
                                    <td>
                                      <AiFillDelete
                                        className="pointer"
                                        onClick={() =>
                                          handleDeleteRecord(record)
                                        }
                                        style={{ color: Colors.orangeDark }}
                                      />
                                    </td>
                                  )}
                                  <td>
                                    <p>{index + 1}</p>
                                  </td>
                                  {/* <div className="col-2">{record.pets.length}</div> */}
                                  <td>
                                    <p>{startFormattedDate}</p>
                                  </td>
                                  <td>
                                    <p>{endFormattedDate}</p>
                                  </td>
                                  <td>
                                    <button
                                      className="btn-orange my-1"
                                      onClick={() =>
                                        history.push(`/testing/${record._id}`)
                                      }
                                    >
                                      Visualizar
                                    </button>
                                    <button
                                      className="btn-orange my-1 ml-1"
                                      onClick={() =>
                                        handleDownloadExcelFile(record)
                                      }
                                    >
                                      <AiOutlineDownload size={20} />
                                    </button>
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {selectedPanel && (
                <h4 className="text-center my-5 mx-5">
                  No hay ningun registro en esta prueba
                </h4>
              )}
            </>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </>
  );
};

export default Testing;

import axios from "axios";

export const getTodos = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/todo`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createTodo = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/todo`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateTodo = async (token, todoId) => {
  try {
    await axios.put(`${process.env.REACT_APP_API}/todo/${todoId}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    console.error("ERROR BACKEEENND", e);
  }
};

export const deleteTodo = async (token, todoId) =>
  await axios.delete(`${process.env.REACT_APP_API}/todo/${todoId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

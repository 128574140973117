import { getPets, deletePet, getPetsByName } from "../actions/pet";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import PetCard from "../components/cards/PetCard";
import { toast } from "react-toastify";
import { GrLinkPrevious } from "react-icons/gr";
import { BsFileSpreadsheet } from "react-icons/bs";
import { deletePetActions } from "../actions/control";
import { Colors } from "../common/Colors";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { States } from "../common/States";
import SquareLoader from "react-spinners/SquareLoader";
import { isAdmin } from "../common/Permissions";

const Pets = ({ history }) => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const [pets, setPets] = useState([]);
  const [petState, setPetState] = useState("");
  const [searchParameter, setSearchParameter] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadPets();
  }, [petState]);

  useEffect(() => {
    loadPetsByName();
  }, [searchParameter]);

  const loadPetsByName = async () => {
    const res = await getPetsByName(searchParameter);
    setPets(res.data);
    setIsLoading(false);
  };

  const loadPets = async () => {
    try {
      const res = await getPets(petState);

      setPets(res.data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePetDelete = async (petId) => {
    if (!window.confirm("¿Este perro saldrá del bioterio?")) return;
    await deletePetActions(token, petId);
    deletePet(token, petId).then((res) => {
      toast.success(`El perro salió del bioterio.`);
      loadPets();
    });
  };

  const handlePetTransfer = (petId) => {
    history.push(`/pet/transferpet/${petId}`);
  };

  const handleChange = (e) => {
    setSearchParameter(e.target.value);
    setPetState("");
  };

  const loadedPetsPage = () => {
    return (
      <>
        <div className="m-3 d-flex justify-content-between flex-wrap">
          <button
            className="mb-3 btn-turq"
            onClick={() => history.push(`/cages/`)}
          >
            + Registrar perro
          </button>
          {isAdmin(auth.user) ? (
            <a
              href={`${process.env.REACT_APP_API}/pet-format`}
              download
              className="btn-turq"
              style={{ color: Colors.white, cursor: "pointer" }}
            >
              <BsFileSpreadsheet size={30} />
            </a>
          ) : null}
        </div>

        <div className="container d-flex justify-content center align-items-center flex-wrap">
          <TextField
            onChange={handleChange}
            placeholder="Buscar"
            value={searchParameter}
            id="outlined-name"
            style={{ width: "100%" }}
          />
        </div>
        <div className="container d-flex justify-content-around mt-3 col-md-6">
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!petState}
                  onClick={() => setPetState("")}
                  color="default"
                />
              }
              label="Todo"
            />
            {States.map((state) => {
              return (
                <FormControlLabel
                  key={state}
                  control={
                    <Checkbox
                      checked={petState === state}
                      onClick={() => setPetState(state)}
                      color="default"
                    />
                  }
                  label={state}
                />
              );
            })}
          </FormGroup>
        </div>

        <div className="container-fluid mb-3 mt-3">
          <h4 className="text-muted">
            {searchParameter.length > 0
              ? `${pets.length} resultados para ${searchParameter}`
              : `Hay ${pets.length} perro(s) ${petState.toLocaleLowerCase()}${
                  petState ? "(s)" : ""
                } en el Bioterio`}
          </h4>
        </div>

        <div className="col">
          {pets.map((pet) => (
            <PetCard
              key={pet._id}
              pet={pet}
              handlePetDelete={handlePetDelete}
              handlePetTransfer={handlePetTransfer}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Perros</h2>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <SquareLoader color={Colors.orange} loading={isLoading} size={100} />
          <h1 className="m-3">Cargando...</h1>
        </div>
      ) : (
        loadedPetsPage()
      )}
    </>
  );
};

export default Pets;

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import UserEditForm from "../components/forms/UserEditForm";
import { readUser, updateUser, hasImage } from "../actions/auth";
import { useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Avatar } from "@material-ui/core";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const EditUser = ({ match, history }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const [values, setValues] = useState({
    name: "",
    email: "",
  });

  const dispatch = useDispatch();

  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState(
    "https://via.placeholder.com/100x100.png?text=PREVIEW"
  );

  const { name, email } = values;

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    let res = await readUser(match.params.userId);
    // console.log(res);
    setValues({ ...values, ...res.data });
    loadImg();
  };

  const loadImg = async () => {
    try {
      let hi = await hasImage(match.params.userId);
      hi.data.ok
        ? setPreview(
            `${process.env.REACT_APP_API}/user/image/${match.params.userId}`
          )
        : setPreview("");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let userData = new FormData();
    userData.append("name", name);
    userData.append("email", email);
    //console.log("image yes", image);
    image && userData.append("image", image);

    try {
      let res = await updateUser(token, userData, match.params.userId);
      console.log("USER UPDATE RES", res);

      const user = res.data;

      window.localStorage.setItem(
        "auth",
        JSON.stringify({
          token,
          user,
        })
      );

      dispatch({
        type: "LOGGED_IN_USER",
        payload: res.data,
      });
      window.location.href = "/dashboard";

      res
        ? toast.success(`${res.data.name} se ha actualizado`)
        : history.push("/dashboard");
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.err);
    }
  };

  const handleImageChange = (e) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Editar Usuario</h2>
      </div>

      <div className="container-fluid">
        <div className="d-flex justify-content-center mt-3">
          {loading ? (
            <ClipLoader loading={loading} size={200} className="m-3" />
          ) : preview ? (
            <Avatar
              src={preview}
              style={{
                width: 248,
                height: 248,
              }}
            />
          ) : (
            <Avatar
              style={{
                width: 248,
                height: 248,
                fontSize: 160,
              }}
            >
              {name[0].toUpperCase()}
            </Avatar>
          )}
        </div>

        <div className=" d-flex justify-content-center col-md-12">
          <br />
          <UserEditForm
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            handleImageChange={handleImageChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default EditUser;

import { allCages, deleteCage } from "../actions/cage";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import CageCard from "../components/cards/CageCard";
import { toast } from "react-toastify";
import { GrLinkPrevious } from "react-icons/gr";
import { BsFileSpreadsheet } from "react-icons/bs";
import { Colors } from "../common/Colors";
import SquareLoader from "react-spinners/SquareLoader";
import { isAdmin } from "../common/Permissions";

const Cages = ({ history }) => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));
  const [cages, setCages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [diningRooms, setDiningRooms] = useState([]);

  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadCages();
  }, []);

  const handleCageDelete = async (cageId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Jaula?")) return;
    deleteCage(token, cageId).then((res) => {
      toast.success("Jaula eliminada");
      loadCages();
    });
  };

  const loadCages = async () => {
    const res = await allCages();
    let resCages = res.data;
    const rooms = resCages.filter((item) => item.isDiningRoom);
    if (rooms.length > 0) {
      setDiningRooms(rooms);
      resCages = resCages.filter((item) => !item.isDiningRoom);
    }
    setCages(resCages);
    setIsLoading(false);
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Jaulas</h2>
      </div>
      <div className="m-3 d-flex justify-content-between">
        {isAdmin(auth.user) ? (
          <>
            <button
              className="mb-3 btn-turq"
              onClick={() => history.push(`/cages/new`)}
            >
              + Agregar jaula
            </button>
            <a
              href={`${process.env.REACT_APP_API}/cage-format`}
              download
              className="btn-turq"
              style={{ color: "white" }}
            >
              <BsFileSpreadsheet size={30} />
            </a>
          </>
        ) : null}
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <SquareLoader color={Colors.orange} loading={isLoading} size={100} />
          <h1 className="m-3">Cargando...</h1>
        </div>
      ) : (
        <>
          {diningRooms && (
            <div className="col mb-5">
              {diningRooms.map((cage) => (
                <CageCard
                  key={cage._id}
                  cage={cage}
                  handleCageDelete={handleCageDelete}
                />
              ))}
            </div>
          )}
          <div className="container-fluid mb-3 mt-3">
            <h4 className="text-muted">{`Hay ${cages.length} jaula(s) en el Bioterio`}</h4>
          </div>
          <div className="col">
            {cages.map((cage) => (
              <CageCard
                key={cage._id}
                cage={cage}
                handleCageDelete={handleCageDelete}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Cages;

const LoginForm = ({ email, setEmail, goForgotPw, goRegister }) => (
  <form onSubmit={goForgotPw} className="mt-3">
    <div className="form-group mb-3">
      <label className="form-label">Ingrese su correo electrónico</label>
      <input
        type="email"
        className="form-control"
        placeholder="usuario@maka.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>

    <div className="d-flex justify-content-between align-items-center">
      <button className="btn-black-txt" onClick={goRegister}>
        Registrarse
      </button>
      <button className="btn-turq">Recuperar mi contraseña</button>
    </div>
  </form>
);

export default LoginForm;

import { useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Avatar, Modal } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../common/Colors";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SquareLoader from "react-spinners/SquareLoader";
import { DATE_OPTIONS } from "../../common/Dates";
import { createNewTest } from "../../actions/tests";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles({
  gridContainer: {
    marginTop: "40px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
});

const PalatabilityModal = ({
  panel,
  showModal,
  setShowModal,
  panelPets,
  isLoading,
  loadPanelRecords,
}) => {
  const DAYS_FOR_PALATABILITY_TEST = 6;
  const [startDate, setStartDate] = useState(new Date());

  const [foodGroup1, setFoodGroup1] = useState([]);
  const [foodGroup2, setFoodGroup2] = useState([]);

  const [isSelected, setIsSelected] = useState(false);
  const classes = useStyles();
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const handleStartDataChange = (newDate) => {
    if (!isSelected) {
      setIsSelected(true);
    }
    setStartDate(new Date(newDate["$d"].toDateString()));
  };

  const handleFoodChange = (index, e, group) => {
    e.preventDefault();
    if (group === "group_1") {
      const temp = foodGroup1;
      temp[index] = e.target.value;
      setFoodGroup1(temp);
    } else if (group === "group_2") {
      const temp = foodGroup2;
      temp[index] = e.target.value;
      setFoodGroup2(temp);
    }
  };

  const createNewRecord = async () => {
    const [pg1, pg2] = setPetsInGroups();

    if (
      foodGroup1.length !== DAYS_FOR_PALATABILITY_TEST ||
      foodGroup2.length !== DAYS_FOR_PALATABILITY_TEST ||
      foodGroup1.filter((day) => !day).length > 0 ||
      foodGroup2.filter((day) => !day).length > 0 ||
      pg1.length + pg2.length !== panelPets.length
    ) {
      toast.error("Error: verifica que toda la informacion este completa");
      return;
    }

    const res = await createNewTest(token, panel._id, {
      start_date: startDate.toISOString().split("T")[0],
      groups: [
        { pets: pg1, food: foodGroup1 },
        { pets: pg2, food: foodGroup2 },
      ],
    });
    console.log("createNewTest PALATABLILDAD res: ", res);
    toast.success("Nueva prueba creada!");
    loadPanelRecords(panel._id);
    clearAllStuff();
    setShowModal(false);
  };

  const clearRadioButtons = () => {
    var ele = document.querySelectorAll("input[type=radio]");
    for (var i = 0; i < ele.length; i++) {
      ele[i].checked = false;
    }
  };

  const setPetsInGroups = () => {
    let g1 = [];
    let g2 = [];

    panelPets.map((pet, index) => {
      var ele = document.querySelectorAll(
        `input[type=radio][name=radioGroup-${index}]:checked`
      );
      if (ele.length === 1) {
        if (ele[0].id.includes("radioGroup1-")) {
          g1.push(pet._id);
        } else if (ele[0].id.includes("radioGroup2-")) {
          g2.push(pet._id);
        }
      }
    });

    return [g1, g2];
  };

  const clearAllStuff = () => {
    setFoodGroup1([]);
    setFoodGroup2([]);
    clearRadioButtons();
    setStartDate(new Date());
    setIsSelected(false);
  };

  useEffect(() => {
    clearAllStuff();
  }, []);

  const DaysAndTextFields = () => {
    let rows = [];
    for (let i = 0; i < DAYS_FOR_PALATABILITY_TEST; i++) {
      rows.push(
        <div
          className="row align-items-center text-center justify-content-center my-3"
          key={`day-field-${i}`}
        >
          <div className="col-2">{i + 1}</div>
          <div className="col-4">
            <div className="form-floating">
              <input
                type="text"
                className="form-control form-control-sm"
                value={foodGroup1[i]}
                onChange={(e) => handleFoodChange(i, e, "group_1")}
                required
              />
              <label htmlFor="floatingInput">
                {new Date(
                  startDate.getTime() + i * 1000 * 3600 * 24
                ).toLocaleString("es-MX", DATE_OPTIONS)}
              </label>
            </div>
          </div>

          <div className="col-2">{i + 1}</div>
          <div className="col-4">
            <div className="form-floating">
              <input
                type="text"
                className="form-control form-control-sm"
                value={foodGroup2[i]}
                onChange={(e) => handleFoodChange(i, e, "group_2")}
                required
              />
              <label htmlFor="floatingInput">
                {new Date(
                  startDate.getTime() + i * 1000 * 3600 * 24
                ).toLocaleString("es-MX", DATE_OPTIONS)}
              </label>
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>
        </div>
      );
    }
    return rows;
  };

  const SeparateDaysAndTextFields = ({ groupTitle }) => {
    let rows = [];
    for (let i = 0; i < DAYS_FOR_PALATABILITY_TEST; i++) {
      rows.push(
        <div
          className="row align-items-center text-center justify-content-center my-3"
          key={`separate-day-field-${i}`}
        >
          <div className="col-3">{i + 1}</div>
          <div className="col-9">
            <div className="form-floating">
              <input
                type="text"
                className="form-control form-control-sm"
                value={foodGroup1[i]}
                onChange={(e) => handleFoodChange(i, e, groupTitle)}
                required
              />
              <label>
                {
                  new Date(startDate.getTime() + i * 1000 * 3600 * 24)
                    .toLocaleString("es-MX", DATE_OPTIONS)
                    .split(" de 20")[0]
                }
              </label>
            </div>
          </div>
        </div>
      );
    }
    return rows;
  };

  return (
    <Modal
      open={showModal}
      centered
      width={1000}
      closeIcon={
        <AiOutlineCloseCircle
          className="pointer"
          color={Colors.orange}
          size={30}
        />
      }
      onCancel={() => setShowModal(!showModal)}
    >
      <div
        className="container-fluid p-3 my-4 d-flex justify-content-center align-items-center border rounded"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <h2 className="text-white text-center">
          Agregar nueva prueba en
          <br />"{panel.name}"
        </h2>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="container-fluid">
          {isLoading ? (
            <>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <SquareLoader
                  color={Colors.orange}
                  loading={isLoading}
                  size={100}
                />
                <h1 className="m-3">Cargando...</h1>
              </div>
            </>
          ) : (
            <>
              <div className="row align-items-center text-center justify-content-center">
                <div className="col mt-3">
                  <MobileDatePicker
                    label="Fecha de inicio"
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    cancelLabel="Cancelar"
                    onChange={(value) => handleStartDataChange(value, true)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>

              {isMobile ? (
                <>
                  <div className="row align-items-center text-center justify-content-center mt-4">
                    <div className="col-12 h3">
                      <p>Grupo 1</p>
                    </div>

                    <div className="row align-items-center text-center justify-content-center mt-4">
                      <div className="col-3 h6">
                        <p>Dia</p>
                      </div>
                      <div className="col-9 h6">
                        <p>Comida</p>
                      </div>
                    </div>
                  </div>

                  <SeparateDaysAndTextFields groupTitle={"group_1"} />

                  <div className="row align-items-center text-center justify-content-center mt-4">
                    <div className="col-12 h3">
                      <p>Grupo 2</p>
                    </div>

                    <div className="row align-items-center text-center justify-content-center mt-4">
                      <div className="col-3 h6">
                        <p>Dia</p>
                      </div>
                      <div className="col-9 h6">
                        <p>Comida</p>
                      </div>
                    </div>
                  </div>

                  <SeparateDaysAndTextFields groupTitle={"group_2"} />
                </>
              ) : (
                <>
                  <div className="row align-items-center text-center justify-content-center mt-4">
                    <div className="col-6 h3">
                      <p>Grupo 1</p>
                    </div>

                    <div className="col-6 h3">
                      <p>Grupo 2</p>
                    </div>
                  </div>

                  <div className="row align-items-center text-center justify-content-center mt-4">
                    <div className="col-2 h4">
                      <p>Dia</p>
                    </div>
                    <div className="col-4 h4">
                      <p>Comida</p>
                    </div>

                    <div className="col-2 h4">
                      <p>Dia</p>
                    </div>
                    <div className="col-4 h4">
                      <p>Comida</p>
                    </div>
                  </div>

                  <DaysAndTextFields />
                </>
              )}

              {panelPets && panelPets.length > 0 ? (
                <Grid
                  container
                  spacing={2}
                  className={classes.gridContainer}
                  justifyContent="center"
                  alignItems="center"
                >
                  {panelPets.map((pet, index) => {
                    return (
                      <Grid
                        key={`pet-card-${index}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <div className="container">
                          <div className="row text-center align-items-center">
                            <div className="col">
                              <div className="card p-2">
                                <div className="card-block p-1">
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      lineHeight: "0",
                                      letterSpacing: "0",
                                    }}
                                  ></div>
                                  {/* TODO keep this or not?
                                  <Avatar
                                    style={{
                                      width: 80,
                                      height: 80,
                                    }}
                                    shape="circle"
                                    className="mb-1"
                                    src={`${process.env.REACT_APP_API}/pet/image/${pet._id}`}
                                  /> */}

                                  <div className="row">
                                    <div className="col">
                                      <h5>{pet.name}</h5>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col card-text mx-auto text-center">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={`radioGroup-${index}`}
                                          id={`radioGroup1-${index}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`radioGroup1-${index}`}
                                        >
                                          Grupo 1
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col card-text mx-auto text-center">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={`radioGroup-${index}`}
                                          id={`radioGroup2-${index}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`radioGroup2-${index}`}
                                        >
                                          Grupo 2
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <div className="d-flex justify-content-center">
                  <h4 className="text-center mt-5 w-50">
                    No hay ningun perro en este panel, intenta agregar alguno
                    para poder crear una nueva prueba!
                  </h4>
                </div>
              )}

              <div className="row align-items-center text-center justify-content-center mt-4">
                <button className="mb-3 btn-turq" onClick={createNewRecord}>
                  Crear
                </button>
              </div>
            </>
          )}
        </div>
      </LocalizationProvider>
    </Modal>
  );
};

export default PalatabilityModal;

import axios from "axios";

export const getPetsByPanel = async (token, panel) =>
  await axios.get(`${process.env.REACT_APP_API}/test/pets`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      panel,
    },
  });

export const getTestsByPanel = async (token, panel) =>
  await axios.get(`${process.env.REACT_APP_API}/tests/${panel}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createNewTest = async (token, panel, data) =>
  await axios.post(`${process.env.REACT_APP_API}/test/new/${panel}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getTest = async (token, testId) =>
  axios.get(`${process.env.REACT_APP_API}/test/${testId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateTest = async (token, testId, data) =>
  axios.post(`${process.env.REACT_APP_API}/test/${testId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteTest = async (token, testId) =>
  await axios.delete(`${process.env.REACT_APP_API}/test/${testId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadTest = async (token, data, panel) => {
  console.log("here ENDPOINT");
  for (var key of data.entries()) {
    console.log("uploadTest data:\n", key[0] + ", " + key[1]);
  }
  await axios.post(`${process.env.REACT_APP_API}/test/upload/${panel}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const downloadTest = async (token, test) => {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);

  axios
    .get(`${process.env.REACT_APP_API}/test/download/${test._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    })
    .then((res) => new Blob([res.data], { type: res.headers["content-type"] }))
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);
      anchor.href = objectUrl;
      anchor.download = test.filename || "excel";
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    });
};

import { AiFillDelete } from "react-icons/ai";
import moment from "moment";
import { Colors } from "../../common/Colors";

const CleanCard = ({ clean, handleShowMore, handleActionDelete }) => {
  console.log(clean);

  return (
    <>
      <div className="card mb-3">
        <div className="row no-gutters">
          <h4 className="mt-2 text-center">{clean.actionType}</h4>
        </div>
        <div className="row m-3">
          {clean.madeFor ? (
            <h2>{clean.madeFor && clean.madeFor.name}</h2>
          ) : null}
          {clean.postedBy ? (
            <i>Limpiada por {clean.postedBy && clean.postedBy.name}</i>
          ) : null}
          <span>
            {moment(clean.createdAt)
              .fromNow(false)
              .replace(/\b[a-z]/, (match) => match.toUpperCase())}
          </span>
          <div className="card-body">
            <div className="flex-wrap d-flex justify-content-between align-content-center h4">
              <button
                className="btn-turq"
                onClick={() => handleShowMore(clean._id, clean.madeFor._id)}
              >
                Ver más
              </button>

              <AiFillDelete
                className="m-2"
                style={{ color: Colors.orangeDark }}
                onClick={() => handleActionDelete(clean._id, clean.madeFor._id)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CleanCard;

import { TextField, Fab } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { Colors } from "../../common/Colors";

const UserEditForm = ({
  values,
  setValues,
  handleChange,
  handleImageChange,
  handleSubmit,
}) => {
  const { name, email } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group mt-3">
        <div className="d-flex justify-content-center">
          <label htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={handleImageChange}
            />
            <Fab
              component="span"
              aria-label="add"
              variant="extended"
              style={{ backgroundColor: Colors.turquise, color: "white" }}
            >
              <ImageIcon /> Agregar Imagen
            </Fab>
          </label>
        </div>

        <TextField
          onChange={handleChange}
          fullWidth
          name="name"
          placeholder="Nombre"
          value={name}
          id="outlined-name"
          className="mt-3"
        />

        <TextField
          type="email"
          onChange={handleChange}
          fullWidth
          name="email"
          placeholder="Email"
          value={email}
          id="outlined-name"
          className="mt-3"
        />
      </div>

      <button className="btn-turq">Guardar</button>
    </form>
  );
};

export default UserEditForm;

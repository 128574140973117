import { TextField, Fab, Select, MenuItem } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { Colors } from "../../common/Colors";
import { Panels } from "../../common/Panels";
import { States } from "../../common/States";

const PetEditForm = ({
  values,
  handleChange,
  handleImageChange,
  handleSubmit,
}) => {
  const { name, comments, breed, color, size, panel, sex, state } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="d-flex justify-content-center">
          <label htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={handleImageChange}
            />
            <Fab
              component="span"
              aria-label="add"
              variant="extended"
              style={{ backgroundColor: Colors.turquise, color: "white" }}
            >
              <ImageIcon /> Agregar Imagen
            </Fab>
          </label>
        </div>

        <TextField
          className="mt-3"
          onChange={handleChange}
          fullWidth
          name="name"
          placeholder="Nombre"
          value={name}
          id="outlined-name"
        />

        <TextField
          className="mt-3"
          onChange={handleChange}
          fullWidth
          name="comments"
          placeholder="Comentarios"
          value={comments}
          id="outlined-name"
          multiline
        />

        <Select
          className="mt-2"
          fullWidth
          value={sex}
          onChange={handleChange}
          displayEmpty
          name="sex"
        >
          <MenuItem value="" disabled>
            Sexo
          </MenuItem>
          <MenuItem value={`Hembra`}>Hembra</MenuItem>
          <MenuItem value={`Macho`}>Macho</MenuItem>
        </Select>

        <Select
          className="mt-2"
          fullWidth
          value={state}
          onChange={handleChange}
          displayEmpty
          name="state"
        >
          <MenuItem value="" disabled>
            Estado
          </MenuItem>
          {States.map((state) => {
            return (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            );
          })}
        </Select>

        <TextField
          className="mt-3"
          onChange={handleChange}
          fullWidth
          name="breed"
          placeholder="Raza"
          value={breed}
          id="outlined-name"
        />

        <TextField
          className="mt-3"
          onChange={handleChange}
          fullWidth
          name="color"
          placeholder="Color"
          value={color}
          id="outlined-name"
        />

        <TextField
          className="mt-3"
          onChange={handleChange}
          fullWidth
          name="size"
          placeholder="Tamaño"
          value={size}
          id="outlined-name"
        />

        <Select
          className="mt-2"
          fullWidth
          value={panel}
          onChange={handleChange}
          displayEmpty
          name="panel"
        >
          <MenuItem value="" disabled>
            Panel
          </MenuItem>
          <MenuItem value="">Ninguno</MenuItem>
          {Panels.map((panel) => {
            return (
              <MenuItem key={panel} value={panel}>
                {panel}
              </MenuItem>
            );
          })}
        </Select>
      </div>

      <button className="btn-turq">Guardar</button>
    </form>
  );
};

export default PetEditForm;

export const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const GET_TIME_DIFFERENCE = (date) => {
  return parseInt(date.getTimezoneOffset() / 60);
};

export const ADD_DAYS = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const ADD_YEARS = (date, years) => {
  const finalDate = new Date(date.setFullYear(date.getFullYear() + years));
  return finalDate;
};

export const GET_FORMATTED_DATE_STRING = (date, formatOptions) => {
  return new Date(
    new Date(date).setHours(
      new Date(date).getHours() + GET_TIME_DIFFERENCE(new Date(date))
    )
  ).toLocaleDateString("es-MX", formatOptions);
};

import React, { useEffect, useState } from "react";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";
import { Fab } from "@material-ui/core";
import SquareLoader from "react-spinners/SquareLoader";
import TrainingCard from "../components/cards/TrainingCard";
import { useSelector } from "react-redux";
import { isAdmin } from "../common/Permissions";
import { AiFillDelete } from "react-icons/ai";
import NewTrainingModal from "../components/modals/NewTrainingModal";
import { getTrainings } from "../actions/trainings";

const TrainingPage = () => {
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const loadTrainings = async () => {
    setLoading(true);
    const { data } = await getTrainings(token);
    setTrainings(data);
    setLoading(false);
  };

  useEffect(() => {
    loadTrainings();
  }, []);

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Entrenamientos</h2>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center flex-wrap mt-5">
          <SquareLoader color={Colors.orange} loading={loading} size={100} />
          <h1 className="m-3">Cargando...</h1>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center mt-5 mx-3">
            <Fab
              component="span"
              aria-label="add"
              variant="extended"
              onClick={() => setShowModal(true)}
              className="text-center"
              style={{
                backgroundColor: Colors.turquise,
                color: "white",
              }}
            >
              + Registrar nuevo entrenamiento
            </Fab>

            {showModal && (
              <NewTrainingModal
                showModal={showModal}
                setShowModal={setShowModal}
                loadTrainings={loadTrainings}
              />
            )}
          </div>

          {trainings.length > 0 ? (
            <div className="my-5">
              <div className="container tbl-container">
                <div className="row tbl-fixed">
                  <div className="table-responsive">
                    <table className="table table-sm table-hover table-borderless">
                      <thead>
                        <tr>
                          <th>
                            {auth && isAdmin(auth.user) && (
                              <p className="text-center tag-blue m-2">
                                Acciones
                              </p>
                            )}
                          </th>
                          <th>
                            <p className="text-center tag-blue m-2">
                              # de perros
                            </p>
                          </th>
                          <th>
                            <p className="text-center tag-blue m-2">Fecha</p>
                          </th>
                          <th>
                            <p className="text-center tag-blue m-2">
                              Comentarios
                            </p>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {trainings
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((training, index) => {
                            return (
                              <TrainingCard
                                key={`training-card-${index}`}
                                training={training}
                                loadTrainings={loadTrainings}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="text-center mt-5">No hay ningun entrenamiento</h1>
          )}
        </>
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default TrainingPage;

const NewPwForm = ({ handleSubmit, password, setPassword, token }) => (
  <form onSubmit={handleSubmit} className="mt-3">
    <div className="form-group mb-3">
      <label className="form-label">Ingrese su nueva contraseña</label>
      <input
        type="password"
        className="form-control"
        placeholder="********"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
    <div className="d-flex" style={{ float: "right" }}>
      <button className="btn-turq">Actualizar mi contraseña</button>
    </div>
  </form>
);

export default NewPwForm;

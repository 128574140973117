import React, { useEffect, useState } from "react";
import { Button, Card, Form, FormLabel } from "react-bootstrap";
import {
  AiFillCheckCircle,
  AiFillDelete,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { DeleteFilled } from "@ant-design/icons";
import { Colors } from "../common/Colors";
import { isAdmin, isSuperAdmin } from "../common/Permissions";
import { useSelector } from "react-redux";
import { createTodo, deleteTodo, getTodos, updateTodo } from "../actions/todos";
import moment from "moment";

const TodoList = () => {
  const [newTodoTitle, setNewTodoTitle] = useState("");
  const [newTodoComments, setNewTodoComments] = useState("");
  const [todos, setTodos] = useState([]);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const loadTodos = async () => {
    let res = await getTodos(token);
    const newTodos = res.data;
    newTodos.sort(
      (a, b) =>
        a.completed - b.completed || b.createdAt.localeCompare(a.createdAt)
    );
    setTodos(newTodos);
  };

  useEffect(() => {
    loadTodos();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newTodoTitle) return;
    await addTodo(newTodoTitle, newTodoComments);
    setNewTodoTitle("");
    setNewTodoComments("");
  };

  const addTodo = async (title, comments) => {
    let todoData = new FormData();
    todoData.append("title", title);
    await createTodo(token, todoData);
    await loadTodos();
  };

  const markTodo = async (index) => {
    await updateTodo(token, todos[index]._id);
    await loadTodos();
  };

  const removeTodo = async (index) => {
    await deleteTodo(token, todos[index]._id);
    await loadTodos();
  };

  return (
    <div>
      {auth && (isAdmin(auth.user) || isSuperAdmin(auth.user)) && (
        <Form onSubmit={handleSubmit} className="d-flex flex-column">
          <Form.Group>
            <Form.Label>
              <b>Agregar nueva tarea</b>
            </Form.Label>
            <Form.Control
              type="text"
              className="input mb-2"
              value={newTodoTitle}
              onChange={(e) => setNewTodoTitle(e.target.value)}
              placeholder="Titulo"
            />
            {/* <Form.Control type="text" className="input mb-2" value={newTodoComments} onChange={e => setNewTodoComments(e.target.value)} placeholder="Comentarios" /> */}
          </Form.Group>
          <Button
            variant="primary mb-5"
            type="submit"
            className="align-items-center"
          >
            Agregar
          </Button>
        </Form>
      )}

      <div className="mb-5">
        {todos.length > 0 ? (
          <div>
            {todos.map((todo, index) => (
              <Card className="mb-3" key={"todo-" + index}>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="p-2">
                      {todo.completed ? (
                        <AiFillCheckCircle
                          className={
                            auth &&
                            (isAdmin(auth.user) || isSuperAdmin(auth.user))
                              ? "pointer"
                              : ""
                          }
                          onClick={
                            auth &&
                            (isAdmin(auth.user) || isSuperAdmin(auth.user))
                              ? () => markTodo(index)
                              : null
                          }
                          color={Colors.turquise}
                          size={30}
                        >
                          ✓
                        </AiFillCheckCircle>
                      ) : (
                        <AiOutlineCheckCircle
                          className="pointer"
                          onClick={() => markTodo(index)}
                          color={Colors.turquise}
                          size={30}
                        >
                          ✓
                        </AiOutlineCheckCircle>
                      )}
                    </div>

                    <div className="d-flex flex-column">
                      <span
                        className={
                          todo.completed
                            ? "text-decoration-line-through text-secondary p-2"
                            : "p-2"
                        }
                      >
                        {todo.title}
                      </span>
                      {todo.completedBy && todo.completed && (
                        <FormLabel
                          className={
                            todo.completed
                              ? "text-secondary font-weight-light h6 ml-4"
                              : "font-weight-light h6 ml-4"
                          }
                        >
                          Completada por {todo.completedBy.name}
                        </FormLabel>
                      )}
                    </div>

                    {auth &&
                      (isAdmin(auth.user) || isSuperAdmin(auth.user)) && (
                        <AiFillDelete
                          onClick={() => removeTodo(index)}
                          size={25}
                          style={{
                            color: Colors.orangeDark,
                            right: "5%",
                            bottom: "15%",
                          }}
                          className="position-absolute pointer"
                        />
                      )}
                  </div>
                  {todo.comments && (
                    <div className="mt-3 ml-3">
                      <FormLabel
                        className={
                          todo.completed
                            ? "text-secondary font-weight-normal"
                            : "font-weight-normal"
                        }
                      >
                        {todo.comments}
                      </FormLabel>
                    </div>
                  )}
                  <div className="mt-3 ml-3">
                    <FormLabel
                      className={
                        todo.completed
                          ? "text-secondary font-weight-normal"
                          : "font-weight-normal"
                      }
                    >
                      Creada {moment(todo.createdAt).fromNow(false)}
                    </FormLabel>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        ) : (
          <h1>No hay tareas pendientes!</h1>
        )}
      </div>
    </div>
  );
};

export default TodoList;

import QrReader from "react-qr-reader";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";
import { toast } from "react-toastify";

const ReadQR = ({ history }) => {
  const handleScanWebCam = (result) => {
    if (result) {
      history.push({
        pathname: "/pet/" + result,
        state: true,
      });
    }
  };

  const handleErrorWebCam = (error) => {
    console.log(error);
    toast.error(`Hay un error al acceder a la cámara`);
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: Colors.orange,
          position: "relative",
        }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Escanear QR</h2>
      </div>
      <div className="m-3">
        <QrReader
          delay={300}
          style={{ width: "100%" }}
          onError={handleErrorWebCam}
          onScan={handleScanWebCam}
        />
      </div>
    </>
  );
};

export default ReadQR;

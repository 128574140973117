import { TextField } from "@material-ui/core";
import { FaUserMd } from "react-icons/fa";
import { GrLinkPrevious } from "react-icons/gr";
import { AiOutlineCheck } from "react-icons/ai";
import { Colors } from "../../common/Colors";
import { useSelector } from "react-redux";
import { isAdmin } from "../../common/Permissions";
import { DatePicker } from "antd";

const DiagnosisForm = ({
  values,
  handleChange,
  prevStep,
  handleSubmit,
  setValues,
}) => {
  const { auth } = useSelector((state) => ({ ...state }));

  const {
    definitiveDiagnosis,
    administeredMedications,
    prescriptionDrugs,
    examinedBy,
  } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <TextField
          onChange={handleChange}
          fullWidth
          name="definitiveDiagnosis"
          placeholder="Diagnóstico definitivo"
          value={definitiveDiagnosis}
          id="outlined-multiline-static"
          multiline
          className="mt-3"
        />

        <TextField
          onChange={handleChange}
          fullWidth
          name="administeredMedications"
          placeholder="Medicamentos administrados"
          value={administeredMedications}
          id="outlined-multiline-static"
          multiline
          className="mt-3"
        />

        <TextField
          onChange={handleChange}
          fullWidth
          name="prescriptionDrugs"
          placeholder="Medicamentos recetados"
          value={prescriptionDrugs}
          id="outlined-multiline-static"
          multiline
          className="mt-3"
        />

        <div className="mt-4 d-flex align-items-center justify-content-center">
          <FaUserMd
            size={110}
            className="icon-btn"
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: Colors.turquise,
              borderRadius: 110,
            }}
          />
          <TextField
            onChange={handleChange}
            name="examinedBy"
            placeholder="Examinado por..."
            value={examinedBy}
            id="outlined-name"
          />
        </div>

        {isAdmin(auth.user) ? (
          <div className="d-flex justify-content-center flex-wrap">
            <DatePicker
              placeholder="Fecha de consulta"
              className="form-control mt-3"
              onChange={(date, dateString) =>
                setValues({ ...values, createdAt: dateString })
              }
            />
            <span className="font-italic">
              Si no se selecciona la fecha, se asignará la actual
            </span>
          </div>
        ) : null}
      </div>

      <div className="d-flex justify-content-between">
        <GrLinkPrevious
          size={40}
          onClick={prevStep}
          style={{ cursor: "pointer" }}
        />
        <AiOutlineCheck
          size={50}
          onClick={handleSubmit}
          color="white"
          style={{
            padding: 3,
            backgroundColor: Colors.green,
            borderRadius: 100,
            cursor: "pointer",
          }}
        />
      </div>
    </form>
  );
};

export default DiagnosisForm;

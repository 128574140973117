import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { createWeight, getWeights, deleteAction } from "../actions/control";
import { updatePet } from "../actions/pet";
import { useSelector } from "react-redux";
import WeightCreateForm from "../components/forms/WeightCreateForm";
import { GrLinkPrevious } from "react-icons/gr";
import ClipLoader from "react-spinners/ClipLoader";
import { BsFileSpreadsheet } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";

const NewWeight = ({ match }) => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  // state
  const [values, setValues] = useState({
    weight: "",
    createdAt: new Date(),
  });
  const [weights, setWeights] = useState([]);
  const [loading, setLoading] = useState(true);
  const { weight, createdAt } = values;

  useEffect(() => {
    loadWeights();
  }, []);

  const loadWeights = async () => {
    const res = await getWeights(match.params.petId);
    setWeights(res.data.reverse());
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let weightData = new FormData();
    weightData.append("weight", weight);
    weightData.append("madeFor", match.params.petId);
    weightData.append("createdAt", createdAt);
    let petData = new FormData();
    petData.append("weight", weight);

    try {
      await createWeight(token, weightData);
      await updatePet(token, petData, match.params.petId);
      toast.success(`Pesaje exitoso!`);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleActionDelete = async (actionId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Acción?")) return;
    deleteAction(token, actionId).then((res) => {
      toast.success("Acción eliminada");
      window.location.reload();
    });
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Agregar Pesaje</h2>
      </div>

      <div className="container-fluid col-md-8 col-md-offset-4 p-4">
        <div className="row">
          <br />
          <WeightCreateForm
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </div>

        <h4 className="text-center mt-3">Historial de pesos</h4>

        <div className="d-flex justify-content-center mb-2">
          {isAdmin(auth.user) ? (
            <a
              href={`${process.env.REACT_APP_API}/weight-format/${match.params.petId}`}
              download
              className="btn-turq"
              style={{ color: "white" }}
            >
              <BsFileSpreadsheet size={30} />
            </a>
          ) : null}
        </div>

        {loading ? (
          <ClipLoader loading={true} size={200} className="m-3" />
        ) : weights.length > 0 ? (
          <div>
            <div className="d-flex justify-content-between">
              <p className="font-weight-bold">Peso</p>
              <p className="font-weight-bold">Fecha</p>
              {isAdmin(auth.user) ? (
                <AiFillDelete style={{ opacity: 0 }} />
              ) : null}
            </div>
            {weights.map((w) => {
              return (
                <div className="d-flex justify-content-between">
                  <p>{w.weight} kg</p>
                  <p>{new Date(w.createdAt).toLocaleDateString()}</p>
                  {isAdmin(auth.user) ? (
                    <AiFillDelete
                      onClick={() => handleActionDelete(w._id)}
                      className=" mt-2"
                      style={{ color: Colors.orangeDark }}
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-muted text-center mt-3">
            No hay pesos por mostrar
          </p>
        )}
      </div>
    </>
  );
};

export default NewWeight;

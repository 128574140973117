import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { hasImage } from "../../actions/auth";
import { Avatar } from "@material-ui/core";
import { Colors } from "../../common/Colors";
import { isAdmin, isSuperAdmin } from "../../common/Permissions";

const UserCard = ({
  user,
  handleUserDelete = (f) => f,
  showViewMoreButton = true,
}) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    loadImage();
  }, []);

  const loadImage = () => {
    if (user.image && user.image.contentType) {
      setImage(`${process.env.REACT_APP_API}/user/image/${user._id}`);
      return;
    }

    setImage(null);
  };

  const history = useHistory();
  const ColorList = [
    Colors.orange,
    Colors.yellow,
    Colors.blue,
    Colors.turquise,
  ];
  return (
    <>
      <div className="card m-1">
        <div className="row no-gutters">
          <div className="col m-1 d-flex justify-content-center align-items-center">
            {image ? (
              <Avatar
                src={image}
                style={{
                  width: 140,
                  height: 140,
                  fontSize: 50,
                }}
              />
            ) : (
              <Avatar
                style={{
                  width: 140,
                  height: 140,
                  fontSize: 50,
                  backgroundColor:
                    ColorList[Math.floor(Math.random() * ColorList.length)],
                }}
              >
                {user.name[0]}
              </Avatar>
            )}
          </div>
          <div className="col">
            <div className="card-body">
              <h4 className="card-title">{user.name}</h4>
              <h6
                className={
                  isAdmin(user) || isSuperAdmin(user)
                    ? "font-weight-bold card-text"
                    : "card-text"
                }
              >
                Rol:{" "}
                {isSuperAdmin(user)
                  ? "Super-Admin"
                  : isAdmin(user)
                  ? "Administrador"
                  : "Colaborador"}{" "}
                {isAdmin(user)}
              </h6>
              <div className="d-flex justify-content-between h4">
                {showViewMoreButton && (
                  <button
                    onClick={() => history.push(`/user/${user._id}`)}
                    className="btn-turq"
                  >
                    Ver más
                  </button>
                )}
                <AiFillDelete
                  onClick={() => handleUserDelete(user._id)}
                  className="mt-2"
                  style={{ color: Colors.orangeDark }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCard;

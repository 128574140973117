import { useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Modal } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../common/Colors";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SquareLoader from "react-spinners/SquareLoader";
import DragDropFile from "../forms/DragDropFile";
import { useSelector } from "react-redux";
import { uploadTest } from "../../actions/tests";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    marginTop: "40px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
});

const DigestibilityModal = ({
  panel,
  showModal,
  setShowModal,
  loadPanelRecords,
}) => {
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [comments, setComments] = useState("");
  const [file, setFile] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleChange = (newDate, isStart) => {
    if (!isSelected) {
      setIsSelected(true);
    }
    isStart ? setStartDate(newDate["$d"]) : setEndDate(newDate["$d"]);
  };

  const handleCommentsChange = (e) => {
    e.preventDefault();
    setComments(e.target.value);
  };

  const createNewRecord = async () => {
    console.log("here FIRST", file);
    if (!file) {
      toast.error("Error: debes subir un archivo con extension .xlsx");
      return;
    }

    try {
      let fileData = new FormData();
      fileData.append("file", file);
      fileData.append("comments", comments);
      console.log("here SECOND");
      for (var key of fileData.entries()) {
        console.log("fileData:\n", key[0] + ", " + key[1]);
      }
      const res = await uploadTest(token, fileData, panel._id);
      toast.success("Nueva prueba creada!");
      loadPanelRecords(panel._id);
      clearAllStuff();
      setShowModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  const clearAllStuff = () => {
    setFile(null);
    setStartDate(new Date(Date.now()));
    setEndDate(new Date(Date.now()));
    setComments("");
    setIsSelected(false);
  };

  useEffect(() => {
    clearAllStuff();
  }, []);

  return (
    <Modal
      open={showModal}
      centered
      width={1000}
      closeIcon={
        <AiOutlineCloseCircle
          className="pointer"
          color={Colors.orange}
          size={30}
        />
      }
      onCancel={() => setShowModal(!showModal)}
    >
      <div
        className="container-fluid p-3 my-4 d-flex justify-content-center align-items-center border rounded"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <h2 className="text-white text-center">
          Agregar nueva prueba en
          <br />"{panel.name}"
        </h2>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="container-fluid">
          {isLoading ? (
            <>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <SquareLoader
                  color={Colors.orange}
                  loading={isLoading}
                  size={100}
                />
                <h1 className="m-3">Cargando...</h1>
              </div>
            </>
          ) : (
            <>
              {/* <div className="row align-items-center text-center justify-content-center">
                    <div className="col my-5">
                      <MobileDatePicker
                        label="Fecha de inicio"
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        cancelLabel="Cancelar"
                        onChange={(value) => handleChange(value, true)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>

                    <div className="col">
                      <MobileDatePicker
                        label="Fecha de fin"
                        inputFormat="DD/MM/YYYY"
                        value={endDate}
                        cancelLabel="Cancelar"
                        onChange={(value) => handleChange(value, false)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </div> */}

              <div className="row align-items-center text-center justify-content-center mb-5">
                <div className="col">
                  <label>
                    <h4>Archivo</h4>
                  </label>
                  <DragDropFile file={file} setFile={setFile} />
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    <h4>Comentarios</h4>
                  </label>
                  <textarea
                    className="form-control w-100 align-items-center justify-content-center"
                    id="exampleFormControlTextarea1"
                    rows="9"
                    value={comments}
                    onChange={handleCommentsChange}
                  ></textarea>
                </div>
              </div>

              <div className="row align-items-center text-center justify-content-center mt-4">
                <button className="mb-3 btn-turq" onClick={createNewRecord}>
                  Crear
                </button>
              </div>
            </>
          )}
        </div>
      </LocalizationProvider>
    </Modal>
  );
};

export default DigestibilityModal;

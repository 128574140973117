import React, { useEffect, useState } from "react";
import { Colors } from "../common/Colors";
import { GrLinkPrevious } from "react-icons/gr";
import { Dialog, DialogActions, Grid } from "@material-ui/core";
import { BiImageAdd } from "react-icons/bi";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import "./NewStoolRegister.styles.css";
import { toast } from "react-toastify";
import { createStoolRegister } from "../actions/stool";
import { useSelector } from "react-redux";
import stoolTable from "../assets/img/stoolTable.jpg";

const NewStoolRegister = ({ history, match }) => {
  const [preview, setPreview] = useState(undefined);
  const [openTable, setOpenTable] = useState(false);
  const [newRegister, setNewRegister] = useState({
    score: 0,
    image: "",
  });
  const { petId } = match.params;

  const { auth } = useSelector((state) => ({ ...state }));

  const handleChangeScore = (scale) => {
    setNewRegister({ ...newRegister, score: newRegister.score + scale });
  };

  const handleInputClicked = () => {
    const input = document.querySelector(`#image-input`);
    input.click();
  };

  const handleImageChange = (e) => {
    // ==> Removing filter for images
    // if (e.target.files[0].size > 1000000) {
    //   toast.error("La imagen debe pesar menos de 1MB");
    //   return;
    // }
    setPreview(URL.createObjectURL(e.target.files[0]));
    setNewRegister({ ...newRegister, image: e.target.files[0] });
  };

  const handleSubmit = async () => {
    if (!newRegister.image || newRegister.score <= 0) {
      toast.error("Completa los campos para continuar");
      return;
    }

    const stoolRegisterData = new FormData();
    stoolRegisterData.append("score", newRegister.score);
    newRegister.image && stoolRegisterData.append("image", newRegister.image);

    try {
      await createStoolRegister(petId, stoolRegisterData, auth.token);

      toast.success("Registro guardado");

      setTimeout(() => {
        history.push(`/pet/${petId}`);
      }, 1500);
    } catch (error) {
      toast.error("Error al guardar registro");
    }
  };

  return (
    <div>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h4 className="text-white text-center">Nuevo registro de heces</h4>
      </div>
      <div className="container mt-5">
        <Grid container spacing={3}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <div className="image-holder">
              {preview ? (
                <>
                  <img alt={petId} src={preview} className="image-preview" />
                  <button
                    className="btn-turq mt-3"
                    onClick={handleInputClicked}
                  >
                    Cambiar Imagen
                  </button>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    name="image-input"
                    id="image-input"
                    className="input"
                    onChange={handleImageChange}
                    capture="enviroment"
                  />
                </>
              ) : (
                <>
                  <div className="no-image" onClick={handleInputClicked}>
                    <BiImageAdd size={60} className="icon-image" />
                  </div>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    name="image-input"
                    id="image-input"
                    className="input"
                    onChange={handleImageChange}
                    capture="enviroment"
                  />
                </>
              )}
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <h3 className="text-center">Calificación</h3>
            <div className="d-flex align-items-center">
              <AiFillCaretLeft
                size={50}
                className="icon ml-3 mr-3"
                onClick={() =>
                  handleChangeScore(newRegister.score > 0 ? -0.25 : 0)
                }
              />
              <h3>{newRegister.score}</h3>
              <AiFillCaretRight
                size={50}
                className="icon ml-3 mr-3"
                onClick={() => handleChangeScore(0.25)}
              />
            </div>
            <button className="btn-turq mt-5" onClick={handleSubmit}>
              Guardar
            </button>
            <button
              className="btn-turq mt-5"
              onClick={() => setOpenTable(true)}
            >
              Ver escala de calidad de heces
            </button>
          </Grid>
        </Grid>
      </div>
      <Dialog open={openTable}>
        <img alt="Tabla de heces" src={stoolTable} />
        <DialogActions className="d-flex flex-column align-items-center justify-content-center">
          <button className="btn-turq" onClick={() => setOpenTable(false)}>
            Cerrar
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewStoolRegister;

import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import UserCard from "../components/cards/UserCard";
import { getUsers, deleteUser } from "../actions/auth";
import { toast } from "react-toastify";
import SquareLoader from "react-spinners/SquareLoader";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";

const Users = ({ history }) => {
  const [loading, setLoading] = useState(true);

  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));
  const [users, setUsers] = useState([]);

  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    if (!auth || !isAdmin(auth.user)) {
      history.push("/");
    }
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const res = await getUsers();
    setUsers(res.data);
    setLoading(false);
  };

  const handleUserDelete = async (userId) => {
    if (!window.confirm("¿Estás seguro de eliminar al Usuario?")) return;
    deleteUser(token, userId).then((res) => {
      toast.success("Usuario eliminado");
      loadUsers();
    });
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Usuarios</h2>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <SquareLoader
            loading={loading}
            size={100}
            className="m-3"
            color={Colors.orange}
          />
          <h1 className="m-3">Cargando...</h1>
        </div>
      ) : (
        <div className="col justify-content-center align-items-center">
          {users.map((user) => (
            <UserCard
              key={user._id}
              user={user}
              handleUserDelete={handleUserDelete}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Users;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card } from "antd";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
import { readUser, hasImage } from "../actions/auth";
import "moment/locale/es";
import { AiFillStar } from "react-icons/ai";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";

const { Meta } = Card;

const ConnectNav = () => {
  const [values, setValues] = useState({
    name: "",
    createdAt: "",
  });
  const [image, setImage] = useState("");

  const { auth } = useSelector((state) => ({ ...state }));
  const { user } = auth;
  moment.locale("es");

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    let res = await readUser(user._id);
    setValues({ ...values, ...res.data });
    let hi = await hasImage(user._id);
    hi.data.ok
      ? setImage(`${process.env.REACT_APP_API}/user/image/${res.data._id}`)
      : setImage(null);
  };

  const { name, createdAt } = values;

  return (
    <>
      <div className="d-flex justify-content-around">
        <Card>
          <Meta
            avatar={
              <div style={{ position: "relative" }}>
                {image ? (
                  <Avatar
                    src={image}
                    style={{
                      width: 70,
                      height: 70,
                    }}
                  />
                ) : (
                  <Avatar
                    style={{
                      width: 70,
                      height: 70,
                    }}
                  >
                    {name[0]}
                  </Avatar>
                )}
                {auth && isAdmin(auth.user) && (
                  <AiFillStar
                    color={Colors.gold}
                    size={30}
                    style={{ position: "absolute", bottom: -5, right: -5 }}
                  />
                )}
              </div>
            }
            title={name}
            description={`Creado ${moment(createdAt).fromNow()}`}
          />
        </Card>
      </div>
      <div className="d-flex justify-content-around">
        <Link to={`/user/edit/${user._id}`}>
          <button className="btn-turq mt-3">Editar usuario</button>
        </Link>
      </div>
    </>
  );
};

export default ConnectNav;

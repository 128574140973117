const adminRoles = ["admin", "super-admin"];

export const isAdmin = (user) => {
  if (!user.userRole || !user) return false;

  if (adminRoles.includes(user?.userRole)) {
    return true;
  }
  return false;
};

export const isSuperAdmin = (user) => {
  if (!user.userRole || !user) return false;

  if (user?.userRole === "super-admin") {
    return true;
  }
  return false;
};

import React, { useState, useEffect, useReducer } from "react";
import { read, updateClean } from "../actions/cage";
import {
  createClean,
  getCleanByCage,
  getCleanImgsByCage,
} from "../actions/control";
import moment from "moment";
import { getPetsInCage } from "../actions/pet";
import PetCard from "../components/cards/PetCard";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { deletePet } from "../actions/pet";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillClockCircle,
} from "react-icons/ai";
import SimpleImageSlider from "react-simple-image-slider";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Fab } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import Resizer from "react-image-file-resizer";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const ViewCage = ({ match, history }) => {
  const [cage, setCage] = useState({});
  const [pets, setPets] = useState([]);
  const [clean, setClean] = useState({});
  const [uploading, setUploading] = useState(false);
  const [loadingClean, setLoadingClean] = useState(true);
  const [prevCleanImgs, setPrevCleanImgs] = useState([]);
  const [cleanImgs, setCleanImgs] = useState([]);
  const [canClean, setCanClean] = useState(true);
  const [open, setOpen] = useState(false);

  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadCage();
    loadAllPets();
  }, []);

  useEffect(() => {
    checkClean();
  }, [cage]);

  const checkClean = () => {
    if (!isClean(new Date(), new Date(cage?.lastClean))) {
      setCanClean(true);
    } else {
      loadClean();
      setCanClean(false);
    }
  };

  const loadCage = async () => {
    try {
      let res = await read(match.params.cageId);
      setCage(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAllPets = async () => {
    let res = await getPetsInCage(match.params.cageId);
    setPets(res.data);
  };

  const loadClean = async () => {
    let res = await getCleanByCage(match.params.cageId);
    setClean(res.data);
    res = await getCleanImgsByCage(match.params.cageId);

    const imgsArr = Array.from(res.data).map((f) => {
      const base64String = btoa(String.fromCharCode(...new Uint8Array(f.data)));
      const src = `data:image/png;base64,${base64String}`;
      return { url: src };
    });

    setPrevCleanImgs(imgsArr);
    setLoadingClean(false);
  };

  const handleClean = async () => {
    setUploading(true);
    let cleanData = new FormData();
    cleanImgs[0] && cleanData.append("image1", cleanImgs[0]);
    cleanImgs[1] && cleanData.append("image2", cleanImgs[1]);
    cleanImgs[2] && cleanData.append("image3", cleanImgs[2]);
    cleanData.append("madeFor", match.params.cageId);

    console.log(new Date());

    let cageData = new FormData();
    cageData.append("lastClean", new Date());

    try {
      await createClean(token, cleanData);
      await updateClean(token, match.params.cageId, cageData);
      toast.info("Esperando aprobación", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
    setUploading(false);
  };

  const handlePetDelete = async (petId) => {
    if (!window.confirm("¿Este perro saldrá del bioterio?")) return;
    deletePet(token, petId).then((res) => {
      toast.success(`El perro salió del bioterio.`);
      loadAllPets();
    });
  };

  const handlePetTransfer = (petId) => {
    history.push(`/pet/transferpet/${petId}`);
  };

  const isClean = (now, past) => {
    if (
      now.getDate() > past.getDate() ||
      now.getMonth() > past.getMonth() ||
      now.getFullYear() > past.getFullYear()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleImageChange = async (e) => {
    if (e.target.files) {
      const imgsArr = Array.from(e.target.files).map((file) => {
        return { url: URL.createObjectURL(file) };
      });

      setPrevCleanImgs((prevImgs) => prevImgs.concat(imgsArr));
      const rFile = await resizeFile(e.target.files[0]);
      console.log("rFile", rFile);
      setCleanImgs([...cleanImgs, rFile]);
    }
  };

  const handleRemoveImage = (photo) => {
    setPrevCleanImgs(prevCleanImgs.filter((img) => img !== photo));
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <div className="img-wrap" key={`render-photo-${index}`}>
          <AiFillCloseCircle
            className="close"
            color="black"
            onClick={() => handleRemoveImage(photo)}
          />
          <img
            className="m-2"
            src={photo.url}
            key={photo.url}
            width={200}
            alt={photo.url}
          />
        </div>
      );
    });
  };

  const handleCancel = () => {
    setPrevCleanImgs([]);
    setOpen(false);
  };

  const handleOpen = () => {
    setPrevCleanImgs([]);
    setOpen(true);
  };

  return (
    <>
      {cage.isDiningRoom ? (
        <ViewDiningRoomCage
          cage={cage}
          handleCancel={handleCancel}
          handleOpen={handleOpen}
          renderPhotos={renderPhotos}
          handleImageChange={handleImageChange}
          handleClean={handleClean}
          clean={clean}
          uploading={uploading}
          loadingClean={loadingClean}
          prevCleanImgs={prevCleanImgs}
          canClean={canClean}
          open={open}
        />
      ) : (
        <DefaultViewCage
          cage={cage}
          handleCancel={handleCancel}
          handleOpen={handleOpen}
          renderPhotos={renderPhotos}
          handleImageChange={handleImageChange}
          handlePetTransfer={handlePetTransfer}
          handlePetDelete={handlePetDelete}
          handleClean={handleClean}
          pets={pets}
          clean={clean}
          uploading={uploading}
          loadingClean={loadingClean}
          prevCleanImgs={prevCleanImgs}
          canClean={canClean}
          open={open}
        />
      )}
    </>
  );
};

const ViewDiningRoomCage = ({
  cage,
  handleCancel,
  handleOpen,
  renderPhotos,
  handleImageChange,
  handleClean,
  clean,
  uploading,
  loadingClean,
  prevCleanImgs,
  canClean,
  open,
}) => {
  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">{cage.name}</h2>
      </div>
      <div className="container-fluid col-md-8 col-md-offset-4">
        <div>
          <br />
          <h5>
            <b>Comentarios: </b>
            {cage.comments}
          </h5>
          <br />
          <h5 className="font-weight-bold">
            Activa desde {moment(cage.activeFrom).fromNow()}
          </h5>
          <br />
          {cage.postedBy ? (
            <i>Creada por {cage.postedBy && cage.postedBy.name}</i>
          ) : null}
          <h5 className="font-weight-bold text-center mt-3">Limpieza: </h5>
          {loadingClean ? (
            <div className="d-flex justify-content-center">
              <ClipLoader loading={loadingClean} size={200} className="m-3" />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              {canClean ? (
                <>
                  <Fab
                    className="m-3"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    style={{
                      backgroundColor: Colors.turquise,
                      color: Colors.white,
                    }}
                    onClick={() => handleOpen()}
                  >
                    Limpiar Comedor
                  </Fab>
                </>
              ) : (
                <div>
                  <div className="container">
                    {prevCleanImgs.length > 0 ? (
                      <SimpleImageSlider
                        width={330}
                        height={250}
                        images={prevCleanImgs}
                        navStyle={1}
                        showBullets
                        showNavs
                      />
                    ) : null}
                  </div>
                  <div className="container mt-3 d-flex justify-content-center">
                    {clean && clean.approved ? (
                      <>
                        <span className="text-center">
                          <AiFillCheckCircle size={20} color={Colors.green} />{" "}
                          Comedor limpia
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-center">
                          <AiFillClockCircle size={20} color={Colors.blue} />{" "}
                          Esperando aprobación
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="md"
            fullScreen
          >
            <DialogTitle className="text-center">
              Toma algunas fotos para continuar
            </DialogTitle>
            <DialogContent>
              {prevCleanImgs.length < 3 ? (
                <div className="d-flex justify-content-center">
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      capture="enviroment"
                      onChange={handleImageChange}
                    />
                    <Fab
                      component="span"
                      aria-label="add"
                      variant="extended"
                      style={{
                        backgroundColor: Colors.turquise,
                        color: Colors.white,
                      }}
                    >
                      <ImageIcon /> Agregar Imagen
                    </Fab>
                  </label>
                </div>
              ) : (
                <p className="text-center">Solo puedes añadir tres fotos</p>
              )}
              <div className="flex-wrap d-flex justify-content-center">
                {prevCleanImgs ? renderPhotos(prevCleanImgs) : null}
              </div>
            </DialogContent>
            <DialogActions className="flex-wrap justify-content-around">
              <button className="btn-turq" onClick={handleCancel}>
                Cancelar
              </button>
              <button
                className="btn-turq"
                onClick={handleClean}
                disabled={prevCleanImgs.length <= 0 || prevCleanImgs.length > 3}
              >
                Guardar
              </button>
            </DialogActions>
          </Dialog>

          <Dialog open={uploading} maxWidth="md">
            <DialogTitle className="text-center">
              Subiendo, por favor espere...
            </DialogTitle>
          </Dialog>
        </div>
        <br />
      </div>
    </>
  );
};

const DefaultViewCage = ({
  cage,
  handleCancel,
  handleOpen,
  renderPhotos,
  handleImageChange,
  handlePetTransfer,
  handlePetDelete,
  handleClean,
  pets,
  clean,
  uploading,
  loadingClean,
  prevCleanImgs,
  canClean,
  open,
}) => {
  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Jaula {cage.name}</h2>
      </div>
      <div className="container-fluid col-md-8 col-md-offset-4">
        <div>
          <br />
          <h5>
            <b>Comentarios: </b>
            {cage.comments}
          </h5>
          <br />
          <h5 className="font-weight-bold">
            Activa desde {moment(cage.activeFrom).fromNow()}
          </h5>
          <br />
          {cage.postedBy ? (
            <i>Creada por {cage.postedBy && cage.postedBy.name}</i>
          ) : null}
          <h5 className="font-weight-bold text-center mt-3">Capacidad: </h5>
          <div className="d-flex justify-content-center mb-3">
            <Avatar
              style={{
                width: 100,
                height: 100,
                fontSize: 30,
                backgroundColor:
                  pets.length >= cage.capacity ? "black" : Colors.green,
              }}
            >
              {pets.length >= cage.capacity
                ? "¡Llena!"
                : (cage.capacity - pets.length).toString()}
            </Avatar>
          </div>
          <h5 className="font-weight-bold text-center mt-3">Limpieza: </h5>
          {loadingClean ? (
            <div className="d-flex justify-content-center">
              <ClipLoader loading={loadingClean} size={200} className="m-3" />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              {canClean ? (
                <>
                  <Fab
                    className="m-3"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    style={{
                      backgroundColor: Colors.turquise,
                      color: Colors.white,
                    }}
                    onClick={() => handleOpen()}
                  >
                    Limpiar Jaula
                  </Fab>
                </>
              ) : (
                <div>
                  <div className="container">
                    {prevCleanImgs.length > 0 ? (
                      <SimpleImageSlider
                        width={330}
                        height={250}
                        images={prevCleanImgs}
                        navStyle={1}
                        showBullets
                        showNavs
                      />
                    ) : null}
                  </div>
                  <div className="container mt-3 d-flex justify-content-center">
                    {clean && clean.approved ? (
                      <>
                        <span className="text-center">
                          <AiFillCheckCircle size={20} color={Colors.green} />{" "}
                          Jaula limpia
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-center">
                          <AiFillClockCircle size={20} color={Colors.blue} />{" "}
                          Esperando aprobación
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="md"
            fullScreen
          >
            <DialogTitle className="text-center">
              Toma algunas fotos para continuar
            </DialogTitle>
            <DialogContent>
              {prevCleanImgs.length < 3 ? (
                <div className="d-flex justify-content-center">
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      capture="enviroment"
                      onChange={handleImageChange}
                    />
                    <Fab
                      component="span"
                      aria-label="add"
                      variant="extended"
                      style={{
                        backgroundColor: Colors.turquise,
                        color: Colors.white,
                      }}
                    >
                      <ImageIcon /> Agregar Imagen
                    </Fab>
                  </label>
                </div>
              ) : (
                <p className="text-center">Solo puedes añadir tres fotos</p>
              )}
              <div className="flex-wrap d-flex justify-content-center">
                {prevCleanImgs ? renderPhotos(prevCleanImgs) : null}
              </div>
            </DialogContent>
            <DialogActions className="flex-wrap justify-content-around">
              <button className="btn-turq" onClick={handleCancel}>
                Cancelar
              </button>
              <button
                className="btn-turq"
                onClick={handleClean}
                disabled={prevCleanImgs.length <= 0 || prevCleanImgs.length > 3}
              >
                Guardar
              </button>
            </DialogActions>
          </Dialog>

          <Dialog open={uploading} maxWidth="md">
            <DialogTitle className="text-center">
              Subiendo, por favor espere...
            </DialogTitle>
          </Dialog>
        </div>
        <div>
          <br />
          <br />
          {pets.length > 0 ? (
            <h4>
              Dentro de <strong>{cage.name}</strong> se encuentran estos perros:
            </h4>
          ) : (
            <h4>
              No hay perros dentro de <strong>{cage.name}</strong>
            </h4>
          )}
        </div>
        <br />
        <div>
          <br />
          {pets.map((pet) => (
            <PetCard
              key={pet._id}
              pet={pet}
              handlePetDelete={handlePetDelete}
              handlePetTransfer={handlePetTransfer}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ViewCage;

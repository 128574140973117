import {
  AiOutlineQrcode,
  AiFillHome,
  AiOutlineFileProtect,
  AiFillFolder,
} from "react-icons/ai";
import { GiSittingDog, GiNightSleep } from "react-icons/gi";
import { AiOutlineSchedule } from "react-icons/ai";
import { BiDumbbell } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";

export const SidebarData = [
  {
    title: "Inicio",
    path: "/",
    icon: <AiFillHome size={30} />,
    cName: "nav-text",
    private: false,
  },
  {
    title: "Escanear QR",
    path: "/readqr",
    icon: <AiOutlineQrcode size={30} />,
    cName: "nav-text",
    private: false,
  },
  {
    title: "Perros",
    path: "/pets",
    icon: <GiSittingDog size={30} />,
    cName: "nav-text",
    private: false,
  },
  {
    title: "Jaulas",
    path: "/cages",
    icon: <GiNightSleep size={30} />,
    cName: "nav-text",
    private: false,
  },
  {
    title: "Acciones",
    path: "/actions",
    icon: <AiOutlineSchedule size={30} />,
    cName: "nav-text",
    private: false,
  },
  {
    title: "Drive",
    path: "/drive",
    icon: <AiFillFolder size={30} />,
    cName: "nav-text",
    private: false,
  },
  {
    title: "Entrenamientos",
    path: "/training",
    icon: <BiDumbbell size={30} />,
    cName: "nav-text",
    private: false,
  },
  {
    title: "Pruebas",
    path: "/testing",
    icon: <AiOutlineFileProtect size={30} />,
    cName: "nav-text",
    private: true,
  },
  {
    title: "Usuarios",
    path: "/users",
    icon: <FaUserAlt size={30} />,
    cName: "nav-text",
    private: true,
  },
];

export const Panels = [
  "Palatabilidad",
  "Calidad Heces",
  `CH / Digestibilidad`,
  "Versus / Diference",
];

export const PANELS_DATA = [
  {
    _id: "calidad-heces",
    name: "Control de Heces",
    icon: "https://cdn.shopify.com/s/files/1/1061/1924/files/Poop_Emoji.png?6135488989279264585",
  },
  {
    _id: "palatabilidad",
    name: "Palatabilidad",
    icon: "https://www.pngplay.com/wp-content/uploads/6/Adult-Dog-Food-PNG.png",
  },
  {
    _id: "digestibilidad",
    name: "Digestibilidad",
    icon: "https://cdn-prod.medicalnewstoday.com/content/images/articles/322/322868/golden-retriever-puppy.jpg",
  },
  {
    _id: "versus",
    name: "Versus",
    icon: "https://cdn-prod.medicalnewstoday.com/content/images/articles/322/322868/golden-retriever-puppy.jpg",
  },
];

export const PANELS_MAP = {
  stoolQuality: {
    _id: "calidad-heces",
    name: "Control de Heces",
  },
  palatability: {
    _id: "palatabilidad",
    name: "Palatabilidad",
    icon: "https://www.pngplay.com/wp-content/uploads/6/Adult-Dog-Food-PNG.png",
  },
  digestibility: {
    _id: "digestibilidad",
    name: "Digestibilidad",
    icon: "https://cdn-prod.medicalnewstoday.com/content/images/articles/322/322868/golden-retriever-puppy.jpg",
  },
  versus: {
    _id: "versus",
    name: "Versus",
    icon: "https://cdn-prod.medicalnewstoday.com/content/images/articles/322/322868/golden-retriever-puppy.jpg",
  },
};

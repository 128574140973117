import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { createComment, getCleanById, deleteAction } from "../actions/control";
import { updateClean } from "../actions/cage";
import { useSelector } from "react-redux";
import { GrLinkPrevious } from "react-icons/gr";
import ClipLoader from "react-spinners/ClipLoader";
import { TextField } from "@material-ui/core";
import { Colors } from "../common/Colors";

const NewComment = ({ match }) => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const [clean, setClean] = useState({});
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    text: "",
  });

  const { text } = values;

  useEffect(() => {
    loadAction();
  }, []);

  const loadAction = async () => {
    try {
      let res = await getCleanById(match.params.actionId);
      setClean(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let commentData = new FormData();
    commentData.append("text", text);
    commentData.append("madeFor", clean.postedBy._id);
    commentData.append("cageMadeFor", clean.madeFor._id);

    try {
      await createComment(auth.token, commentData);
      await deleteAction(auth.token, match.params.actionId);
      let cageData = new FormData();
      cageData.append("lastClean", "");
      await updateClean(auth.token, clean.madeFor._id, cageData);
      // console.log("CAGE CREATE RES", res);
      toast.success(`Comentario agregado`);
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 1000);
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data);
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h4 className="text-white text-center">Nuevo Comentario</h4>
      </div>

      <div>
        {loading ? (
          <ClipLoader loading={loading} size={200} className="m-3" />
        ) : (
          <>
            <div className="container-fluid col-md-8 col-md-offset-4 p-4">
              <h3 className="text-center mt-3">
                Limpieza de {clean.madeFor.name}
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <TextField
                    className="m-2 mt-3 mb-3"
                    onChange={handleChange}
                    fullWidth
                    name="text"
                    placeholder="Comentario"
                    value={text}
                    id="outlined-name"
                    multiline
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn-turq">Guardar</button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NewComment;

import { allCages } from "../actions/cage";
import { transferPet } from "../actions/pet";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import TransferCard from "../components/cards/TransferCard";
import { toast } from "react-toastify";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const TransferPets = ({ match }) => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));
  const [cages, setCages] = useState([]);

  useEffect(() => {
    loadCages();
  }, []);

  const loadCages = async () => {
    const res = await allCages();
    console.log(res);
    setCages(res.data);
  };

  const handlePetTransfer = async (cageId) => {
    try {
      let petData = new FormData();
      petData.append("locatedIn", cageId);

      transferPet(token, match.params.petId, petData);
      toast.success(`Perro transferido`);
      setTimeout(() => {
        window.location.href = "/pets";
      }, 1000);
    } catch (error) {}
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Transferir Perro</h2>
      </div>

      <div className="d-flex justify-content-center flex-wrap">
        {cages.map((cage) => (
          <div className="m-3">
            <TransferCard
              key={cage._id}
              cage={cage}
              handlePetTransfer={handlePetTransfer}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default TransferPets;

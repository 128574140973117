import React, { useState, useEffect } from "react";
import { GrLinkPrevious } from "react-icons/gr";
import { getPet } from "../actions/pet";
import {
  deleteStoolRegister,
  getStoolRegisters,
  getStoolRegistersByDate,
} from "../actions/stool";
import { Colors } from "../common/Colors";
import { SquareLoader } from "react-spinners";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import StoolCard from "../components/cards/StoolCard";
import Pagination from "../components/navigation/Pagination";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Calendar } from "react-calendar";
import "./Calendar.css";
import { BsFileSpreadsheet } from "react-icons/bs";
import { isAdmin } from "../common/Permissions";

const NUMBER_OF_REGISTERS = 9;

const StoolRegisters = ({ match, history }) => {
  const [pet, setPet] = useState({});
  const [stoolRegisters, setStoolRegisters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasRegisters, setHasRegisters] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const { petId } = match.params;

  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadPet();
  }, []);

  useEffect(() => {
    pet && loadStoolRegisters();
  }, [pet, currentPage]);

  useEffect(() => {
    selectedDate && loadStoolRegistersByDate();
  }, [selectedDate]);

  const loadPet = async () => {
    try {
      const res = await getPet(petId);

      setPet(res.data);
    } catch (error) {
      console.log(error);
      window.history.back();
    }
  };

  const loadStoolRegisters = async () => {
    try {
      setLoading(true);

      const res = await getStoolRegisters(
        petId,
        false,
        NUMBER_OF_REGISTERS,
        currentPage
      );

      if (res.data.length > 0) setHasRegisters(true);

      setStoolRegisters(res.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      window.history.back();
    }
  };

  const loadStoolRegistersByDate = async () => {
    try {
      setLoading(true);

      const res = await getStoolRegistersByDate(
        petId,
        false,
        NUMBER_OF_REGISTERS,
        currentPage,
        selectedDate
      );

      console.log(res.data);

      if (res.data.length > 0) setHasRegisters(true);

      setStoolRegisters(res.data);

      setLoading(false);
    } catch (error) {
      setStoolRegisters([]);
      setLoading(false);
    }
  };

  const handleStoolRegisterDelete = async (stoolId) => {
    try {
      await deleteStoolRegister(stoolId, auth.token);

      toast.success("Registro eliminado");

      loadStoolRegisters();
    } catch (error) {
      console.log(error);
      window.history.back();
    }
  };

  const handleDownloadFormat = async () => {
    const a = document.createElement("a");
    a.href = `${process.env.REACT_APP_API}/stool/format/${petId}`;
    a.download = true;
    a.click();
  };

  return (
    <div>
      <Dialog open={openCalendar} onClose={() => setOpenCalendar(false)}>
        <DialogTitle>Selecciona una fecha</DialogTitle>
        <DialogContent>
          <Calendar onChange={setSelectedDate} value={selectedDate} />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <button
            className="btn-turq ml-3"
            onClick={() => setOpenCalendar(false)}
          >
            Cerrar
          </button>
        </DialogActions>
      </Dialog>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        {pet && (
          <h4 className="text-white text-center ml-3">
            Registros de heces de {pet.name}
          </h4>
        )}
      </div>
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-wrap justify-content-center">
          <button
            className="btn-turq btn-add"
            onClick={() => history.push(`/new/stool/${petId}`)}
          >
            + Agregar registro
          </button>
          <button
            className="btn-turq btn-add ml-3"
            onClick={() => setOpenCalendar(true)}
          >
            Filtrar por fecha
          </button>
          {isAdmin(auth.user) && (
            <button
              className="btn-turq btn-add ml-3"
              onClick={handleDownloadFormat}
            >
              <BsFileSpreadsheet size={30} />
            </button>
          )}
        </div>
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
            <SquareLoader loading={loading} color={Colors.orange} size={100} />
            <h3 className="mt-3">Cargando...</h3>
          </div>
        ) : (
          <>
            {hasRegisters || stoolRegisters.length > 0 ? (
              <>
                <Grid container spacing={3} className="m-3 pl-2">
                  {stoolRegisters.map((register) => (
                    <Grid
                      item
                      key={register.stool_register._id}
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                    >
                      <StoolCard
                        register={register}
                        handleStoolDelete={handleStoolRegisterDelete}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Pagination
                  page={currentPage}
                  setPage={setCurrentPage}
                  showNext={stoolRegisters.length >= NUMBER_OF_REGISTERS}
                />
              </>
            ) : (
              <span className="font-italic text-center mt-5">
                Aun no se ha hecho ningún registro, ¡agrega alguno!
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default StoolRegisters;

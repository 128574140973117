import React, { useState, useEffect } from "react";
import { read } from "../actions/cage";
import { getCleanById, getCleanImgsById, approve } from "../actions/control";
import { useSelector } from "react-redux";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import {
  AiFillCheckCircle,
  AiOutlineCheck,
  AiOutlineClose,
  AiFillClockCircle,
} from "react-icons/ai";
import SimpleImageSlider from "react-simple-image-slider";
import { toast } from "react-toastify";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const ViewClean = ({ match, history }) => {
  const [clean, setClean] = useState({});
  const [cage, setCage] = useState({});
  const [loadingClean, setLoadingClean] = useState(true);
  const [prevCleanImgs, setPrevCleanImgs] = useState([]);

  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadClean();
    loadCage();
  }, []);

  const loadClean = async () => {
    console.log(match.params.actionId);
    let res = await getCleanById(match.params.actionId);
    setClean(res.data);
    res = await getCleanImgsById(match.params.actionId);

    const imgsArr = Array.from(res.data).map((f) => {
      const base64String = btoa(String.fromCharCode(...new Uint8Array(f.data)));
      const src = `data:image/png;base64,${base64String}`;
      return { url: src };
    });

    setPrevCleanImgs(imgsArr);
    setLoadingClean(false);
  };

  const loadCage = async () => {
    let res = await read(history.location.state.cageId);
    setCage(res.data);
  };

  const handleClean = async () => {
    let cleanData = new FormData();
    cleanData.append("approved", true);

    await approve(token, cleanData, match.params.actionId);
    toast.success(`¡Jaula limpia!`);
    window.location.reload();
  };

  const handleNotClean = async () => {
    history.push({
      pathname: `/addcomment/${match.params.actionId}`,
      state: cage._id,
    });
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">{clean.actionType}</h2>
      </div>

      <div className="container-fluid">
        <div className="row">
          <h2 className="text-center mt-3">Jaula: {cage.name}</h2>
          <div className="col">
            <br />
            <p className="font-weight-bold">
              Realizado {moment(clean.createdAt).fromNow()}
            </p>
            {clean.postedBy ? (
              <i>Limpiada por {clean.postedBy && clean.postedBy.name}</i>
            ) : null}
            {clean.comments ? (
              <p>
                <strong>Comentarios: </strong>
                {clean.comments}
              </p>
            ) : null}

            {loadingClean ? (
              <ClipLoader loading={loadingClean} size={200} className="m-3" />
            ) : (
              <div className="mt-3 container flex-wrap d-flex justify-content-center">
                {prevCleanImgs.length > 0 ? (
                  <SimpleImageSlider
                    width={330}
                    height={250}
                    images={prevCleanImgs}
                    navStyle={1}
                    showBullets
                    showNavs
                  />
                ) : null}
                <div className="mt-2 container">
                  {clean.approved ? (
                    <>
                      <p className="text-center">
                        <AiFillCheckCircle size={20} color={Colors.green} />{" "}
                        Jaula limpia
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="text-center">
                        <AiFillClockCircle size={20} color={Colors.blue} />{" "}
                        Esperando aprobación
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}

            {clean.approved ? null : (
              <>
                <p className="text-center mt-1">¿La jaula está limpia?</p>

                <div className="d-flex justify-content-center">
                  <AiOutlineCheck
                    size={70}
                    className="icon-btn"
                    onClick={handleClean}
                    color={Colors.white}
                    style={{
                      margin: 10,
                      padding: 10,
                      backgroundColor: Colors.green,
                      borderRadius: 100,
                      cursor: "pointer",
                    }}
                  />
                  <AiOutlineClose
                    size={70}
                    className="icon-btn"
                    onClick={handleNotClean}
                    color={Colors.white}
                    style={{
                      margin: 10,
                      padding: 10,
                      backgroundColor: Colors.redPink,
                      borderRadius: 100,
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewClean;

import { useHistory } from "react-router-dom";
import moment from "moment";
import { Avatar } from "@material-ui/core";
import { BiTransferAlt } from "react-icons/bi";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { useSelector } from "react-redux";
import { BsHouse } from "react-icons/bs";
import { GiWeight } from "react-icons/gi";
import { Colors } from "../../common/Colors";
import { isAdmin } from "../../common/Permissions";

const PetCard = ({
  pet,
  handlePetDelete = (f) => f,
  handlePetTransfer = (f) => f,
  showViewMoreButton = true,
}) => {
  const history = useHistory();

  const { auth } = useSelector((state) => ({ ...state }));

  return (
    <>
      <div className="card m-1">
        <div className="row no-gutters">
          <div
            className="col m-2 d-flex justify-content-center align-items-center flex-column"
            style={{ position: "relative" }}
          >
            {pet.panel ? (
              <p className="text-center tag-blue m-2">{pet.panel}</p>
            ) : null}
            {pet.image && pet.image.contentType ? (
              <Avatar
                style={{
                  width: 140,
                  height: 140,
                }}
                shape="circle"
                src={`${process.env.REACT_APP_API}/pet/image/${pet._id}`}
              />
            ) : (
              <Avatar
                shape="circle"
                style={{
                  width: 140,
                  height: 140,
                }}
              >
                {pet.name}
              </Avatar>
            )}

            <button
              className="mt-4 btn-turq"
              onClick={() => history.push(`/pet/${pet._id}`)}
            >
              Control
            </button>
          </div>

          <div className="col">
            <div className="card-body">
              <div className="d-flex justify-content-between flex-wrap">
                <h4 className="card-title">{pet.name}</h4>
                {pet.sex === "Hembra" && (
                  <IoMdFemale
                    size={25}
                    color={Colors.white}
                    style={{
                      backgroundColor: Colors.pink,
                      borderRadius: 50,
                      padding: 2,
                    }}
                  />
                )}

                {pet.sex === "Macho" && (
                  <IoMdMale
                    size={25}
                    color={Colors.white}
                    style={{
                      backgroundColor: Colors.blue,
                      borderRadius: 50,
                      padding: 2,
                    }}
                  />
                )}
              </div>

              <p className="font-italic mt-1">
                {pet.comments.length > 50
                  ? `${pet.comments.substring(0, 50)}...`
                  : pet.comments}
              </p>

              <p>
                Edad:{" "}
                {moment(pet.born)
                  .fromNow(true)
                  .replace(/\b[a-z]/, (match) => match.toUpperCase())}
              </p>

              <div className="d-flex justify-content-around align-content-center">
                {pet.locatedIn && pet.locatedIn.name ? (
                  <div className="d-flex">
                    <BsHouse size={20} className="mr-2" />{" "}
                    <p>{pet.locatedIn.name}</p>
                  </div>
                ) : (
                  <div className="d-flex">
                    <BsHouse size={20} className="mr-2" color="red" /> <p>!</p>
                  </div>
                )}
                {pet.weight ? (
                  <div className="d-flex">
                    <GiWeight size={20} /> <p>{pet.weight} kg</p>
                  </div>
                ) : (
                  <div className="d-flex">
                    <GiWeight size={20} />{" "}
                    <p style={{ color: Colors.orange }}> !</p>
                  </div>
                )}
                <h6>Estado: {pet.state}</h6>
              </div>

              <p className="font-weight-bold">
                Está en el Bioterio desde{" "}
                {moment(pet.arrive)
                  .fromNow(false)
                  .replace(/\b[a-z]/, (match) => match.toUpperCase())}
              </p>

              <div className="d-flex justify-content-around h4">
                {auth && isAdmin(auth.user) && (
                  <AiFillEdit
                    style={{ color: Colors.yellow }}
                    onClick={() => history.push(`/pet/edit/${pet._id}`)}
                  />
                )}
                {auth && isAdmin(auth.user) && (
                  <AiFillDelete
                    onClick={() => handlePetDelete(pet._id)}
                    style={{ color: Colors.orangeDark }}
                  />
                )}

                <BiTransferAlt
                  onClick={() => handlePetTransfer(pet._id)}
                  className="text-dark"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PetCard;

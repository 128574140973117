import React, { useState, useEffect } from "react";
import { getPet } from "../actions/pet";
import { getDaily } from "../actions/control";
import { useSelector } from "react-redux";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { Avatar } from "@material-ui/core";
import { GrLinkPrevious } from "react-icons/gr";
import { BsFileSpreadsheet } from "react-icons/bs";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";

const ViewDaily = ({ match, history }) => {
  const [daily, setDaily] = useState({});
  const [pet, setPet] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");

  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadDaily();
    loadPet();
    if (!pet) {
      history.back();
    }
  }, []);

  useEffect(() => {
    loadImage();
  }, [pet]);

  const loadDaily = async () => {
    let res = await getDaily(match.params.actionId);
    setDaily(res.data);
  };

  const loadPet = async () => {
    let res = await getPet(history.location.state.petId);
    setPet(res.data);
  };

  const loadImage = () => {
    if (pet.image && pet.image.contentType) {
      setImage(`${process.env.REACT_APP_API}/pet/image/${pet._id}`);
      return;
    }
    setImage(null);
    setLoading(false);
  };

  return (
    <>
      <div className="mb-3">
        <div
          className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: Colors.orange, position: "relative" }}
        >
          <GrLinkPrevious
            size={40}
            onClick={() => window.history.back()}
            color={Colors.white}
            style={{
              cursor: "pointer",
              position: "absolute",
              left: 20,
              borderRadius: 50,
              padding: 5,
              backgroundColor: Colors.white,
            }}
          />
          <h2 className="text-white text-center">{daily.actionType}</h2>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="d-flex justify-content-center align-items-center mt-4">
              <br />
              {loading ? (
                <ClipLoader loading={loading} size={200} className="m-3" />
              ) : image ? (
                <Avatar
                  src={image}
                  style={{
                    width: 250,
                    height: 250,
                  }}
                />
              ) : (
                <Avatar style={{ width: 250, height: 250, fontSize: 20 }}>
                  {pet.name}
                </Avatar>
              )}
            </div>

            <h2 className="text-center mt-3">Perro: {pet.name}</h2>

            <div className="d-flex justify-content-center">
              {isAdmin(auth.user) ? (
                <a
                  href={`${process.env.REACT_APP_API}/daily-format/${pet._id}`}
                  download
                  className="btn-turq"
                  style={{ color: "white" }}
                >
                  <BsFileSpreadsheet size={30} />
                </a>
              ) : null}
            </div>

            <div className="col">
              <br />
              <p className="font-weight-bold">
                Realizado {moment(daily.createdAt).fromNow()}
              </p>
            </div>
          </div>

          <div className="col mb-10">
            <p
              style={{
                color:
                  daily.generalAppearance === "Normal"
                    ? "black"
                    : Colors.yellow,
              }}
            >
              <strong>Apariencia General: </strong>
              {daily.generalAppearance}
            </p>
            <p
              style={{
                color:
                  daily.bodyCondition === "Normal" ? "black" : Colors.yellow,
              }}
            >
              <strong>Condición Corporal: </strong>
              {daily.bodyCondition}
            </p>
            <p
              style={{
                color: daily.hairAndSkin === "Normal" ? "black" : Colors.yellow,
              }}
            >
              <strong>Piel y pelo: </strong>
              {daily.hairAndSkin}
            </p>
            <p
              style={{
                color: daily.eyes === "Normal" ? "black" : Colors.yellow,
              }}
            >
              <strong>Ojos: </strong>
              {daily.eyes}
            </p>
            <p
              style={{
                color: daily.ears === "Normal" ? "black" : Colors.yellow,
              }}
            >
              <strong>Oídos: </strong>
              {daily.ears}
            </p>
            <p
              style={{
                color: daily.nose === "Normal" ? "black" : Colors.yellow,
              }}
            >
              <strong>Naríz: </strong>
              {daily.nose}
            </p>
            <p
              style={{
                color: daily.mouth === "Normal" ? "black" : Colors.yellow,
              }}
            >
              <strong>Boca: </strong>
              {daily.mouth}
            </p>
            <p className="text-center">
              <strong>Comentarios </strong>
              {daily.comments !== undefined ? daily.comments : "No"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDaily;

import {
  TextField,
  Fab,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { Colors } from "../../common/Colors";

const CageCreateForm = ({ values, handleChange, handleSubmit }) => {
  const { name, comments, capacity, isDiningRoom } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <TextField
          onChange={handleChange}
          fullWidth
          name="name"
          placeholder="Nombre"
          value={name}
          id="outlined-name"
          className="mt-3"
        />

        <TextField
          onChange={handleChange}
          fullWidth
          name="comments"
          placeholder="Comentarios"
          value={comments}
          id="outlined-name"
          className="mt-3"
        />

        <TextField
          onChange={handleChange}
          type="number"
          fullWidth
          name="capacity"
          placeholder="Capacidad"
          value={capacity}
          id="outlined-name"
          className="mt-3"
        />

        <div className="mt-3 form-check">
          <input
            className="form-check-input"
            onChange={handleChange}
            type="checkbox"
            value={isDiningRoom}
            name="isDiningRoom"
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            ¿Es comedor?
          </label>
        </div>
      </div>

      <button className="btn-turq">Guardar</button>
    </form>
  );
};

export default CageCreateForm;

import { useState, useEffect } from "react";
import {
  AiFillFolder,
  AiFillTrophy,
  AiOutlineFileProtect,
  AiOutlineQrcode,
} from "react-icons/ai";
import { GiSittingDog, GiNightSleep } from "react-icons/gi";
import { FaUserAlt } from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import "../components/TopNav.css";
import "../components/TopNav.css";
import { useSelector } from "react-redux";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";
import { BiDumbbell } from "react-icons/bi";

const Home = ({ history }) => {
  const [width, setWidth] = useState();

  const { auth } = useSelector((state) => ({ ...state }));

  const setIconSizes = () => {
    if (window.innerWidth < 1024) {
      setWidth(window.innerWidth / 2 - 30);
    } else {
      setWidth(window.innerWidth / 5 - 100);
    }
  };

  useEffect(() => {
    if (!auth) {
      history.push("/login");
    }
    setIconSizes();
  }, [width]);

  return (
    <div className="container col-lg-7  col-sm-12 d-flex justify-content-center flex-wrap mt-5">
      <AiOutlineQrcode
        size={width}
        className="icon-btn"
        onClick={() => history.push("/readqr")}
        color={Colors.white}
        style={{
          margin: 10,
          padding: 20,
          backgroundColor: Colors.black,
          borderRadius: width,
        }}
      />
      <GiSittingDog
        size={width}
        className="icon-btn"
        onClick={() => history.push("/pets")}
        color={Colors.white}
        style={{
          margin: 10,
          padding: 20,
          backgroundColor: Colors.black,
          borderRadius: width,
        }}
      />
      <GiNightSleep
        size={width}
        className="icon-btn"
        onClick={() => history.push("/cages")}
        color={Colors.white}
        style={{
          margin: 10,
          padding: 20,
          backgroundColor: Colors.black,
          borderRadius: width,
        }}
      />
      <AiOutlineSchedule
        size={width}
        className="icon-btn"
        onClick={() => history.push("/actions")}
        color={Colors.white}
        style={{
          margin: 10,
          padding: 20,
          backgroundColor: Colors.black,
          borderRadius: width,
        }}
      />
      <AiFillFolder
        size={width}
        className="icon-btn"
        onClick={() => history.push("/drive")}
        color={Colors.white}
        style={{
          margin: 10,
          padding: 20,
          backgroundColor: Colors.black,
          borderRadius: width,
        }}
      />
      <BiDumbbell
        size={width}
        className="icon-btn"
        onClick={() => history.push("/training")}
        color={Colors.white}
        style={{
          margin: 10,
          padding: 20,
          backgroundColor: Colors.black,
          borderRadius: width,
        }}
      />
      {auth && isAdmin(auth.user) ? (
        <>
          <AiOutlineFileProtect
            size={width}
            className="icon-btn"
            onClick={() => history.push("/testing")}
            color={Colors.white}
            style={{
              margin: 10,
              padding: 20,
              backgroundColor: Colors.black,
              borderRadius: width,
            }}
          />
          <FaUserAlt
            size={width}
            className="icon-btn"
            onClick={() => history.push("/users")}
            color={Colors.white}
            style={{
              margin: 10,
              padding: 20,
              backgroundColor: Colors.black,
              borderRadius: width,
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default Home;

import { useState } from "react";
import { toast } from "react-toastify";
import { createDeworming } from "../actions/control";
import { updatePet } from "../actions/pet";
import { useSelector } from "react-redux";
import DewormingCreateForm from "../components/forms/DewormingCreateForm";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const NewDeworming = ({ match }) => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  // state
  const [values, setValues] = useState({
    deworming: "",
    nextDeworming: "",
    createdAt: new Date(),
  });
  const { deworming, nextDeworming, createdAt } = values;
  const [manualSelect, setManualSelect] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let dewormingData = new FormData();
    let petData = new FormData();
    dewormingData.append("deworming", deworming);
    if (manualSelect) {
      dewormingData.append("nextDeworming", nextDeworming);
      petData.append("nextDeworming", nextDeworming);
    } else {
      let manualDeworming = new Date(createdAt);
      manualDeworming.setMonth(manualDeworming.getMonth() + 6);
      dewormingData.append("nextDeworming", manualDeworming);

      petData.append("nextDeworming", manualDeworming);
    }
    dewormingData.append("madeFor", match.params.petId);
    createdAt && dewormingData.append("createdAt", createdAt);

    try {
      await createDeworming(token, dewormingData);
      await updatePet(token, petData, match.params.petId);
      // console.log("CAGE CREATE RES", res);
      toast.success(`¡Desparasitación creada exitosamente!`);
      setTimeout(() => {
        window.location.href = "/pet/" + match.params.petId;
      }, 1000);
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChecked = (event) => {
    setManualSelect(event.target.checked);
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Agregar Desparasitación</h2>
      </div>

      <div className="container-fluid col-md-8 col-md-offset-4 p-4">
        <div className="row">
          <br />
          <DewormingCreateForm
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            manualSelect={manualSelect}
            handleChecked={handleChecked}
          />
        </div>
      </div>
    </>
  );
};

export default NewDeworming;

import DashboardNav from "../components/DashboardNav";
import ConnectNav from "../components/ConnectNav";
import TodoList from "../components/TodoList";
import { Colors } from "../common/Colors";

const DashboardComments = () => {
  return (
    <>
      <div
        className="container-fluid p-5"
        style={{ backgroundColor: Colors.orange }}
      >
        <ConnectNav />
      </div>

      <div className="container-fluid p-4">
        <DashboardNav />
      </div>

      <div className="container-fluid">
        <div className="d-flex justify-content-center flex-wrap">
          <div className="col-md-5">
            <TodoList />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardComments;

import { useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Avatar, Modal } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../common/Colors";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SquareLoader from "react-spinners/SquareLoader";
import { getPets } from "../../actions/pet";
import { createTraining } from "../../actions/trainings";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "20px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
});

const NewTrainingModal = ({ showModal, setShowModal, loadTrainings }) => {
  const [date, setDate] = useState(new Date(Date.now()));
  const [comments, setComments] = useState("");
  const [pets, setPets] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleNewTraining = async () => {
    try {
      const checkboxes = Array.from(
        document.querySelectorAll("input[type=checkbox]:checked")
      );
      const selectedPets = checkboxes
        .filter((checkbox) => checkbox.name !== "general-checkbox")
        .map((checkbox) => checkbox.value);

      await createTraining(token, {
        date,
        comments,
        pets: selectedPets,
      });
      setShowModal(false);
      loadTrainings();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectAll = () => {
    var checkboxes = document.querySelectorAll("input[type=checkbox]");
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = !selectAll;
    }
    setSelectAll(!selectAll);
  };

  const clearCheckboxes = () => {
    var checkboxes = document.querySelectorAll("input[type=checkbox]");
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  };

  const loadPets = async () => {
    try {
      const res = await getPets("");
      setPets(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const clearAllStuff = () => {
    clearCheckboxes();
    setDate(new Date(Date.now()));
    setComments("");
  };

  useEffect(() => {
    loadPets();
    clearAllStuff();
  }, []);

  return (
    <Modal
      open={showModal}
      centered
      width={1000}
      closeIcon={
        <AiOutlineCloseCircle
          className="pointer"
          color={Colors.orange}
          size={30}
        />
      }
      onCancel={() => setShowModal(!showModal)}
    >
      <div
        className="container-fluid p-3 my-4 d-flex justify-content-center align-items-center border rounded"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <h2 className="text-white text-center">
          Registrar nuevo entrenamiento
        </h2>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="container-fluid">
          {isLoading ? (
            <>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <SquareLoader
                  color={Colors.orange}
                  loading={isLoading}
                  size={100}
                />
                <h1 className="m-3">Cargando...</h1>
              </div>
            </>
          ) : (
            <>
              {pets.length > 0 ? (
                <>
                  <div className="row align-items-center text-center justify-content-center my-5">
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                      <MobileDatePicker
                        label="Fecha"
                        inputFormat="DD/MM/YYYY"
                        value={date}
                        cancelLabel="Cancelar"
                        onChange={(newDate) => setDate(newDate["$d"])}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>

                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                      <label htmlFor="exampleFormControlTextarea1">
                        <h4>Comentarios</h4>
                      </label>
                      <textarea
                        className="form-control w-100 align-items-center justify-content-center"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <div className="row align-items-center text-center justify-content-center">
                    <h4>Perros</h4>
                  </div>
                  <div className="row align-items-center text-center justify-content-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={selectAll}
                        onChange={handleSelectAll}
                        name="general-checkbox"
                        id="general-checkbox"
                      />
                      <label
                        className="form-check-label h6"
                        htmlFor="general-checkbox"
                      >
                        Seleccionar todos
                      </label>
                    </div>
                  </div>

                  <Grid
                    container
                    spacing={1}
                    className={classes.gridContainer}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {pets.map((pet, index) => {
                      return (
                        <Grid
                          key={`pet-checkbox-${index}`}
                          item
                          container
                          xs={4}
                          sm={3}
                          md={2}
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={pet._id}
                              name={`checkbox-${index}`}
                              id={`checkbox-${index}`}
                            />
                            <label
                              className="form-check-label h6"
                              htmlFor={`checkbox-${index}`}
                            >
                              {pet.name}
                            </label>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <div className="row align-items-center text-center justify-content-center mt-4">
                    <button
                      className="mb-3 btn-turq"
                      onClick={handleNewTraining}
                    >
                      Registrar
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <h4 className="text-center mt-5 w-50">
                      No hay ningun perro registrado
                    </h4>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </LocalizationProvider>
    </Modal>
  );
};

export default NewTrainingModal;

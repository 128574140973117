import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import HeatMap from "@uiw/react-heat-map";
import {
  getActionsByDate,
  getActionsByMonth,
  deleteAction,
} from "../actions/control";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ActionCard from "../components/cards/ActionCard";
import { toast } from "react-toastify";
import { Colors } from "../common/Colors";

const days = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
const rectSize = 50;
const space = 5;

const panelColors = {
  0: Colors.lightGray,
  8: Colors.blue,
  4: Colors.blue,
  12: Colors.blue,
  32: Colors.blue,
};

const getMonth = (date) => {
  switch (date.getMonth()) {
    case 0:
      return "Enero";
    case 1:
      return "Febrero";
    case 2:
      return "Marzo";
    case 3:
      return "Abril";
    case 4:
      return "Mayo";
    case 5:
      return "Junio";
    case 6:
      return "Julio";
    case 7:
      return "Agosto";
    case 8:
      return "Septiembre";
    case 9:
      return "Octubre";
    case 10:
      return "Noviembre";
    case 11:
      return "Diciembre";
    default:
      break;
  }
};

const ActionsCalendar = ({ history }) => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const [now, setNow] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [dateValues, setDateValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateValues, setSelectedDateValues] = useState([]);

  useEffect(() => {
    setLoading(true);
    loadActions();
    loadActionsByDate();
  }, [now, selectedDate]);

  const loadActions = async () => {
    try {
      const res = await getActionsByMonth(
        `${now.getFullYear()}/${now.getMonth() + 1}/01`,
        getEndDate()
      );
      const values = Array.from(res.data).map((f) => {
        const date = new Date(f.createdAt);
        return {
          date: `${date.getFullYear()}/${
            date.getMonth() + 1
          }/${date.getDate()}`,
          count: 10,
        };
      });
      setDateValues(values);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getDay = (date) => {
    const aux = new Date(date);
    return aux.getDate();
  };

  const handleNext = () => {
    let aux;
    if (now.getMonth() === 11) {
      aux = new Date(`${now.getFullYear() + 1}/01/01`);
    } else {
      aux = new Date(`${now.getFullYear()}/${now.getMonth() + 2}/01`);
    }
    console.log(aux);
    setNow(aux);
  };

  const handlePrev = () => {
    let aux;
    if (now.getMonth() === 0) {
      aux = new Date(`${now.getFullYear() - 1}/12/01`);
    } else {
      aux = new Date(`${now.getFullYear()}/${now.getMonth()}/01`);
    }
    console.log(aux);
    setNow(aux);
  };

  const getEndDate = () => {
    if (now.getMonth() === 11) {
      console.log("end", `${now.getFullYear()}/${now.getMonth() + 1}/31`);
      return `${now.getFullYear()}/${now.getMonth() + 1}/31`;
    } else {
      return `${now.getFullYear()}/${now.getMonth() + 2}/01`;
    }
  };

  const handleClick = async (date) => {
    setSelectedDate(new Date(date));
    setOpen(true);
  };

  const loadActionsByDate = async () => {
    try {
      const res = await getActionsByDate(
        `${selectedDate.getFullYear()}/${
          selectedDate.getMonth() + 1
        }/${selectedDate.getDate()}`
      );
      setSelectedDateValues(res.data.reverse());
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
    setLoading(false);
  };

  const handleActionDelete = async (actionId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Acción?")) return;
    deleteAction(token, actionId).then((res) => {
      toast.success("Acción eliminada");
      setOpen(false);
      loadActions();
    });
  };

  const handleShowMore = (actionId, actionType, petId) => {
    if (actionType === "Entrenamiento") {
      history.push({
        pathname: `/training/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Desparasitación") {
      history.push({
        pathname: `/deworming/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Consulta") {
      history.push({
        pathname: `/consultation/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Vacuna") {
      history.push({
        pathname: `/vaccine/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Prueba") {
      history.push({
        pathname: `/test/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Limpieza") {
      history.push({
        pathname: `/cage/${petId}`,
      });
    }
    if (actionType === "Chequeo Diario") {
      history.push({
        pathname: `/daily/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Baño") {
      history.push({
        pathname: `/shower/${actionId}`,
        state: {
          petId,
        },
      });
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Calendario de acciones</h2>
      </div>

      <h1 className="text-center">{now.getFullYear()}</h1>
      <h3 className="text-center">{getMonth(now)}</h3>

      <div className="d-flex justify-content-center">
        {loading ? (
          <ClipLoader loading={loading} size={200} className="m-3" />
        ) : (
          <HeatMap
            panelColors={panelColors}
            weekLables={days}
            monthLables={[]}
            value={dateValues}
            startDate={
              new Date(`${now.getFullYear()}/${now.getMonth() + 1}/01`)
            }
            space={space}
            height={rectSize * 8}
            width={(rectSize + space * 2) * 6}
            legendCellSize={0}
            endDate={new Date(getEndDate())}
            rectSize={rectSize}
            rectProps={{
              rx: rectSize,
            }}
            rectRender={(props, data, index) => {
              let i = 0;
              return (
                <g key={i} onClick={() => handleClick(data.date)}>
                  <rect {...props} key={i + 1}></rect>
                  <text
                    {...props}
                    x={props.x + rectSize / 2}
                    y={props.y + rectSize / 2 + 10}
                    style={{
                      fontSize: 25,
                      color: Colors.white,
                      cursor: "pointer",
                    }}
                    key={i + 2}
                  >
                    {getDay(data.date)}
                  </text>
                </g>
              );
            }}
          ></HeatMap>
        )}

        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
          <DialogTitle className="text-center">
            {selectedDate.getDate()} de {getMonth(selectedDate)} del{" "}
            {selectedDate.getFullYear()}
          </DialogTitle>
          <DialogContent>
            <div>
              {loading ? (
                <ClipLoader loading={loading} size={200} className="m-3" />
              ) : selectedDateValues.length > 0 ? (
                selectedDateValues.map((action) => (
                  <ActionCard
                    key={action._id}
                    action={action}
                    handleActionDelete={handleActionDelete}
                    handleShowMore={handleShowMore}
                  />
                ))
              ) : (
                <p>No hay acciones por mostrar</p>
              )}
            </div>
          </DialogContent>
          <DialogActions className="justify-content-around">
            <button className="btn-turq" onClick={() => setOpen(false)}>
              Cerrar
            </button>
          </DialogActions>
        </Dialog>
      </div>

      <div className="d-flex justify-content-between ml-5 mr-5 mt-3 mb-2">
        <GrLinkPrevious
          size={40}
          onClick={handlePrev}
          style={{ cursor: "pointer" }}
        />
        <GrLinkNext
          size={40}
          onClick={handleNext}
          style={{ cursor: "pointer" }}
        />
      </div>
    </>
  );
};

export default ActionsCalendar;

import React, { useState, useEffect } from "react";
import { read } from "../actions/cage";
import { getComment } from "../actions/control";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { Avatar } from "@material-ui/core";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const ViewTraining = ({ match, history }) => {
  const [comment, setComment] = useState({});
  const [cage, setCage] = useState({});

  useEffect(() => {
    loadComment();
    loadCage();
    if (!cage) {
      history.back();
    }
  }, []);

  const loadComment = async () => {
    let res = await getComment(match.params.actionId);
    setComment(res.data);
  };

  const loadCage = async () => {
    let res = await read(history.location.state);
    setCage(res.data);
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">{comment.actionType}</h2>
      </div>

      <div className="container-fluid">
        <div className="row">
          <h2 className="text-center mt-3">Limpieza en {cage.name}</h2>

          <div className="col">
            {comment.postedBy ? (
              <p className="text-center font-italic">
                Hecho por {comment.postedBy && comment.postedBy.name}
              </p>
            ) : null}
            <p className="font-weight-bold mt-3">
              Realizado {moment(comment.createdAt).fromNow()}
            </p>
            <p>{comment.text}</p>
            {comment.comments ? (
              <p>
                <strong>Comentarios: </strong>
                {comment.comments}
              </p>
            ) : null}
          </div>

          <div className="d-flex justify-content-center">
            <button
              onClick={() => history.push(`/cage/${cage._id}`)}
              className="btn-turq"
            >
              Ir a {cage.name}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTraining;

import { Link } from "react-router-dom";
import { Colors } from "../common/Colors";

const NotFound = () => {
  const style = {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 30,
  };

  return (
    <>
      <div
        className="container-fluid p-5 text-center"
        style={{ backgroundColor: Colors.orange }}
      >
        <h1 className="text-white">:(</h1>
      </div>
      <p style={style}>No se puede encontrar esa página</p>
      <div className="d-flex justify-content-center">
        <Link to="/">
          <button className="btn-turq mt-3">Volver a inicio</button>
        </Link>
      </div>
    </>
  );
};

export default NotFound;

import { useState, useEffect } from "react";
import {
  GiMagnifyingGlass,
  GiHairStrands,
  GiFrontTeeth,
  GiStomach,
  GiLungs,
} from "react-icons/gi";
import { RiThermometerLine } from "react-icons/ri";
import { ImEye } from "react-icons/im";
import { BiHeart } from "react-icons/bi";
import { FaDog } from "react-icons/fa";
import { CgGenderMale } from "react-icons/cg";
import { TextField } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  AiFillInfoCircle,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import { FaBone } from "react-icons/fa";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../../common/Colors";
import DogEarsImg from "../../assets/img/dog-ears-icon.svg";
import DogNoseImg from "../../assets/img/dog-nose-icon.png";

const PatientExaminationForm = ({
  values,
  handleChange,
  prevStep,
  nextStep,
  handleClick,
  toggles,
  open,
  setOpen,
  handleNext,
  translation,
  handleInfo,
  toggleInfo,
  historyBools,
  handleHistory,
}) => {
  const [width, setWidth] = useState(undefined);
  const margin = 8;

  const {
    heartRate,
    temperature,
    generalAppearance,
    bodyCondition,
    hairAndSkin,
    eyes,
    ears,
    noseThroatTonsils,
    mouthTeethGums,
    musculoskeletal,
    heartCirculatory,
    respiratory,
    gastrointestinal,
    urinaryReproductive,
    laboratoryExams,
  } = values;
  const {
    generalAppearanceT,
    bodyConditionT,
    hairAndSkinT,
    eyesT,
    earsT,
    noseThroatTonsilsT,
    mouthTeethGumsT,
    musculoskeletalT,
    heartCirculatoryT,
    respiratoryT,
    gastrointestinalT,
    urinaryReproductiveT,
  } = toggles;
  const { laboratoryExamsB } = historyBools;

  const setIconSizes = () => {
    if (window.innerWidth < 1024) {
      setWidth(window.innerWidth / 2.7 - 50);
    } else {
      setWidth(window.innerWidth / 7 - 70);
    }
  };

  useEffect(() => {
    setIconSizes();
  }, [width]);

  return (
    <>
      <div className="form-group">
        <div className="d-flex align-items-center justify-content-center">
          <BiHeart
            size={110}
            className="icon-btn"
            color="white"
            style={{
              margin: margin,
              padding: 10,
              backgroundColor: Colors.redPink,
              borderRadius: 110,
            }}
          />
          <TextField
            type="number"
            onChange={handleChange}
            name="heartRate"
            placeholder="Frecuencia cardiaca"
            value={heartRate}
            id="outlined-name"
          />
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <RiThermometerLine
            size={110}
            className="icon-btn"
            color="white"
            style={{
              margin: margin,
              padding: 10,
              backgroundColor: Colors.orange,
              borderRadius: 110,
            }}
          />
          <TextField
            type="number"
            onChange={handleChange}
            name="temperature"
            placeholder="Temperatura"
            value={temperature}
            id="outlined-name"
          />
        </div>

        <div className="row">
          <p className="text-center font-face-lm mt-4">
            <AiFillInfoCircle
              size={25}
              color={Colors.blue}
              onClick={handleInfo}
            />{" "}
            Condición del perro
          </p>

          {toggleInfo ? (
            <p style={{ fontSize: 13, fontStyle: "italic" }}>
              Toca una vez el aspecto si se encuentra en estado NORMAL o dejalo
              asi para especificar algo distinto.
            </p>
          ) : null}

          <div className="text-center">
            <GiMagnifyingGlass
              size={width}
              className="icon-btn"
              onClick={() => handleClick("generalAppearance")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: generalAppearanceT
                  ? Colors.green
                  : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <FaDog
              size={width}
              className="icon-btn"
              onClick={() => handleClick("bodyCondition")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: bodyConditionT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <GiHairStrands
              size={width}
              className="icon-btn"
              onClick={() => handleClick("hairAndSkin")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: hairAndSkinT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <ImEye
              size={width}
              className="icon-btn"
              onClick={() => handleClick("eyes")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: eyesT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <img
              src={DogEarsImg}
              className="icon-btn"
              onClick={() => handleClick("ears")}
              width={width}
              height={width}
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: earsT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <img
              src={DogNoseImg}
              className="icon-btn"
              onClick={() => handleClick("noseThroatTonsils")}
              width={width}
              height={width}
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: noseThroatTonsilsT
                  ? Colors.green
                  : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <GiFrontTeeth
              size={width}
              className="icon-btn"
              onClick={() => handleClick("mouthTeethGums")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: mouthTeethGumsT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <FaBone
              size={width}
              className="icon-btn"
              onClick={() => handleClick("musculoskeletal")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: musculoskeletalT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <BiHeart
              size={width}
              className="icon-btn"
              onClick={() => handleClick("heartCirculatory")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: heartCirculatoryT
                  ? Colors.green
                  : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <GiLungs
              size={width}
              className="icon-btn"
              onClick={() => handleClick("respiratory")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: respiratoryT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <GiStomach
              size={width}
              className="icon-btn"
              onClick={() => handleClick("gastrointestinal")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: gastrointestinalT
                  ? Colors.green
                  : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <CgGenderMale
              size={width}
              className="icon-btn"
              onClick={() => handleClick("urinaryReproductive")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: urinaryReproductiveT
                  ? Colors.green
                  : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
          </div>

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle className="text-center">
              ¿Cuál es el estado de estos aspectos?
            </DialogTitle>
            <DialogContent>
              {generalAppearanceT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="generalAppearance"
                  placeholder={translation["generalAppearance"]}
                  value={generalAppearance}
                  id="outlined-name"
                />
              )}
              {bodyConditionT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="bodyCondition"
                  placeholder={translation["bodyCondition"]}
                  value={bodyCondition}
                  id="outlined-name"
                />
              )}
              {hairAndSkinT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="hairAndSkin"
                  placeholder={translation["hairAndSkin"]}
                  value={hairAndSkin}
                  id="outlined-name"
                />
              )}
              {eyesT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="eyes"
                  placeholder={translation["eyes"]}
                  value={eyes}
                  id="outlined-name"
                />
              )}
              {earsT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="ears"
                  placeholder={translation["ears"]}
                  value={ears}
                  id="outlined-name"
                />
              )}
              {noseThroatTonsilsT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="noseThroatTonsils"
                  placeholder={translation["noseThroatTonsils"]}
                  value={noseThroatTonsils}
                  id="outlined-name"
                />
              )}
              {mouthTeethGumsT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="mouthTeethGums"
                  placeholder={translation["mouthTeethGums"]}
                  value={mouthTeethGums}
                  id="outlined-name"
                />
              )}
              {musculoskeletalT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="musculoskeletal"
                  placeholder={translation["musculoskeletal"]}
                  value={musculoskeletal}
                  id="outlined-name"
                />
              )}
              {heartCirculatoryT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="heartCirculatory"
                  placeholder={translation["heartCirculatory"]}
                  value={heartCirculatory}
                  id="outlined-name"
                />
              )}
              {respiratoryT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="respiratory"
                  placeholder={translation["respiratory"]}
                  value={respiratory}
                  id="outlined-name"
                />
              )}
              {gastrointestinalT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="gastrointestinal"
                  placeholder={translation["gastrointestinal"]}
                  value={gastrointestinal}
                  id="outlined-name"
                />
              )}
              {urinaryReproductiveT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="urinaryReproductive"
                  placeholder={translation["urinaryReproductive"]}
                  value={urinaryReproductive}
                  id="outlined-name"
                />
              )}
            </DialogContent>
            <DialogActions className="justify-content-around">
              <button className="btn-turq" onClick={nextStep}>
                Continuar
              </button>
            </DialogActions>
          </Dialog>
        </div>

        <p className="text-center mt-1">
          ¿Hay que realizar algún examen de laboratorio?
        </p>

        <div className="d-flex justify-content-center">
          <AiOutlineCheck
            size={70}
            className="icon-btn"
            onClick={() => handleHistory(true, "laboratoryExams")}
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: laboratoryExamsB ? Colors.green : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
          <AiOutlineClose
            size={70}
            className="icon-btn"
            onClick={() => handleHistory(false, "laboratoryExams")}
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: !laboratoryExamsB ? Colors.pink : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
        </div>

        {laboratoryExamsB ? (
          <TextField
            onChange={handleChange}
            fullWidth
            name="laboratoryExams"
            placeholder="¿Cuál?"
            value={laboratoryExams}
            id="outlined-name"
            multiline
          />
        ) : null}
      </div>

      <div className="d-flex justify-content-between">
        <GrLinkPrevious
          size={40}
          onClick={prevStep}
          style={{ cursor: "pointer" }}
        />
        <GrLinkNext
          size={40}
          onClick={handleNext}
          style={{ cursor: "pointer" }}
        />
      </div>
    </>
  );
};

export default PatientExaminationForm;

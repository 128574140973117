import React, { useState, useEffect } from "react";
import { getPet } from "../actions/pet";
import { getConsultation } from "../actions/control";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { Avatar } from "@material-ui/core";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const ViewConsultation = ({ match, history }) => {
  const [consultation, setConsultation] = useState({});
  const [pet, setPet] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");

  useEffect(() => {
    console.log(history.location.state);
    loadConsultation();
    loadPet();
    if (!pet) {
      history.back();
    }
  }, []);

  useEffect(() => {
    loadImage();
  }, [pet]);

  const loadConsultation = async () => {
    let res = await getConsultation(match.params.actionId);
    setConsultation(res.data);
  };

  const loadPet = async () => {
    let res = await getPet(history.location.state.petId);
    setPet(res.data);
  };

  const loadImage = () => {
    if (pet.image && pet.image.contentType) {
      setImage(`${process.env.REACT_APP_API}/pet/image/${pet._id}`);
      return;
    }
    setImage(null);
    setLoading(false);
  };

  return (
    <>
      <div className="mb-3">
        <div
          className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: Colors.orange, position: "relative" }}
        >
          <GrLinkPrevious
            size={40}
            onClick={() => window.history.back()}
            color={Colors.white}
            style={{
              cursor: "pointer",
              position: "absolute",
              left: 20,
              borderRadius: 50,
              padding: 5,
              backgroundColor: Colors.white,
            }}
          />
          <h2 className="text-white text-center">{consultation.actionType}</h2>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="d-flex justify-content-center align-items-center mt-4">
              <br />
              {loading ? (
                <ClipLoader loading={loading} size={200} className="m-3" />
              ) : image ? (
                <Avatar
                  src={image}
                  style={{
                    width: 250,
                    height: 250,
                  }}
                />
              ) : (
                <Avatar style={{ width: 250, height: 250, fontSize: 20 }}>
                  {pet.name}
                </Avatar>
              )}
            </div>

            <h2 className="text-center mt-3">Perro: {pet.name}</h2>

            <div className="col">
              <br />
              <p className="font-weight-bold">
                Realizado {moment(consultation.createdAt).fromNow()}
              </p>
            </div>
          </div>

          <div className="col mb-10">
            <h3>
              <strong>Motivo: </strong>
              {consultation.reason}
            </h3>
            <h4 className="mt-4">
              <strong>Historia</strong>
            </h4>
            <p>
              <strong>Cirugía: </strong>
              {consultation.surgery}
            </p>
            <p>
              <strong>Tratameinto: </strong>
              {consultation.treatment}
            </p>
            <p>
              <strong>Alergia: </strong>
              {consultation.allergy}
            </p>
            <h4 className="mt-4">
              <strong>Examen del paciente</strong>
            </h4>

            <div className="row mb-3">
              <div className="col">
                <p className="text-center">
                  <strong>Frecuencia cardiaca</strong>
                </p>

                <div className="d-flex justify-content-center">
                  <Avatar
                    size={50}
                    style={{ fontSize: 25, backgroundColor: Colors.blue }}
                  >
                    {consultation.heartRate}
                  </Avatar>
                </div>
              </div>

              <div className="col">
                <p className="text-center">
                  <strong>Temperatura</strong>
                </p>

                <div className="d-flex justify-content-center">
                  <Avatar
                    size={50}
                    style={{ fontSize: 25, backgroundColor: Colors.blue }}
                  >
                    {consultation.temperature}
                  </Avatar>
                </div>
              </div>
            </div>

            <p>
              <strong>Apariencia General: </strong>
              {consultation.generalAppearance}
            </p>
            <p>
              <strong>Condición Corporal: </strong>
              {consultation.bodyCondition}
            </p>
            <p>
              <strong>Piel y pelo: </strong>
              {consultation.hairAndSkin}
            </p>
            <p>
              <strong>Ojos: </strong>
              {consultation.eyes}
            </p>
            <p>
              <strong>Oídos: </strong>
              {consultation.ears}
            </p>
            <p>
              <strong>Naríz/Garganta/Amígdalas: </strong>
              {consultation.noseThroatTonsils}
            </p>
            <p>
              <strong>Boca/Dientes/Encías: </strong>
              {consultation.mouthTeethGums}
            </p>
            <p>
              <strong>Musculoesquelético: </strong>
              {consultation.musculoskeletal}
            </p>
            <p>
              <strong>Corazón/Circulatorio: </strong>
              {consultation.heartCirculatory}
            </p>
            <p>
              <strong>Respiratorio: </strong>
              {consultation.respiratory}
            </p>
            <p>
              <strong>Gastrointestinal: </strong>
              {consultation.gastrointestinal}
            </p>
            <p>
              <strong>Urinario/Reproductivo: </strong>
              {consultation.urinaryReproductive}
            </p>
            <h4 className="mt-4">
              <strong>Diagnóstico</strong>
            </h4>
            <p>
              <strong>Exámenes de laboratorio: </strong>
              {consultation.laboratoryExams}
            </p>
            <p>
              <strong>Diagnóstico definitivo: </strong>
              {consultation.definitiveDiagnosis}
            </p>
            <p>
              <strong>Medicamentos administrados: </strong>
              {consultation.administeredMedications}
            </p>
            <p>
              <strong>Medicamentos recetados: </strong>
              {consultation.prescriptionDrugs}
            </p>
            <p className="text-center">
              <strong>Examinado por </strong>
              {consultation.examinedBy}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewConsultation;

import { TextField } from "@material-ui/core";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../../common/Colors";

const HistoryForm = ({
  values,
  handleChange,
  nextStep,
  prevStep,
  handleHistory,
  historyBools,
}) => {
  const { surgery, treatment, allergy } = values;
  const { surgeryB, treatmentB, allergyB } = historyBools;

  return (
    <>
      <h3 className="mt-1 font-face-lm">Historia</h3>
      <div className="form-group">
        <p className="text-center mt-1">¿Ha tenido alguna cirugía?</p>

        <div className="d-flex justify-content-center">
          <AiOutlineCheck
            size={70}
            className="icon-btn"
            onClick={() => handleHistory(true, "surgery")}
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: surgeryB ? Colors.green : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
          <AiOutlineClose
            size={70}
            className="icon-btn"
            onClick={() => handleHistory(false, "surgery")}
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: !surgeryB ? Colors.pink : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
        </div>

        {surgeryB ? (
          <TextField
            onChange={handleChange}
            fullWidth
            name="surgery"
            placeholder="¿Cuál?"
            value={surgery}
            id="outlined-name"
            multiline
          />
        ) : null}

        <p className="text-center mt-1">¿Tiene algún tratamiento?</p>

        <div className="d-flex justify-content-center">
          <AiOutlineCheck
            size={70}
            className="icon-btn"
            onClick={() => handleHistory(true, "treatment")}
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: treatmentB ? Colors.green : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
          <AiOutlineClose
            size={70}
            className="icon-btn"
            onClick={() => handleHistory(false, "treatment")}
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: !treatmentB ? Colors.pink : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
        </div>

        {treatmentB ? (
          <TextField
            onChange={handleChange}
            fullWidth
            name="treatment"
            placeholder="¿Cuál?"
            value={treatment}
            id="outlined-name"
            multiline
          />
        ) : null}

        <p className="text-center mt-1">¿Tiene alguna alergia?</p>

        <div className="d-flex justify-content-center">
          <AiOutlineCheck
            size={70}
            className="icon-btn"
            onClick={() => handleHistory(true, "allergy")}
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: allergyB ? Colors.green : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
          <AiOutlineClose
            size={70}
            className="icon-btn"
            onClick={() => handleHistory(false, "allergy")}
            color="white"
            style={{
              margin: 10,
              padding: 10,
              backgroundColor: !allergyB ? Colors.pink : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
        </div>

        {allergyB ? (
          <TextField
            onChange={handleChange}
            fullWidth
            name="allergy"
            placeholder="¿Cuál?"
            value={allergy}
            id="outlined-name"
            multiline
          />
        ) : null}
      </div>

      <div className="d-flex justify-content-between">
        <GrLinkPrevious
          size={40}
          onClick={prevStep}
          style={{ cursor: "pointer" }}
        />
        <GrLinkNext
          size={40}
          onClick={nextStep}
          style={{ cursor: "pointer" }}
        />
      </div>
    </>
  );
};

export default HistoryForm;

import { useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Modal } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../common/Colors";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SquareLoader from "react-spinners/SquareLoader";
import { createNewTest } from "../../actions/tests";
import { useSelector } from "react-redux";
import { CSVtoArray } from "../../utils/strings";
import { DATE_OPTIONS } from "../../common/Dates";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    marginTop: "40px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
});

const StoolControlModal = ({
  panel,
  showModal,
  setShowModal,
  panelPets,
  loadPanelRecords,
}) => {
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [days, setDays] = useState(0);
  const [daysData, setDaysData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleChange = (newDate, isStart) => {
    if (!isSelected) {
      setIsSelected(true);
    }
    isStart ? setStartDate(newDate["$d"]) : setEndDate(newDate["$d"]);
  };

  const handleFoodChange = (index, e) => {
    e.preventDefault();
    const temp = daysData;
    temp[index] = e.target.value;
    setDaysData(temp);
  };

  const createNewRecord = async () => {
    if (
      days <= 0 ||
      daysData.length !== days ||
      daysData.includes(undefined) ||
      daysData.includes(null) ||
      daysData.includes("")
    ) {
      toast.error("Error: verifica las fechas y las comidas");
      return;
    }

    try {
      const res = await createNewTest(token, panel._id, {
        pets: panelPets.map((pet) => pet._id),
        titles: daysData,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0],
        title: title,
      });

      console.log("createNewTest CALIDAD DE HECES res: ", res);
      toast.success("Nueva prueba creada!");
      clearAllStuff();
      loadPanelRecords(panel._id);
      setShowModal(false);
    } catch (error) {
      console.error("Error: " + error);
    }
  };

  const clearAllStuff = () => {
    setDaysData([]);
    setDays(0);
    setStartDate(new Date(Date.now()));
    setEndDate(new Date(Date.now()));
    setIsSelected(false);
  };

  useEffect(() => {
    clearAllStuff();
  }, []);

  useEffect(() => {
    const startTimestamp = new Date(startDate).getTime();
    const endTimestamp = new Date(endDate).getTime();
    const difference = endTimestamp - startTimestamp;
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24)) + 1;

    if (totalDays <= 0 && isSelected) {
      toast.error("Por favor selecciona fechas validas");
      setDays(0);
    } else {
      setDays(totalDays);
    }
  }, [startDate, endDate]);

  const DaysAndTextFields = ({ days }) => {
    let rows = [];
    for (let i = 0; i < days; i++) {
      rows.push(
        <div
          className="row align-items-center text-center justify-content-center my-3"
          key={`day-field-${i}`}
        >
          <div className="col-2">{i + 1}</div>
          <div className="col-6">
            <div className="form-floating">
              <input
                type="text"
                className="form-control form-control-sm"
                onChange={(value) => handleFoodChange(i, value)}
                required
              />
              <label htmlFor="floatingInput">
                {new Date(
                  startDate.getTime() + i * 1000 * 3600 * 24
                ).toLocaleString("es-MX", DATE_OPTIONS)}
              </label>
            </div>
          </div>
        </div>
      );
    }
    return rows;
  };

  const SeparateDaysAndTextFields = ({ days }) => {
    let rows = [];
    for (let i = 0; i < days; i++) {
      rows.push(
        <div
          className="row align-items-center text-center justify-content-center my-3"
          key={`separate-day-field-${i}`}
        >
          <div className="col-3">{i + 1}</div>
          <div className="col-9">
            <div className="form-floating">
              <input
                type="text"
                className="form-control form-control-sm"
                onChange={(value) => handleFoodChange(i, value)}
                required
              />
              <label>
                {
                  new Date(startDate.getTime() + i * 1000 * 3600 * 24)
                    .toLocaleString("es-MX", DATE_OPTIONS)
                    .split(" de 20")[0]
                }
              </label>
            </div>
          </div>
        </div>
      );
    }
    return rows;
  };

  return (
    <Modal
      open={showModal}
      centered
      width={1000}
      closeIcon={
        <AiOutlineCloseCircle
          className="pointer"
          color={Colors.orange}
          size={30}
        />
      }
      onCancel={() => setShowModal(!showModal)}
    >
      <div
        className="container-fluid p-3 my-4 d-flex justify-content-center align-items-center border rounded"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <h2 className="text-white text-center">
          Agregar nueva prueba en <br />"{panel.name}"
        </h2>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="container-fluid">
          {isLoading ? (
            <>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <SquareLoader
                  color={Colors.orange}
                  loading={isLoading}
                  size={100}
                />
                <h1 className="m-3">Cargando...</h1>
              </div>
            </>
          ) : (
            <>
              {panelPets.length > 0 ? (
                <>
                  {/* <div className="row align-items-center text-center justify-content-center h4">
                    Perros
                  </div>

                  <Grid
                    container
                    spacing={1}
                    className={classes.gridContainer}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {panelPets.map((pet, index) => {
                      return (
                        <Grid
                          key={`pet-bulletpoint-${index}`}
                          item
                          container
                          xs={12}
                          sm={6}
                          md={4}
                        >
                          <div className="h6">&#x2022; {pet.name}</div>
                        </Grid>
                      );
                    })}
                  </Grid> */}

                  <div className="row align-items-center text-center justify-content-center">
                    <div className="col-sm-12 col-md-5 my-5">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">Titulo de prueba</label>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center text-center justify-content-center">
                    <div className="col my-5">
                      <MobileDatePicker
                        label="Fecha de inicio"
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        cancelLabel="Cancelar"
                        onChange={(value) => handleChange(value, true)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>

                    <div className="col">
                      <MobileDatePicker
                        label="Fecha de fin"
                        inputFormat="DD/MM/YYYY"
                        value={endDate}
                        cancelLabel="Cancelar"
                        onChange={(value) => handleChange(value, false)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </div>

                  {days > 0 && (
                    <>
                      {isMobile ? (
                        <>
                          <div className="row align-items-center text-center justify-content-center mt-4">
                            <div className="col-3 h6">
                              <p>Dia</p>
                            </div>
                            <div className="col-9 h6">
                              <p>Comida</p>
                            </div>
                          </div>

                          <SeparateDaysAndTextFields days={days} />
                        </>
                      ) : (
                        <>
                          <div className="row align-items-center text-center justify-content-center mt-4">
                            <div className="col-2 h3">Dia</div>
                            <div className="col-6 h3">Comida</div>
                          </div>

                          <DaysAndTextFields days={days} />
                        </>
                      )}
                    </>
                  )}

                  <div className="row align-items-center text-center justify-content-center mt-4">
                    <button className="mb-3 btn-turq" onClick={createNewRecord}>
                      Crear
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <h4 className="text-center mt-5 w-50">
                      No hay ningun perro en este panel, intenta agregar alguno
                      para poder crear una nueva prueba!
                    </h4>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </LocalizationProvider>
    </Modal>
  );
};

export default StoolControlModal;

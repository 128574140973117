import axios from "axios";

export const addPetImage = async (token, data, petId) =>
  await axios.post(`${process.env.REACT_APP_API}/pet/image/${petId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deletePetImage = async (token, imageId) =>
  await axios.delete(`${process.env.REACT_APP_API}/pet/pet-image/${imageId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

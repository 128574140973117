import { useState, useEffect } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import { Colors } from "../../common/Colors";
import { isAdmin } from "../../common/Permissions";
import {
  ADD_YEARS,
  GET_FORMATTED_DATE_STRING,
  DATE_OPTIONS,
} from "../../common/Dates";

const VaccineCreatePost = ({
  values,
  setValues,
  handleVaccine,
  handleRemove,
}) => {
  const { auth } = useSelector((state) => ({ ...state }));

  const [width, setWidth] = useState(undefined);
  const [toggleInfo, setToggleInfo] = useState(false);
  const [openPuppy, setOpenPuppy] = useState(false);
  const [openDHPPI, setOpenDHPPI] = useState(false);
  const [openDHPPIRL, setOpenDHPPIRL] = useState(false);
  const [openBordetella, setOpenBordetella] = useState(false);
  const [openPuppyRemove, setOpenPuppyRemove] = useState(false);
  const [openDHPPIRemove, setOpenDHPPIRemove] = useState(false);
  const [openDHPPIRLRemove, setOpenDHPPIRLRemove] = useState(false);
  const [openBordetellaRemove, setOpenBordetellaRemove] = useState(false);
  const margin = 5;

  const { puppyDp, dhppi, dhppiRl, bordetella, nextDhppiRl, nextBordetella } =
    values;

  const setIconSizes = () => {
    if (window.innerWidth < 1024) {
      setWidth(window.innerWidth / 2.7 - 10);
    } else {
      setWidth(window.innerWidth / 5 - 100);
    }
  };

  useEffect(() => {
    setIconSizes();
  }, [width]);

  const handleClick = (name) => {
    if (name === "PuppyDp") {
      if (puppyDp && isAdmin(auth.user)) {
        setOpenPuppyRemove(true);
      }
      if (!puppyDp) {
        setOpenPuppy(true);
      }
    }

    if (name === "DHPPI") {
      if (dhppi && isAdmin(auth.user)) {
        setOpenDHPPIRemove(true);
      }
      if (!dhppi) {
        setOpenDHPPI(true);
      }
    }

    if (name === "DHPPIRL") {
      if (dhppiRl && isAdmin(auth.user)) {
        setOpenDHPPIRLRemove(true);
      }
      if (!dhppiRl) {
        setOpenDHPPIRL(true);
      }
    }

    if (name === "Bordetella") {
      if (bordetella && isAdmin(auth.user)) {
        setOpenBordetellaRemove(true);
      }
      if (!bordetella) {
        setOpenBordetella(true);
      }
    }
  };

  return (
    <div>
      <div>
        <AiFillInfoCircle
          size={25}
          color={Colors.blue}
          onClick={() => setToggleInfo(!toggleInfo)}
        />
        {toggleInfo ? (
          <p style={{ fontSize: 13, fontStyle: "italic" }}>
            Si el nombre de la vacuna se encuentra en VERDE, el perro ya ha
            recibido el medicamento. Presiona la vacuna que deseas aplicar
          </p>
        ) : null}
      </div>
      <div className="form-group">
        <div className="d-flex justify-content-center flex-wrap">
          <div
            style={{
              minWidth: width,
              minHeight: width,
              margin: margin,
              backgroundColor: puppyDp ? Colors.green : Colors.black,
              borderRadius: width,
            }}
            onClick={() => handleClick("PuppyDp")}
            className="d-flex justify-content-center align-items-center"
          >
            <h4 className="text-center mt-3 text-white">Puppy Dp</h4>
          </div>
          <div
            style={{
              minWidth: width,
              minHeight: width,
              margin: margin,
              backgroundColor: dhppi ? Colors.green : Colors.black,
              borderRadius: width,
            }}
            onClick={() => handleClick("DHPPI")}
            className="d-flex justify-content-center align-items-center"
          >
            <h4 className="text-center mt-3 text-white">DHPPI</h4>
          </div>
          <div
            style={{
              minWidth: width,
              minHeight: width,
              margin: margin,
              backgroundColor: dhppiRl ? Colors.green : Colors.black,
              borderRadius: width,
            }}
            onClick={() => handleClick("DHPPIRL")}
            className="d-flex justify-content-center align-items-center"
          >
            <h4 className="text-center mt-3 text-white">DHPPI RL</h4>
          </div>
          <div
            style={{
              minWidth: width,
              minHeight: width,
              margin: margin,
              backgroundColor: bordetella ? Colors.green : Colors.black,
              borderRadius: width,
            }}
            onClick={() => handleClick("Bordetella")}
            className="d-flex justify-content-center align-items-center"
          >
            <h4 className="text-center mt-3 text-white">Bordetella</h4>
          </div>
        </div>
      </div>

      <Dialog
        open={openPuppy}
        onClose={() => setOpenPuppy(false)}
        maxWidth="md"
      >
        <DialogTitle className="text-center">
          ¿Deseas aplicar la vacuna Puppy Dp?
        </DialogTitle>
        <DialogContent>
          <p>Fecha de aplicación</p>
          {isAdmin(auth.user) || isSuperAdmin(auth.user) ? (
            <div className="d-flex justify-content-center flex-wrap">
              <DatePicker
                placeholder=""
                className="form-control mb-3"
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                onChange={(date, dateString) => {
                  console.log("Puppy Dp => dateString: ", dateString);
                  setValues({ ...values, puppyDp: dateString });
                }}
              />
              <span className="font-italic">
                Si no se selecciona la fecha, se asignará la actual
              </span>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions className="justify-content-around">
          <button className="btn-turq" onClick={() => setOpenPuppy(false)}>
            Cancelar
          </button>
          <button className="btn-turq" onClick={() => handleVaccine("puppyDp")}>
            Continuar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDHPPI}
        onClose={() => setOpenDHPPI(false)}
        maxWidth="md"
      >
        <DialogTitle className="text-center">
          ¿Deseas aplicar la vacuna DHPPI?
        </DialogTitle>
        <DialogContent>
          <p>Fecha de aplicación</p>
          {isAdmin(auth.user) || isSuperAdmin(auth.user) ? (
            <div className="d-flex justify-content-center flex-wrap">
              <DatePicker
                placeholder=""
                className="form-control mb-3"
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                // onChange={(date, dateString) => setCreatedAt(dateString)}
                onChange={(date, dateString) => {
                  console.log("dateString: ", dateString);
                  setValues({ ...values, dhppi: dateString });
                }}
              />
              <span className="font-italic">
                Si no se selecciona la fecha, se asignará la actual
              </span>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions className="justify-content-around">
          <button className="btn-turq" onClick={() => setOpenDHPPI(false)}>
            Cancelar
          </button>
          <button className="btn-turq" onClick={() => handleVaccine("dhppi")}>
            Continuar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDHPPIRL}
        onClose={() => setOpenDHPPIRL(false)}
        maxWidth="md"
      >
        <DialogTitle className="text-center">
          ¿Deseas aplicar la vacuna DHPPI RL?
        </DialogTitle>
        <DialogContent>
          <p>Fecha de aplicación</p>
          {isAdmin(auth.user) || isSuperAdmin(auth.user) ? (
            <div className="d-flex justify-content-center flex-wrap">
              <DatePicker
                placeholder=""
                className="form-control mb-3"
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                // onChange={(date, dateString) => setCreatedAt(dateString)}
                onChange={(date, dateString) => {
                  console.log("DHPPI RL => dateString: ", dateString);
                  setValues({ ...values, dhppiRl: dateString });
                }}
              />
              <span className="font-italic">
                Si no se selecciona la fecha, se asignará la actual
              </span>
            </div>
          ) : null}
          <p className="text-center">
            La siguiente vacuna se programará en un año. (
            {ADD_YEARS(new Date(values.dhppiRl), 1).toLocaleDateString()})
          </p>
        </DialogContent>
        <DialogActions className="justify-content-around">
          <button className="btn-turq" onClick={() => setOpenDHPPIRL(false)}>
            Cancelar
          </button>
          <button className="btn-turq" onClick={() => handleVaccine("dhppiRl")}>
            Continuar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBordetella}
        onClose={() => setOpenBordetella(false)}
        maxWidth="md"
      >
        <DialogTitle className="text-center">
          ¿Deseas aplicar la vacuna Bordetella?
        </DialogTitle>
        <DialogContent>
          <p>Fecha de aplicación</p>
          {isAdmin(auth.user) || isSuperAdmin(auth.user) ? (
            <div className="d-flex justify-content-center flex-wrap">
              <DatePicker
                placeholder=""
                className="form-control mb-3"
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                // onChange={(date, dateString) => setCreatedAt(dateString)}
                onChange={(date, dateString) => {
                  console.log("Bordetella => dateString: ", dateString);
                  setValues({ ...values, bordetella: dateString });
                }}
              />
              <span className="font-italic">
                Si no se selecciona la fecha, se asignará la actual
              </span>
            </div>
          ) : null}
          <p className="text-center">
            Seleccione la fecha de la siguiente vacuna
          </p>
          <DatePicker
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
            placeholder="Siguiente Bordetella"
            className="form-control mb-3"
            onChange={(date, dateString) =>
              setValues({ ...values, nextBordetella: dateString })
            }
          />
        </DialogContent>
        <DialogActions className="justify-content-around">
          <button className="btn-turq" onClick={() => setOpenBordetella(false)}>
            Cancelar
          </button>
          <button
            className="btn-turq"
            onClick={() => handleVaccine("bordetella")}
          >
            Continuar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPuppyRemove}
        onClose={() => setOpenPuppyRemove(false)}
        maxWidth="md"
      >
        <DialogTitle className="text-center">
          ¿Deseas eliminar la vacuna Puppy Dp?
        </DialogTitle>
        <DialogActions className="justify-content-around">
          <button
            className="btn-turq"
            onClick={() => setOpenPuppyRemove(false)}
          >
            Cancelar
          </button>
          <button className="btn-turq" onClick={() => handleRemove("puppyDp")}>
            Continuar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDHPPIRemove}
        onClose={() => setOpenDHPPIRemove(false)}
        maxWidth="md"
      >
        <DialogTitle className="text-center">
          ¿Deseas eliminar la vacuna DHPPI?
        </DialogTitle>
        <DialogActions className="justify-content-around">
          <button
            className="btn-turq"
            onClick={() => setOpenDHPPIRemove(false)}
          >
            Cancelar
          </button>
          <button className="btn-turq" onClick={() => handleRemove("dhppi")}>
            Continuar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDHPPIRLRemove}
        onClose={() => setOpenDHPPIRLRemove(false)}
        maxWidth="md"
      >
        <DialogTitle className="text-center">
          ¿Deseas eliminar la vacuna DHPPI RL?
        </DialogTitle>
        <DialogActions className="justify-content-around">
          <button
            className="btn-turq"
            onClick={() => setOpenDHPPIRLRemove(false)}
          >
            Cancelar
          </button>
          <button className="btn-turq" onClick={() => handleRemove("dhppiRl")}>
            Continuar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBordetellaRemove}
        onClose={() => setOpenBordetellaRemove(false)}
        maxWidth="md"
      >
        <DialogTitle className="text-center">
          ¿Deseas eliminar la vacuna Bordetella?
        </DialogTitle>
        <DialogActions className="justify-content-around">
          <button
            className="btn-turq"
            onClick={() => setOpenBordetellaRemove(false)}
          >
            Cancelar
          </button>
          <button
            className="btn-turq"
            onClick={() => handleRemove("bordetella")}
          >
            Continuar
          </button>
        </DialogActions>
      </Dialog>

      {puppyDp && (
        <p>
          <strong>Puppy Dp</strong> aplicada el{" "}
          {GET_FORMATTED_DATE_STRING(new Date(puppyDp), DATE_OPTIONS)}
        </p>
      )}
      {dhppi && (
        <p>
          <strong>DHPPI</strong> aplicada el{" "}
          {GET_FORMATTED_DATE_STRING(new Date(dhppi), DATE_OPTIONS)}
        </p>
      )}
      {dhppiRl && (
        <>
          <p>
            <strong>DHPPI RL</strong> aplicada el{" "}
            {GET_FORMATTED_DATE_STRING(new Date(dhppiRl), DATE_OPTIONS)}
          </p>
          <p className="font-italic">
            Próxima <strong>DHPPI RL</strong> el{" "}
            {GET_FORMATTED_DATE_STRING(new Date(nextDhppiRl), DATE_OPTIONS)}
          </p>
        </>
      )}
      {bordetella && (
        <>
          <p>
            <strong>Bordetella</strong> aplicada el{" "}
            {GET_FORMATTED_DATE_STRING(new Date(bordetella), DATE_OPTIONS)}
          </p>
          <p className="font-italic">
            Próxima <strong>Bordetella</strong> el{" "}
            {GET_FORMATTED_DATE_STRING(new Date(nextBordetella), DATE_OPTIONS)}
          </p>
        </>
      )}
    </div>
  );
};

export default VaccineCreatePost;

import { useEffect, useState } from "react";
import { Colors } from "../common/Colors";
import SquareLoader from "react-spinners/SquareLoader";
import { useSelector } from "react-redux";
import "./table.css";
import { updateTest } from "../actions/tests";
import { GrLinkPrevious } from "react-icons/gr";
import { DATE_OPTIONS, GET_TIME_DIFFERENCE } from "../common/Dates";

const GroupTable = ({ group, startDate, originalRows, setOriginalRows }) => {
  const handleGramsRowsInputChange = (e, index) => {
    setOriginalRows(
      originalRows.map((row, irow) =>
        index === irow ? { ...row, grams: parseInt(e.target.value) } : row
      )
    );
  };

  const handleGradesRowsInputChange = (e, jrow, index) => {
    setOriginalRows(
      originalRows.map((row, irow) => {
        if (irow === jrow) {
          return {
            ...row,
            grades: row.grades.map((grade, igrade) => {
              if (igrade === index) {
                return e.target.valueAsNumber;
              } else {
                return grade;
              }
            }),
          };
        } else {
          return row;
        }
      })
    );
  };

  return (
    <div className="my-5">
      <div className="row align-items-center text-center justify-content-center mt-4">
        <div className="col-6 h3">{group.name}</div>
      </div>

      <div className="container tbl-container">
        <div className="row tbl-fixed">
          <div className="table-responsive">
            <table
              // cellSpacing={5}
              // cellPadding={3}
              className="table table-sm table-hover table-borderless"
            >
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>

                  {group.foodTitles.map((_, ititle) => {
                    return (
                      <th scope="col" key={`day-str-pb-${ititle}`}>
                        <h6 className="align-items-center text-center justify-content-center">
                          {startDate &&
                            new Date(
                              startDate.getTime() + ititle * 1000 * 3600 * 24
                            ).toLocaleString("es-MX", DATE_OPTIONS)}
                        </h6>
                      </th>
                    );
                  })}
                </tr>

                <tr className="table-vertical-header">
                  <th scope="col" style={{ width: "5%" }}>
                    <span
                      className="text-center tag-blue-sm"
                      style={{ backgroundColor: Colors.orange }}
                    >
                      Perro
                    </span>
                  </th>
                  <th scope="col" style={{ width: "12%" }}>
                    <span
                      className="text-center tag-blue-sm"
                      style={{ backgroundColor: Colors.orange }}
                    >
                      Gramos
                    </span>
                  </th>

                  {group.foodTitles.map((foodTitle, ifoodTitle) => {
                    return (
                      <th
                        scope="col"
                        style={{ width: "12%" }}
                        key={`food-title-pb-${ifoodTitle}`}
                      >
                        <span className="align-items-center text-center justify-content-center tag-blue-sm">
                          {foodTitle}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {originalRows.map((petRow, irow) => {
                  return (
                    <tr
                      key={`row-pb-${irow}`}
                      className="table-vertical-header"
                    >
                      <th scope="row">
                        {petRow.pet && petRow.pet.name
                          ? petRow.pet.name
                          : "Sin nombre"}
                      </th>
                      <td>
                        <input
                          type="number"
                          step={1}
                          min={0}
                          className="form-control form-control-sm"
                          value={originalRows[irow].grams || 0}
                          onChange={(e) => handleGramsRowsInputChange(e, irow)}
                        />
                      </td>
                      {petRow.grades.map((grade, igrade) => {
                        return (
                          <td key={`row-grades-pb-${igrade}`}>
                            <input
                              type="number"
                              step={0.1}
                              min={0}
                              max={10}
                              className="form-control form-control-sm"
                              value={grade || 0}
                              onChange={(e) =>
                                handleGradesRowsInputChange(e, irow, igrade)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const VisualizePalatability = ({ test, testId, panel }) => {
  const [originalRows0, setOriginalRows0] = useState(test.groups[0].rows);
  const [originalRows1, setOriginalRows1] = useState(test.groups[1].rows);
  const [startDate, setStartDate] = useState();
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleSaveRecord = async () => {
    console.log(originalRows1);

    try {
      await updateTest(token, testId, {
        panel,
        new_group0_rows: originalRows0,
        new_group1_rows: originalRows1,
      });

      setTimeout(() => {
        window.location.href = "/testing";
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setOriginalRows0(test.groups[0].rows);
    setOriginalRows1(test.groups[1].rows);
    setStartDate(
      new Date(
        new Date(test.startDate).setHours(
          new Date(test.startDate).getHours() +
            GET_TIME_DIFFERENCE(new Date(test.startDate))
        )
      )
    );
  }, [test]);

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Editar prueba</h2>
      </div>

      <div className="d-flex justify-content-center align-items-center flex-wrap my-5">
        <span className="">
          {startDate && startDate.toLocaleDateString("es-MX", DATE_OPTIONS)}
        </span>
        <span>-</span>
        <span className="">
          {new Date(
            new Date(test.endDate).setHours(
              new Date(test.endDate).getHours() +
                GET_TIME_DIFFERENCE(new Date(test.endDate))
            )
          ).toLocaleDateString("es-MX", DATE_OPTIONS)}
        </span>
      </div>

      <div className="container-fluid">
        <div className="row align-items-center text-center justify-content-center mt-4">
          <button className="mb-3 btn-turq" onClick={handleSaveRecord}>
            Guardar
          </button>
        </div>

        {test.groups && test.groups.length > 0 && (
          <>
            <GroupTable
              group={test.groups[0]}
              startDate={startDate}
              originalRows={originalRows0}
              setOriginalRows={setOriginalRows0}
            />

            <GroupTable
              group={test.groups[1]}
              startDate={startDate}
              originalRows={originalRows1}
              setOriginalRows={setOriginalRows1}
            />
          </>
        )}

        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default VisualizePalatability;

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { read, updateCage } from "../actions/cage";
import { useSelector } from "react-redux";
import CageEditForm from "../components/forms/CageEditForm";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const EditCage = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const [values, setValues] = useState({
    name: "",
    comments: "",
    capacity: "",
  });

  const { name, comments, capacity } = values;

  useEffect(() => {
    loadSellerCage();
  }, []);

  const loadSellerCage = async () => {
    let res = await read(match.params.cageId);
    setValues({ ...values, ...res.data });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let cageData = new FormData();
    cageData.append("name", name);
    cageData.append("comments", comments);
    cageData.append("capacity", capacity);

    try {
      let res = await updateCage(token, cageData, match.params.cageId);
      //console.log("CAGE UPDATE RES", res);
      toast.success(`${res.data.name} actualizada!`);
      setTimeout(() => {
        window.location.href = "/cages";
      }, 1000);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.err);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Editar Jaula</h2>
      </div>

      <div className="container-fluid">
        <div className="row d-flex justify-content-center flex-wrap">
          <div className="col-md-8 p-4">
            <br />
            <CageEditForm
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCage;

import axios from "axios";

export const register = async (user) =>
  await axios.post(`${process.env.REACT_APP_API}/register`, user);

export const login = async (user) =>
  await axios.post(`${process.env.REACT_APP_API}/login`, user);

// update user in local storage
export const updateUserInLocalStorage = (user, next) => {
  if (window.localStorage.getItem("auth")) {
    let auth = JSON.parse(localStorage.getItem("auth"));
    auth.user = user;
    localStorage.setItem("auth", JSON.stringify(auth));
    next();
  }
};

export const forgot = async (email) =>
  await axios.post(`${process.env.REACT_APP_API}/forgot-password`, email);

export const recoverPassword = async (password, token) =>
  await axios.put(`${process.env.REACT_APP_API}/reset/${token}`, {
    password,
    token,
  });

export const readUser = async (userId) =>
  await axios.get(`${process.env.REACT_APP_API}/user/${userId}`);

export const updateUser = async (token, data, userId) =>
  await axios.put(`${process.env.REACT_APP_API}/update-user/${userId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getUsers = async () =>
  await axios.get(`${process.env.REACT_APP_API}/users`);

export const hasImage = async (userId) =>
  await axios.get(`${process.env.REACT_APP_API}/user/hasimage/${userId}`);

export const deleteUser = async (token, userId) => {
  let auth = JSON.parse(localStorage.getItem("auth"));
  await axios.delete(`${process.env.REACT_APP_API}/delete-user/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (auth.user._id === userId) {
    window.localStorage.removeItem("auth");
    window.location.href = "/";
  }
};

export const giveAdminPermissions = async (userId, data, token) =>
  await axios.put(
    `${process.env.REACT_APP_API}/give-permissions/${userId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const removeAdminPermissions = async (userId, data, token) =>
  await axios.put(
    `${process.env.REACT_APP_API}/remove-permissions/${userId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

import axios from "axios";

export const getStoolRegisters = async (petId, last, limit, page) => {
  if (last === true) {
    return await axios.get(
      `${process.env.REACT_APP_API}/stool/${petId}?last=true`
    );
  }
  if (last === false) {
    return await axios.get(
      `${process.env.REACT_APP_API}/stool/${petId}?page=${page}&limit=${limit}`
    );
  }
};

export const getStoolRegistersByDate = async (
  petId,
  last,
  limit,
  page,
  date
) => {
  let _date = new Date(date);
  _date = _date.setDate(_date.getDate() + 1);
  return await axios.get(
    `${process.env.REACT_APP_API}/stool/${petId}?date=${
      new Date(_date).toISOString().split("T")[0]
    }&page=${page}&limit=${limit}`
  );
};

export const deleteStoolRegister = async (stoolId, token) => {
  return await axios.delete(`${process.env.REACT_APP_API}/stool/${stoolId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createStoolRegister = async (petId, stoolData, token) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/stool/${petId}`,
    stoolData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

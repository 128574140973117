import { useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Modal } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../common/Colors";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SquareLoader from "react-spinners/SquareLoader";
import { createNewTest } from "../../actions/tests";
import { useSelector } from "react-redux";
import { ADD_DAYS } from "../../common/Dates";

const DAYS_FOR_VERSUS_TEST = 3;
const useStyles = makeStyles({
  gridContainer: {
    marginTop: "40px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
});

const VersusModal = ({
  panel,
  showModal,
  setShowModal,
  panelPets,
  loadPanelRecords,
}) => {
  const NUM_FOODS = 2;
  const [title, setTitle] = useState("");
  const [foodTitles, setFoodTitles] = useState(Array(NUM_FOODS).fill(""));
  const [pets, setPets] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date(Date.now()).setHours(0, 0, 0, 0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date(Date.now()).setHours(0, 0, 0, 0))
  );
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleSelectAll = () => {
    var checkboxes = document.querySelectorAll("input[type=checkbox]");
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = !selectAll;
    }
    setSelectAll(!selectAll);
  };

  const clearCheckboxes = () => {
    var checkboxes = document.querySelectorAll("input[type=checkbox]");
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  };

  const handleChange = (newDate, isStart) => {
    if (!isSelected) {
      setIsSelected(true);
    }
    // isStart
    //   ? setStartDate(
    //       new Date(new Date(Date.parse(newDate["$d"])).setHours(0, 0, 0, 0))
    //     )
    //   : setEndDate(
    //       new Date(new Date(Date.parse(newDate["$d"])).setHours(0, 0, 0, 0))
    //     );
    const tempDate = new Date(
      new Date(Date.parse(newDate["$d"])).setHours(0, 0, 0, 0)
    );
    setStartDate(tempDate);
    setEndDate(ADD_DAYS(tempDate, DAYS_FOR_VERSUS_TEST - 1));
  };

  const handleFoodChange = (index, e) => {
    e.preventDefault();
    const tempFoodTitles = foodTitles.map((foodTitle, ifoodTitle) => {
      return ifoodTitle === index ? e.target.value : foodTitle;
    });
    setFoodTitles(tempFoodTitles);
  };

  const createNewRecord = async () => {
    const checkboxes = Array.from(
      document.querySelectorAll("input[type=checkbox]:checked")
    );
    const selectedPets = checkboxes
      .filter((checkbox) => checkbox.name !== "general-checkbox")
      .map((checkbox) => checkbox.value);

    if (
      title === "" ||
      startDate > endDate ||
      foodTitles.length !== NUM_FOODS ||
      foodTitles.includes(undefined) ||
      foodTitles.includes(null) ||
      foodTitles.includes("") ||
      selectedPets <= 0
    ) {
      toast.error(
        "Error: verifica que todos los datos sean validos y esten completos"
      );
      return;
    }

    try {
      const res = await createNewTest(token, panel._id, {
        title,
        food_titles: foodTitles,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0],
        pets: selectedPets,
      });

      console.log("createNewTest VERSUS res: ", res);
      toast.success("Nueva prueba creada!");
      clearAllStuff();
      loadPanelRecords(panel._id);
      setShowModal(false);
    } catch (error) {
      console.error("Error: " + error);
    }
  };

  const clearAllStuff = () => {
    clearCheckboxes();
    setTitle("");
    setFoodTitles(Array(NUM_FOODS).fill(""));
    setStartDate(new Date(new Date(Date.now()).setHours(0, 0, 0, 0)));
    setEndDate(new Date(new Date(Date.now()).setHours(0, 0, 0, 0)));
    setIsSelected(false);
  };

  useEffect(() => {
    clearAllStuff();
  }, []);

  return (
    <Modal
      open={showModal}
      centered
      width={1000}
      closeIcon={
        <AiOutlineCloseCircle
          className="pointer"
          color={Colors.orange}
          size={30}
        />
      }
      onCancel={() => setShowModal(!showModal)}
    >
      <div
        className="container-fluid p-3 my-4 d-flex justify-content-center align-items-center border rounded"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <h2 className="text-white text-center">
          Agregar nueva prueba en <br />"{panel.name}"
        </h2>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="container-fluid">
          {isLoading ? (
            <>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <SquareLoader
                  color={Colors.orange}
                  loading={isLoading}
                  size={100}
                />
                <h1 className="m-3">Cargando...</h1>
              </div>
            </>
          ) : (
            <>
              {panelPets && panelPets.length > 0 ? (
                <>
                  <div className="row align-items-center text-center justify-content-center">
                    <div className="col-sm-12 col-md-5 my-5">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingInput">Titulo de prueba</label>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center text-center justify-content-center">
                    <div className="col my-5">
                      <MobileDatePicker
                        label="Fecha de inicio"
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        cancelLabel="Cancelar"
                        onChange={(value) => handleChange(value, true)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>

                    {/* <div className="col">
                      <MobileDatePicker
                        label="Fecha de fin"
                        inputFormat="DD/MM/YYYY"
                        value={endDate}
                        cancelLabel="Cancelar"
                        onChange={(value) => handleChange(value, false)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div> */}
                  </div>

                  {startDate <= endDate ? (
                    <>
                      <div className="row align-items-center text-center justify-content-center mt-4">
                        {foodTitles.map((_, ifoodTitle) => {
                          return (
                            <div
                              className="col h3"
                              key={`food-title-${ifoodTitle}`}
                            >
                              <h4>Alimento {ifoodTitle + 1}</h4>
                            </div>
                          );
                        })}
                      </div>

                      <div className="row align-items-center text-center justify-content-center my-3">
                        {foodTitles.map((foodTitle, ifoodTitle) => {
                          return (
                            <div
                              className="col"
                              key={`food-title-input-${ifoodTitle}`}
                            >
                              <div className="form">
                                <input
                                  required
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={foodTitle}
                                  onChange={(e) =>
                                    handleFoodChange(ifoodTitle, e)
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="alert alert-warning" role="alert">
                      <strong>Advertencia:</strong> Selecciona fechas validas.
                    </div>
                  )}

                  <div className="row align-items-center text-center justify-content-center">
                    <h4>Perros</h4>
                  </div>
                  <div className="row align-items-center text-center justify-content-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={selectAll}
                        onChange={handleSelectAll}
                        name="general-checkbox"
                        id="general-checkbox"
                      />
                      <label
                        className="form-check-label h6"
                        htmlFor="general-checkbox"
                      >
                        Seleccionar todos
                      </label>
                    </div>
                  </div>

                  <Grid
                    container
                    spacing={1}
                    className={classes.gridContainer}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {panelPets.map((pet, index) => {
                      return (
                        <Grid
                          key={`pet-checkbox-${index}`}
                          item
                          container
                          xs={12}
                          sm={6}
                          md={4}
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={pet._id}
                              name={`checkbox-${index}`}
                              id={`checkbox-${index}`}
                            />
                            <label
                              className="form-check-label h6"
                              htmlFor={`checkbox-${index}`}
                            >
                              {pet.name}
                            </label>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <div className="row align-items-center text-center justify-content-center mt-4">
                    <button className="mb-3 btn-turq" onClick={createNewRecord}>
                      Crear
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <h4 className="text-center mt-5 w-50">
                      No hay ningun perro en este panel, intenta agregar alguno
                      para poder crear una nueva prueba!
                    </h4>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </LocalizationProvider>
    </Modal>
  );
};

export default VersusModal;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPetsByPanel } from "../../actions/tests";
import StoolControlModal from "./StoolControlModal";
import PalatabilityModal from "./PalatabilityModal";
import DigestibilityModal from "./DigestibilityModal";
import VersusModal from "./VersusModal";

const NewRecordModal = ({
  panel,
  showModal,
  setShowModal,
  loadPanelRecords,
}) => {
  const [panelPets, setPanelPets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const loadPetsByPanel = async () => {
    setIsLoading(true);
    const res = await getPetsByPanel(token, panel._id);
    setPanelPets(res.data);
    setIsLoading(false);
  };

  useEffect(() => {
    loadPetsByPanel();
  }, []);

  return (
    <>
      {panel._id === "calidad-heces" && (
        <StoolControlModal
          panel={panel}
          showModal={showModal}
          setShowModal={setShowModal}
          panelPets={panelPets}
          loadPanelRecords={loadPanelRecords}
        />
      )}

      {panel._id === "palatabilidad" && (
        <PalatabilityModal
          panel={panel}
          showModal={showModal}
          setShowModal={setShowModal}
          panelPets={panelPets}
          loadPanelRecords={loadPanelRecords}
        />
      )}

      {panel._id === "digestibilidad" && (
        <DigestibilityModal
          panel={panel}
          showModal={showModal}
          setShowModal={setShowModal}
          loadPanelRecords={loadPanelRecords}
        />
      )}

      {panel._id === "versus" && (
        <VersusModal
          panel={panel}
          showModal={showModal}
          setShowModal={setShowModal}
          panelPets={panelPets}
          loadPanelRecords={loadPanelRecords}
        />
      )}
    </>
  );
};

export default NewRecordModal;

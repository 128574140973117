import { useState } from "react";
import { toast } from "react-toastify";
import { recoverPassword } from "../actions/auth";
import NewPwForm from "../components/NewPwForm";
import { useDispatch } from "react-redux";
import { Colors } from "../common/Colors";

const NewPassword = (props) => {
  const [password, setPassword] = useState("");
  const { token } = props;
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("RECOVER PASSWORD DATA");

    try {
      let res = await recoverPassword(password, token);
      if (res.data) {
        dispatch({
          type: "RECOVER_PASSWORD",
          payload: res.data,
        });
        toast.success("La contraseña fue actualizada con éxito.");
        setPassword("");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1500);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400) toast.error(err.response.data);
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <h2 className="text-white text-center">Nueva Contraseña</h2>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 mb-3">
            <NewPwForm
              handleSubmit={handleSubmit}
              password={password}
              setPassword={setPassword}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;

import { useEffect, useState } from "react";
import { getConsultations, deleteAction } from "../actions/control";
import { useSelector } from "react-redux";
import ActionCard from "../components/cards/ActionCard";
import { toast } from "react-toastify";
import { GrLinkPrevious } from "react-icons/gr";
import { BsFileSpreadsheet } from "react-icons/bs";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";

const Consultations = ({ history, match }) => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const { auth } = useSelector((state) => ({ ...state }));

  const [consultations, setConsultations] = useState([]);

  useEffect(() => {
    loadConsultations();
  }, []);

  const loadConsultations = async () => {
    try {
      const res = await getConsultations(match.params.petId);
      setConsultations(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionDelete = async (actionId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Acción?")) return;
    deleteAction(token, actionId).then((res) => {
      toast.success("Acción eliminada");
      loadConsultations();
    });
  };

  const handleShowMore = (actionId, _, petId) => {
    history.push({
      pathname: `/consultation/${actionId}`,
      state: {
        petId,
      },
    });
  };

  return (
    <>
      {console.log(consultations[0])}
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Consultas</h2>
      </div>

      <div className="m-3 d-flex justify-content-between">
        <button
          className="btn-turq"
          onClick={() =>
            history.push(`/pet/newconsultation/${match.params.petId}`)
          }
        >
          + Agregar Consulta
        </button>
        {isAdmin(auth.user) ? (
          <a
            href={`${process.env.REACT_APP_API}/consultation-format/${match.params.petId}`}
            download
            className="btn-turq"
            style={{ color: "white" }}
          >
            <BsFileSpreadsheet size={30} />
          </a>
        ) : null}
      </div>

      <div>
        {consultations.map((action) => (
          <ActionCard
            action={action}
            handleActionDelete={handleActionDelete}
            handleShowMore={handleShowMore}
          />
        ))}
      </div>
    </>
  );
};

export default Consultations;

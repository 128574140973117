import axios from "axios";

// export const createTraining = async (token, data) =>
//   await axios.post(`${process.env.REACT_APP_API}/create-training`, data, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });

export const createShower = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-shower`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createClean = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-clean`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createDeworming = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-deworming`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createWeight = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-weight`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createVaccine = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-vaccine`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createConsultation = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-consultation`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createComment = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-comment`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createDaily = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-daily`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getCleanByCage = async (cageId) =>
  await axios.get(`${process.env.REACT_APP_API}/clean`, {
    params: {
      cageId,
    },
  });

export const getCleanById = async (cleanId) =>
  await axios.get(`${process.env.REACT_APP_API}/clean/${cleanId}`);

export const approve = async (token, data, cleanId) =>
  await axios.put(
    `${process.env.REACT_APP_API}/clean/approve/${cleanId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getCleanImgsById = async (cleanId) =>
  await axios.get(`${process.env.REACT_APP_API}/clean-images`, {
    params: {
      cleanId,
    },
  });

export const getCleanImgsByCage = async (cageId) =>
  await axios.get(`${process.env.REACT_APP_API}/clean-images`, {
    params: {
      cageId,
    },
  });

export const getActions = async () =>
  await axios.get(`${process.env.REACT_APP_API}/actions`);

export const getActionsByDate = async (date) =>
  await axios.get(`${process.env.REACT_APP_API}/actions-by-date`, {
    params: date,
  });

export const getActionsByMonth = async (start, end) =>
  await axios.get(`${process.env.REACT_APP_API}/actions-by-month`, {
    params: { start, end },
  });

export const notApprovedCleans = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/notapproved`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getComments = async (token, userId) =>
  await axios.get(`${process.env.REACT_APP_API}/comments/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getComment = async (actionId) =>
  await axios.get(`${process.env.REACT_APP_API}/comment/${actionId}`);

export const getWeights = async (petId) =>
  await axios.get(`${process.env.REACT_APP_API}/weights`, { params: petId });

export const getShower = async (actionId) =>
  await axios.get(`${process.env.REACT_APP_API}/shower/${actionId}`);

export const getShowers = async (petId) =>
  await axios.get(`${process.env.REACT_APP_API}/showers`, { params: petId });

export const getDeworming = async (actionId) =>
  await axios.get(`${process.env.REACT_APP_API}/deworming/${actionId}`);

export const getDewormings = async (petId) =>
  await axios.get(`${process.env.REACT_APP_API}/dewormings`, { params: petId });

export const getConsultation = async (actionId) =>
  await axios.get(`${process.env.REACT_APP_API}/consultation/${actionId}`);

export const getConsultations = async (petId) =>
  await axios.get(`${process.env.REACT_APP_API}/consultations`, {
    params: {
      petId,
    },
  });

export const getPetActions = async (petId) =>
  await axios.get(`${process.env.REACT_APP_API}/petactions`, { params: petId });

export const getVaccine = async (actionId) =>
  await axios.get(`${process.env.REACT_APP_API}/vaccine/${actionId}`);

export const getDaily = async (actionId) =>
  await axios.get(`${process.env.REACT_APP_API}/daily/${actionId}`);

export const getLastDaily = async (petId) =>
  await axios.get(`${process.env.REACT_APP_API}/lastdaily/${petId}`);

export const deleteAction = async (token, actionId) => {
  await axios.delete(`${process.env.REACT_APP_API}/delete-action/${actionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deletePetActions = async (token, petId) => {
  await axios.delete(
    `${process.env.REACT_APP_API}/delete-pet-actions/${petId}`
  );
};

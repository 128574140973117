const TransferCard = ({ cage, handlePetTransfer }) => {
  return (
    <>
      <div className="card mb-3">
        <div className="row no-gutters">
          <div className="col">
            <div className="card-body">
              <h4 className="card-title text-center">
                {cage.name} <span className="float-right text-primary"></span>{" "}
              </h4>
              <p>Capacidad: {cage.capacity}</p>

              <div className="d-flex justify-content-center align-content-center h4">
                <button
                  className="btn-turq"
                  onClick={() => handlePetTransfer(cage._id)}
                >
                  Transferir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferCard;

import axios from "axios";

export const getDriveFiles = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/file`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadFile = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/file`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const downloadFile = async (token, file) => {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);

  axios
    .get(`${process.env.REACT_APP_API}/file/${file._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    })
    .then((res) => new Blob([res.data], { type: res.headers["content-type"] }))
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);
      anchor.href = objectUrl;
      anchor.download = file.title;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    });
};

export const deleteFile = async (token, fileId) =>
  await axios.delete(`${process.env.REACT_APP_API}/file/${fileId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../common/Colors";
import SquareLoader from "react-spinners/SquareLoader";
import { updateTest } from "../actions/tests";
import { useSelector } from "react-redux";
import { GrLinkPrevious } from "react-icons/gr";
import { DATE_OPTIONS, GET_TIME_DIFFERENCE } from "../common/Dates";

const VisualizeStoolControl = ({ test, testId, panel }) => {
  const [originalRows, setOriginalRows] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(
      new Date(test.startDate).setHours(
        new Date(test.startDate).getHours() +
          GET_TIME_DIFFERENCE(new Date(test.startDate))
      )
    )
  );
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleGramsRowsInputChange = (e, index) => {
    setOriginalRows(
      originalRows.map((row, irow) =>
        index === irow ? { ...row, grams: parseInt(e.target.value) } : row
      )
    );
  };

  const handleGradesRowsInputChange = (e, jrow, jday, index) => {
    setOriginalRows(
      originalRows.map((row, irow) => {
        if (irow === jrow) {
          return {
            ...row,
            days: row.days.map((day, iday) => {
              if (iday === jday) {
                return {
                  ...day,
                  grades: day.grades.map((grade, igrade) => {
                    if (igrade === index) {
                      return e.target.valueAsNumber;
                    } else {
                      return grade;
                    }
                  }),
                };
              } else {
                return day;
              }
            }),
          };
        } else {
          return row;
        }
      })
    );
  };

  useEffect(() => {
    setOriginalRows(test.rows);
  }, [test]);

  const handleSaveRecord = async () => {
    console.log(originalRows);

    try {
      await updateTest(token, testId, {
        panel,
        new_rows: originalRows,
      });

      setTimeout(() => {
        window.location.href = "/testing";
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Editar prueba</h2>
      </div>

      <div className="container-fluid">
        <div className="px-5">
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <h3 className="m-3 text-center">{test.title}</h3>
          </div>

          <div className="d-flex justify-content-center align-items-center flex-wrap my-5">
            <span className="">
              {new Date(
                new Date(test.startDate).setHours(
                  new Date(test.startDate).getHours() +
                    GET_TIME_DIFFERENCE(new Date(test.startDate))
                )
              ).toLocaleDateString("es-MX", DATE_OPTIONS)}
            </span>
            <span>-</span>
            <span className="">
              {new Date(
                new Date(test.endDate).setHours(
                  new Date(test.endDate).getHours() +
                    GET_TIME_DIFFERENCE(new Date(test.endDate))
                )
              ).toLocaleDateString("es-MX", DATE_OPTIONS)}
            </span>
          </div>

          <div className="row align-items-center text-center justify-content-center mt-4">
            <button className="mb-5 btn-turq" onClick={handleSaveRecord}>
              Guardar
            </button>
          </div>

          <div className="my-5">
            <div className="container tbl-container">
              <div className="row tbl-fixed">
                <div className="table-responsive">
                  <table
                    cellSpacing={3}
                    cellPadding={3}
                    className="table table-sm table-hover table-borderless"
                  >
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>

                        {test.titles.map((_, ititle) => {
                          return (
                            <th scope="col" key={`day-str-sc-${ititle}`}>
                              <h6 className="align-items-center text-center justify-content-center">
                                {new Date(
                                  startDate.getTime() +
                                    ititle * 1000 * 3600 * 24
                                ).toLocaleString("es-MX", DATE_OPTIONS)}
                              </h6>
                            </th>
                          );
                        })}
                      </tr>

                      <tr className="table-vertical-header">
                        <th scope="col" rowSpan="2">
                          <span
                            className="text-center tag-blue-sm"
                            style={{ backgroundColor: Colors.orange }}
                          >
                            Perro
                          </span>
                        </th>
                        <th scope="col" rowSpan="2">
                          <span
                            className="text-center tag-blue-sm"
                            style={{ backgroundColor: Colors.orange }}
                          >
                            Gramos
                          </span>
                        </th>

                        {test.titles.map((title, ititle) => {
                          return (
                            <th scope="col" key={`food-title-sc-${ititle}`}>
                              <span className="align-items-center text-center justify-content-center tag-blue-sm">
                                {title}
                              </span>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    <tbody>
                      {originalRows?.map((row, irow) => {
                        return (
                          <tr
                            key={`row-sc-${irow}`}
                            className="table-vertical-header"
                          >
                            <th scope="row">{row.pet.name}</th>
                            <td>
                              <input
                                type="number"
                                step={1}
                                min={0}
                                className="form-control form-control-sm"
                                value={originalRows[irow].grams || 0}
                                onChange={(e) =>
                                  handleGramsRowsInputChange(e, irow)
                                }
                              />
                            </td>

                            {row.days.map((day, iday) => {
                              return (
                                <td scope="col" key={`row-days-pb-${iday}`}>
                                  <div className="col d-flex align-items-center input-group input-group-sm">
                                    {day.grades.map((grade, igrade) => {
                                      return (
                                        <input
                                          key={`row-day-sc-${iday}-grade-${igrade}`}
                                          type="number"
                                          step={0.25}
                                          min={0}
                                          max={5}
                                          className="form-control form-control-sm"
                                          value={grade || 0}
                                          onChange={(e) =>
                                            handleGradesRowsInputChange(
                                              e,
                                              irow,
                                              iday,
                                              igrade
                                            )
                                          }
                                        />
                                      );
                                    })}
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default VisualizeStoolControl;

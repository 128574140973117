import { useState } from "react";
import { toast } from "react-toastify";
import { createConsultation } from "../actions/control";
import { useSelector } from "react-redux";
import HistoryForm from "../components/forms/HistoryForm";
import PatientExaminationForm from "../components/forms/PatientExaminationForm";
import DiagnosisForm from "../components/forms/DiagnosisForm";
import { TextField } from "@material-ui/core";
import { GrLinkNext } from "react-icons/gr";
import { Colors } from "../common/Colors";

const NewConsultation = ({ match }) => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [toggles, setToggles] = useState({
    generalAppearanceT: false,
    bodyConditionT: false,
    hairAndSkinT: false,
    eyesT: false,
    earsT: false,
    noseThroatTonsilsT: false,
    mouthTeethGumsT: false,
    musculoskeletalT: false,
    heartCirculatoryT: false,
    respiratoryT: false,
    gastrointestinalT: false,
    urinaryReproductiveT: false,
  });

  const [toggleInfo, setToggleInfo] = useState(false);

  const translation = {
    generalAppearance: "Apariencia general",
    bodyCondition: "Condición corporal",
    hairAndSkin: "Piel y pelo",
    eyes: "Ojos",
    ears: "Oídos",
    noseThroatTonsils: "Naríz / Garganta / Amígdalas",
    mouthTeethGums: "Boca / Dientes / Encías",
    musculoskeletal: "Musculoesquelético",
    heartCirculatory: "Corazón circulatorio",
    respiratory: "Respiratorio",
    gastrointestinal: "Gastrointestinal",
    urinaryReproductive: "Urinario / Reproductivo",
  };

  const [historyBools, setHistoryBools] = useState({
    surgeryB: false,
    treatmentB: false,
    allergyB: false,
    laboratoryExamsB: false,
  });

  const [values, setValues] = useState({
    reason: "",
    surgery: "No",
    treatment: "No",
    allergy: "No",
    heartRate: "",
    temperature: "",
    generalAppearance: "",
    bodyCondition: "",
    hairAndSkin: "",
    eyes: "",
    ears: "",
    noseThroatTonsils: "",
    mouthTeethGums: "",
    musculoskeletal: "",
    heartCirculatory: "",
    respiratory: "",
    gastrointestinal: "",
    urinaryReproductive: "",
    laboratoryExams: "No",
    definitiveDiagnosis: "",
    administeredMedications: "",
    prescriptionDrugs: "",
    examinedBy: "",
    createdAt: "",
  });
  const {
    reason,
    surgery,
    treatment,
    allergy,
    heartRate,
    temperature,
    generalAppearance,
    bodyCondition,
    hairAndSkin,
    eyes,
    ears,
    noseThroatTonsils,
    mouthTeethGums,
    musculoskeletal,
    heartCirculatory,
    respiratory,
    gastrointestinal,
    urinaryReproductive,
    laboratoryExams,
    definitiveDiagnosis,
    administeredMedications,
    prescriptionDrugs,
    examinedBy,
    createdAt,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(values);

    let consultationData = new FormData();
    consultationData.append("madeFor", match.params.petId);
    consultationData.append("reason", reason);
    consultationData.append("surgery", surgery);
    consultationData.append("treatment", treatment);
    consultationData.append("allergy", allergy);
    consultationData.append("heartRate", heartRate);
    consultationData.append("temperature", temperature);
    consultationData.append("generalAppearance", generalAppearance);
    consultationData.append("bodyCondition", bodyCondition);
    consultationData.append("hairAndSkin", hairAndSkin);
    consultationData.append("eyes", eyes);
    consultationData.append("ears", ears);
    consultationData.append("noseThroatTonsils", noseThroatTonsils);
    consultationData.append("mouthTeethGums", mouthTeethGums);
    consultationData.append("musculoskeletal", musculoskeletal);
    consultationData.append("heartCirculatory", heartCirculatory);
    consultationData.append("respiratory", respiratory);
    consultationData.append("gastrointestinal", gastrointestinal);
    consultationData.append("urinaryReproductive", urinaryReproductive);
    consultationData.append("laboratoryExams", laboratoryExams);
    consultationData.append("definitiveDiagnosis", definitiveDiagnosis);
    consultationData.append("administeredMedications", administeredMedications);
    consultationData.append("prescriptionDrugs", prescriptionDrugs);
    consultationData.append("examinedBy", examinedBy);
    createdAt && consultationData.append("createdAt", createdAt);

    try {
      await createConsultation(token, consultationData);
      toast.success(`¡Consulta creada exitosamente!`);
      setTimeout(() => {
        window.location.href = "/pet/" + match.params.petId;
      }, 1000);
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const nextStep = () => {
    setOpen(false);
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleClick = (name) => {
    toast.info(translation[name], {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    let aux = toggles;
    aux[`${name}T`] = !aux[`${name}T`];
    setToggles({ ...aux });
    if (toggles[`${name}T`]) {
      aux = values;
      aux[name] = "Normal";
      setValues({ ...aux });
    } else {
      aux = values;
      aux[name] = "";
      setValues({ ...aux });
    }
  };

  const handleHistory = (checked, name) => {
    let aux = historyBools;
    aux[`${name}B`] = checked;
    setHistoryBools({ ...aux });
    if (!checked) {
      aux = values;
      aux[name] = "No";
      setValues({ ...aux });
    } else {
      aux = values;
      aux[name] = "";
      setValues({ ...aux });
    }
  };

  const handleNext = () => {
    for (let [, value] of Object.entries(toggles)) {
      if (value === false) {
        setOpen(true);
        return;
      }
    }

    setOpen(false);
    nextStep();
  };

  const handleInfo = () => {
    setToggleInfo(!toggleInfo);
  };

  const showStep = () => {
    if (step === 0) {
      return (
        <>
          <h3 className="mt-1">¿Cuál es el motivo de la consulta?</h3>

          <div className="form-group">
            <TextField
              onChange={handleChange}
              fullWidth
              name="reason"
              placeholder="Motivo"
              value={reason}
              id="outlined-name"
              multiline
            />
          </div>

          <div className="d-flex justify-content-end">
            <GrLinkNext
              size={40}
              onClick={nextStep}
              style={{ cursor: "pointer" }}
            />
          </div>
        </>
      );
    }
    if (step === 1) {
      return (
        <>
          <HistoryForm
            nextStep={nextStep}
            handleChange={handleChange}
            values={values}
            prevStep={prevStep}
            handleHistory={handleHistory}
            historyBools={historyBools}
          />
        </>
      );
    }
    if (step === 2) {
      return (
        <>
          <h3 className="text-center font-face-lm">Examen del paciente</h3>
          <PatientExaminationForm
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={values}
            handleClick={handleClick}
            toggles={toggles}
            open={open}
            setOpen={setOpen}
            handleNext={handleNext}
            translation={translation}
            handleInfo={handleInfo}
            toggleInfo={toggleInfo}
            handleHistory={handleHistory}
            historyBools={historyBools}
          />
        </>
      );
    }
    if (step === 3) {
      return (
        <>
          <h3 className="font-face-lm">Diagnóstico</h3>
          <DiagnosisForm
            prevStep={prevStep}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            setValues={setValues}
            values={values}
          />
        </>
      );
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 text-center"
        style={{ backgroundColor: Colors.orange }}
      >
        <h2 className="text-white">Nueva Consulta</h2>
      </div>
      <div className="container-fluid col-md-8 col-md-offset-4 p-4">
        <div className="row">
          <br />
          {showStep()}
        </div>
      </div>
    </>
  );
};

export default NewConsultation;

import axios from "axios";

export const createCage = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-cage`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const allCages = async () =>
  await axios.get(`${process.env.REACT_APP_API}/cages`);

export const sellerCages = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/seller-cages`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteCage = async (token, cageId) =>
  await axios.delete(`${process.env.REACT_APP_API}/delete-cage/${cageId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const read = async (cageId) =>
  await axios.get(`${process.env.REACT_APP_API}/cage/${cageId}`);

export const updateCage = async (token, data, cageId) =>
  await axios.put(`${process.env.REACT_APP_API}/update-cage/${cageId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const userCageBookings = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/user-cage-bookings`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const isAlreadyBooked = async (token, cageId) =>
  await axios.get(`${process.env.REACT_APP_API}/is-already-booked/${cageId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const searchListings = async (query) =>
  await axios.post(`${process.env.REACT_APP_API}/search-listings`, query);

export const hasImage = async (cageId) =>
  await axios.get(`${process.env.REACT_APP_API}/cage/hasimage/${cageId}`);

export const updateClean = async (token, cageId, lastClean) =>
  await axios.put(
    `${process.env.REACT_APP_API}/update-clean/${cageId}`,
    lastClean,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

import { useState } from "react";
import { toast } from "react-toastify";
import { createCage } from "../actions/cage";
import { useSelector } from "react-redux";
import CageCreateForm from "../components/forms/CageCreateForm";
import { Avatar } from "@material-ui/core";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const NewCage = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const [values, setValues] = useState({
    name: "",
    comments: "",
    activeFrom: "",
    capacity: "",
    isDiningRoom: false,
  });

  const { name, comments, capacity, isDiningRoom } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(values);
    // console.log(location);

    let cageData = new FormData();
    cageData.append("name", name);
    cageData.append("comments", comments);
    cageData.append("activeFrom", new Date());
    cageData.append("capacity", capacity);
    cageData.append("lastClean", new Date().setHours(0, 0, 0, 0));
    cageData.append("isDiningRoom", isDiningRoom);

    // console.log([...cageData]);

    try {
      let res = await createCage(token, cageData);
      //console.log("CAGE CREATE RES", res);
      toast.success(`¡Jaula: ${name} creada exitosamente!`);
      setTimeout(() => {
        window.location.href = "/cages";
      }, 1000);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "isDiningRoom") {
      setValues({ ...values, [e.target.name]: e.target.checked });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Agregar Jaula</h2>
      </div>

      <div className="container-fluid">
        <div className="row d-flex justify-content-center flex-wrap">
          <div className="col-md-8 p-4">
            <br />
            <CageCreateForm
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCage;

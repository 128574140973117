import { useState, useEffect } from "react";
import DashboardNav from "../components/DashboardNav";
import ConnectNav from "../components/ConnectNav";
import { useSelector } from "react-redux";
import {
  deleteAction,
  notApprovedCleans,
  getComments,
} from "../actions/control";
import { toast } from "react-toastify";
import CleanCard from "../components/cards/CleanCard";
import { updateClean } from "../actions/cage";
import ClipLoader from "react-spinners/ClipLoader";
import CommentCard from "../components/cards/CommentCard";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";

const DashboardSeller = ({ history }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [cleans, setCleans] = useState([]);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  const { token } = auth;

  useEffect(() => {
    auth && isAdmin(auth.user) ? loadCleanReviews() : loadComments();
  }, []);

  const loadCleanReviews = async () => {
    let res = await notApprovedCleans(auth.token);
    setCleans(res.data.reverse());
    setLoading(false);
  };

  const loadComments = async () => {
    try {
      let res = await getComments(auth.token, auth.user._id);
      setComments(res.data.reverse());
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleActionDelete = async (actionId, cageId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Acción?")) return;
    deleteAction(auth.token, actionId).then((res) => {
      toast.success("Acción eliminada");
      loadCleanReviews();
    });
    let cageData = new FormData();
    cageData.append("lastClean", "");
    await updateClean(token, cageId, cageData);
  };

  const handleCommentDelete = async (actionId, cageId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Acción?")) return;
    deleteAction(auth.token, actionId).then((res) => {
      toast.success("Acción eliminada");
      loadComments();
    });
  };

  const handleShowMore = (cleanId, cageId) => {
    history.push({ pathname: `/clean/${cleanId}`, state: { cageId } });
  };

  const handleShowComment = (commentId, cageId) => {
    history.push({
      pathname: `/comment/${commentId}`,
      state: cageId, //cageId
    });
  };

  const reviews = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-10">
          <h4>Revisiones pendientes</h4>
        </div>
      </div>

      <div className="d-flex justify-content-center flex-wrap">
        {cleans.length > 0 ? (
          <div className="col-md-5">
            {cleans.map((clean) => (
              <CleanCard
                key={clean._id}
                clean={clean}
                handleActionDelete={handleActionDelete}
                handleShowMore={handleShowMore}
              />
            ))}
          </div>
        ) : (
          <h4 className="text-muted text-center mt-3">
            Sin revisiones pendientes
          </h4>
        )}
      </div>
    </div>
  );

  const myComments = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-10">
          <h4>Comentarios</h4>
        </div>
      </div>

      {comments.length > 0 ? (
        <div className="col">
          {comments.map((h) => (
            <CommentCard
              key={h._id}
              h={h}
              handleActionDelete={handleCommentDelete}
              handleShowMore={handleShowComment}
            />
          ))}
        </div>
      ) : (
        <h4 className="text-muted text-center mt-3">
          Sin comentarios por revisar
        </h4>
      )}
    </div>
  );

  return (
    <>
      <div
        className="container-fluid p-5"
        style={{ backgroundColor: Colors.orange }}
      >
        <ConnectNav />
      </div>

      <div className="container-fluid p-4">
        <DashboardNav />
      </div>

      {loading ? (
        <ClipLoader loading={loading} size={200} className="m-3" />
      ) : auth && isAdmin(auth.user) ? (
        reviews()
      ) : (
        myComments()
      )}

      {/* <pre>{JSON.stringify(auth, null, 4)}</pre> */}
    </>
  );
};

export default DashboardSeller;

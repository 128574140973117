import { DatePicker } from "antd";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { isAdmin, isSuperAdmin } from "../../common/Permissions";
import { useSelector } from "react-redux";

const DewormingCreateForm = ({
  values,
  setValues,
  handleChange,
  handleSubmit,
  manualSelect,
  handleChecked,
}) => {
  const { deworming } = values;
  const { auth } = useSelector((state) => ({ ...state }));

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <TextField
          onChange={handleChange}
          fullWidth
          name="deworming"
          placeholder="Medicamento administrado"
          value={deworming}
          id="outlined-name"
        />

        <FormGroup className="m-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={manualSelect}
                onChange={handleChecked}
                color="default"
              />
            }
            label="Seleccionar próxima fecha de desparasitación manualmente"
          />
        </FormGroup>

        {manualSelect ? (
          <DatePicker
            placeholder="Próxima desparasitación"
            className="form-control"
            onChange={(date, dateString) =>
              setValues({ ...values, nextDeworming: dateString })
            }
          />
        ) : (
          <p>Próxima desparasitación en 6 meses</p>
        )}

        {isAdmin(auth.user) || isSuperAdmin(auth.user) ? (
          <div className="d-flex justify-content-center flex-wrap">
            <DatePicker
              placeholder="Fecha de desparasitación"
              className="form-control mt-3"
              onChange={(date, dateString) =>
                setValues({ ...values, createdAt: dateString })
              }
            />
            <span className="font-italic">
              Si no se selecciona la fecha, se asignará la actual
            </span>
          </div>
        ) : null}
      </div>

      <button className="btn-turq">Guardar</button>
    </form>
  );
};

export default DewormingCreateForm;

import moment from "moment";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import "./StoolCard.styles.css";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";

const StoolCard = ({ register, handleStoolDelete }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { stool_register, image_url } = register;

  return (
    <>
      <Dialog open={showDeleteDialog}>
        <DialogTitle>¿Seguro que deseas eliminar este registro?</DialogTitle>
        <DialogActions className="d-flex justify-content-center mb-3">
          <button
            className="btn-turq"
            onClick={() => setShowDeleteDialog(false)}
          >
            Cancelar
          </button>
          <button
            className="btn-turq"
            onClick={() => handleStoolDelete(stool_register._id)}
          >
            Eliminar
          </button>
        </DialogActions>
      </Dialog>
      <div className="card d-flex flex-row card-holder">
        <FaTrash
          className="trash-icon"
          size={30}
          onClick={() => setShowDeleteDialog(true)}
        />
        <div className="img-holder">
          <img alt={stool_register._id} src={image_url} className="image" />
        </div>
        <div className="data-holder">
          <h3 className="card-score">{stool_register.score}</h3>
          <span className="font-italic text-center hint">
            Hace {moment(stool_register.createdAt).fromNow(true)} por{" "}
            {stool_register.postedBy.name
              ? stool_register.postedBy.name
              : "Alguien"}
          </span>
        </div>
      </div>
    </>
  );
};
export default StoolCard;

import { useState } from "react";
import { toast } from "react-toastify";
import { createPet } from "../actions/pet";
import { useSelector } from "react-redux";
import PetCreateForm from "../components/forms/PetCreateForm";
import { Avatar } from "@material-ui/core";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const NewPet = (props) => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const { cageId } = props.location;

  // state
  const [values, setValues] = useState({
    name: "",
    comments: "",
    image: "",
    born: "",
    arrive: "",
    color: "",
    breed: "",
    panel: "",
    sex: "",
  });

  const [preview, setPreview] = useState(
    "https://via.placeholder.com/100x100.png?text=PREVIEW"
  );

  const {
    name,
    comments,
    image,
    born,
    arrive,
    color,
    breed,
    size,
    panel,
    sex,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();

    let petData = new FormData();
    petData.append("name", name);
    petData.append("comments", comments);
    petData.append("sex", sex);
    image && petData.append("image", image);
    petData.append("born", born);
    petData.append("arrive", arrive);
    petData.append("color", color);
    petData.append("breed", breed);
    petData.append("size", size);
    petData.append("panel", panel);
    cageId && petData.append("locatedIn", cageId);

    try {
      await createPet(token, petData);

      toast.success(`¡Bienvenido al bioterio, ${name}!`);

      setTimeout(() => {
        window.location.href = "/pets";
      }, 1000);
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data);
    }
  };

  const handleImageChange = (e) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
    setValues({ ...values, image: e.target.files[0] });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Registrar Perro</h2>
      </div>

      <div className="container-fluid col-md-8 col-md-offset-4 p-4">
        <div className="d-flex justify-content-center mt-3">
          {image ? (
            <Avatar
              src={preview}
              style={{
                width: 310,
                height: 310,
              }}
            />
          ) : (
            <Avatar
              style={{
                width: 310,
                height: 310,
                fontSize: 20,
              }}
            >
              Sin imagen
            </Avatar>
          )}
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <br />
            <PetCreateForm
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              handleImageChange={handleImageChange}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPet;

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updatePet } from "../actions/pet";
import { useSelector } from "react-redux";
import VaccineCreateForm from "../components/forms/VaccineCreatePost";
import { GrLinkPrevious } from "react-icons/gr";
import { getPet } from "../actions/pet";
import { createVaccine } from "../actions/control";
import { BsFileSpreadsheet } from "react-icons/bs";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";
import { ADD_YEARS } from "../common/Dates";

const NewVaccine = ({ match, history }) => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const [values, setValues] = useState({
    puppyDd: "",
    dhppi: "",
    dhppiRl: "",
    bordetella: "",
    nextDhppiRl: "",
    nextBordetella: "",
  });

  const { nextBordetella } = values;

  useEffect(() => {
    loadValues();
    if (needsDHPPIRL(new Date(), new Date(values.nextDhppiRl))) {
      handleRemove("dhppiRl");
    }
    if (needsDHPPIRL(new Date(), new Date(values.nextBordetella))) {
      handleRemove("bordetella");
    }
  }, []);

  const loadValues = async () => {
    let res = await getPet(match.params.petId);
    setValues(res.data);
  };

  const handleVaccine = async (vaccine) => {
    let vaccineData = new FormData();
    vaccineData.append("madeFor", match.params.petId);
    vaccineData.append("vaccineType", vaccine);

    let petData = new FormData();

    if (vaccine === "dhppiRl") {
      petData.append("dhppiRl", new Date(values.dhppiRl));
      petData.append("nextDhppiRl", ADD_YEARS(new Date(values.dhppiRl), 1));
    }
    if (vaccine === "bordetella") {
      petData.append("bordetella", new Date(values.bordetella));
      petData.append("nextBordetella", nextBordetella);
    }
    if (vaccine === "dhppi") {
      petData.append("dhppi", new Date(values.dhppi));
    }
    if (vaccine === "puppyDp") {
      petData.append("puppyDp", new Date(values.puppyDp));
    }

    try {
      await updatePet(token, petData, match.params.petId);
      await createVaccine(token, vaccineData);
      toast.success(`Vacuna creada exitosamente!`);
      history.push({
        pathname: `/pet/${match.params.petId}`,
        state: true,
      });
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data);
    }
  };

  const handleRemove = async (vaccine) => {
    let petData = new FormData();

    if (vaccine === "dhppiRl") {
      petData.append("dhppiRl", "");
      petData.append("nextDhppiRl", "");
    }
    if (vaccine === "bordetella") {
      petData.append("bordetella", "");
      petData.append("nextBordetella", "");
    }
    if (vaccine === "dhppi") {
      petData.append("dhppi", "");
    }
    if (vaccine === "puppyDp") {
      petData.append("puppyDp", "");
    }

    try {
      await updatePet(token, petData, match.params.petId);
      if (needsDHPPIRL(new Date(), new Date(values.nextDhppiRl))) {
        toast.success(`Necesita la vacuna DHPPI RL`);
      } else if (needsDHPPIRL(new Date(), new Date(values.nextBordetella))) {
        toast.success("Necesita la vacuna Bordetella");
      } else {
        toast.success(`Vacuna eliminada`);
      }
      history.push({
        pathname: `/pet/${match.params.petId}`,
        state: true,
      });
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data);
    }
  };

  const needsDHPPIRL = (now, past) => {
    if (now >= past) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Vacunas</h2>
      </div>

      <div className="container-fluid col-md-8 col-md-offset-4 p-4">
        <div className="row">
          <br />
          <VaccineCreateForm
            values={values}
            setValues={setValues}
            handleVaccine={handleVaccine}
            handleRemove={handleRemove}
          />
        </div>
      </div>

      <div className="m-2 d-flex jutify-content-center">
        {isAdmin(auth.user) ? (
          <>
            <a
              href={`${process.env.REACT_APP_API}/vaccine-format/${match.params.petId}`}
              download
              className="btn-turq m-2 text-center"
              style={{ color: "white" }}
            >
              Vacunas del perro <BsFileSpreadsheet size={30} />
            </a>
            <a
              href={`${process.env.REACT_APP_API}/vaccine-format/`}
              download
              className="btn-turq m-2 text-center"
              style={{ color: "white" }}
            >
              Todas las vacunas <BsFileSpreadsheet size={30} />
            </a>
          </>
        ) : null}
      </div>
    </>
  );
};

export default NewVaccine;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ActionCard from "../components/cards/ActionCard";
import { getPet } from "../actions/pet";
import { getPetActions, deleteAction } from "../actions/control";
import { toast } from "react-toastify";
import { GrLinkPrevious } from "react-icons/gr";
import { Colors } from "../common/Colors";

const PetActions = ({ match, history }) => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const [actions, setActions] = useState([]);
  const [pet, setPet] = useState({});

  useEffect(() => {
    loadPetActions();
    loadPet();
  }, []);

  const loadPet = async () => {
    let res = await getPet(match.params.petId);
    // console.log(res);
    setPet(res.data);
  };

  const loadPetActions = async () => {
    try {
      const res = await getPetActions(match.params.petId);
      setActions(res.data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionDelete = async (actionId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Acción?")) return;
    deleteAction(token, actionId).then((res) => {
      toast.success("Acción eliminada");
      loadPetActions();
    });
  };

  const handleShowMore = (actionId, actionType, petId) => {
    if (actionType === "Entrenamiento") {
      history.push({
        pathname: `/training/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Desparasitación") {
      history.push({
        pathname: `/deworming/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Consulta") {
      history.push({
        pathname: `/consultation/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Vacuna") {
      history.push({
        pathname: `/vaccine/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Test") {
      history.push({
        pathname: `/test/${actionId}`,
        state: {
          petId,
        },
      });
    }
    if (actionType === "Chequeo Diario") {
      history.push({
        pathname: `/daily/${actionId}`,
        state: {
          petId,
        },
      });
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Acciones de {pet.name}</h2>
      </div>

      <div>
        {actions.map((action, iaction) => (
          <ActionCard
            key={`action-card-${iaction}`}
            petName={pet.name}
            action={action}
            handleActionDelete={handleActionDelete}
            handleShowMore={handleShowMore}
          />
        ))}
      </div>
    </>
  );
};

export default PetActions;

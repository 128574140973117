import axios from "axios";

export const createPet = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-pet`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getPet = async (petId) =>
  await axios.get(`${process.env.REACT_APP_API}/pet/${petId}`);

export const getPetImages = async (petId) =>
  await axios.get(`${process.env.REACT_APP_API}/pet/images/${petId}`);

export const rotateImage = async (petId, degrees) =>
  await axios.get(`${process.env.REACT_APP_API}/pet/rotate-image/${petId}`, {
    params: {
      degrees,
    },
  });

export const getPetsInCage = async (cageId) =>
  await axios.get(`${process.env.REACT_APP_API}/pets/cage/${cageId}`);

export const updatePet = async (token, data, petId) =>
  await axios.put(`${process.env.REACT_APP_API}/update-pet/${petId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getPets = async (state) =>
  await axios.get(`${process.env.REACT_APP_API}/pets`, {
    params: {
      state,
    },
  });

export const getPetsByName = async (searchParameter) =>
  await axios.get(`${process.env.REACT_APP_API}/pets/${searchParameter}`);

export const deletePet = async (token, petId) => {
  await axios.delete(`${process.env.REACT_APP_API}/delete-pet/${petId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const transferPet = async (token, petId, cageId) =>
  await axios.put(
    `${process.env.REACT_APP_API}/transfer-pet/${petId}`,
    cageId,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getPetFormat = async () =>
  await axios.get(`${process.env.REACT_APP_API}/pet-format`);

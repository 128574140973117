import { useState, useEffect } from "react";
import { GiMagnifyingGlass, GiHairStrands, GiFrontTeeth } from "react-icons/gi";
import { ImEye } from "react-icons/im";
import { FaDog } from "react-icons/fa";
import { TextField } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  AiFillInfoCircle,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import { Colors } from "../../common/Colors";
import DogEarsImg from "../../assets/img/dog-ears-icon.svg";
import DogNoseImg from "../../assets/img/dog-nose-icon.png";

const DailyCreateForm = ({
  values,
  handleChange,
  handleClick,
  handleSubmit,
  toggles,
  open,
  setOpen,
  translation,
  handleInfo,
  toggleInfo,
  handleComments,
  commentsT,
  handleSave,
}) => {
  const [width, setWidth] = useState(undefined);
  const margin = 8;

  const {
    generalAppearance,
    bodyCondition,
    hairAndSkin,
    eyes,
    ears,
    nose,
    mouth,
    comments,
  } = values;
  const {
    generalAppearanceT,
    bodyConditionT,
    hairAndSkinT,
    eyesT,
    earsT,
    noseT,
    mouthT,
  } = toggles;

  const setIconSizes = () => {
    if (window.innerWidth < 1024) {
      setWidth(window.innerWidth / 2.7 - 50);
    } else {
      setWidth(window.innerWidth / 7 - 100);
    }
  };

  useEffect(() => {
    setIconSizes();
  }, [width]);

  return (
    <>
      <div className="form-group">
        <div className="row">
          <p className="text-center font-face-lm">
            <AiFillInfoCircle
              size={25}
              color={Colors.blue}
              onClick={handleInfo}
            />{" "}
            Condición del perro
          </p>

          {toggleInfo ? (
            <p style={{ fontSize: 13, fontStyle: "italic" }}>
              Toca una vez el aspecto si se encuentra en estado NORMAL o dejalo
              asi para especificar algo distinto.
            </p>
          ) : null}

          <div className="text-center">
            <GiMagnifyingGlass
              size={width}
              className="icon-btn"
              onClick={() => handleClick("generalAppearance")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: generalAppearanceT
                  ? Colors.green
                  : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <FaDog
              size={width}
              className="icon-btn"
              onClick={() => handleClick("bodyCondition")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: bodyConditionT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <GiHairStrands
              size={width}
              className="icon-btn"
              onClick={() => handleClick("hairAndSkin")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: hairAndSkinT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <ImEye
              size={width}
              className="icon-btn"
              onClick={() => handleClick("eyes")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: eyesT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <img
              src={DogEarsImg}
              className="icon-btn"
              onClick={() => handleClick("ears")}
              width={width}
              height={width}
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: earsT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <img
              src={DogNoseImg}
              className="icon-btn"
              onClick={() => handleClick("nose")}
              width={width}
              height={width}
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: noseT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
            <GiFrontTeeth
              size={width}
              className="icon-btn"
              onClick={() => handleClick("mouth")}
              color="white"
              style={{
                margin: margin,
                padding: 10,
                backgroundColor: mouthT ? Colors.green : Colors.black,
                borderRadius: width,
                cursor: "pointer",
              }}
            />
          </div>

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle className="text-center">
              ¿Cuál es el estado de estos aspectos?
            </DialogTitle>
            <DialogContent>
              {generalAppearanceT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="generalAppearance"
                  placeholder={translation["generalAppearance"]}
                  value={generalAppearance}
                  id="outlined-name"
                />
              )}
              {bodyConditionT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="bodyCondition"
                  placeholder={translation["bodyCondition"]}
                  value={bodyCondition}
                  id="outlined-name"
                />
              )}
              {hairAndSkinT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="hairAndSkin"
                  placeholder={translation["hairAndSkin"]}
                  value={hairAndSkin}
                  id="outlined-name"
                />
              )}
              {eyesT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="eyes"
                  placeholder={translation["eyes"]}
                  value={eyes}
                  id="outlined-name"
                />
              )}
              {earsT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="ears"
                  placeholder={translation["ears"]}
                  value={ears}
                  id="outlined-name"
                />
              )}
              {noseT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="nose"
                  placeholder={translation["nose"]}
                  value={nose}
                  id="outlined-name"
                />
              )}
              {mouthT ? null : (
                <TextField
                  onChange={handleChange}
                  fullWidth
                  name="mouth"
                  placeholder={translation["mouth"]}
                  value={mouth}
                  id="outlined-name"
                />
              )}
            </DialogContent>
            <DialogActions className="justify-content-around">
              <button className="btn-turq" onClick={handleSubmit}>
                Continuar
              </button>
            </DialogActions>
          </Dialog>
        </div>

        <p className="text-center mt-1">Comentarios</p>

        <div className="d-flex justify-content-center">
          <AiOutlineCheck
            size={70}
            className="icon-btn"
            onClick={() => handleComments(true)}
            color="white"
            style={{
              margin: margin,
              padding: 10,
              backgroundColor: commentsT ? Colors.green : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
          <AiOutlineClose
            size={70}
            className="icon-btn"
            onClick={() => handleComments(false)}
            color="white"
            style={{
              margin: margin,
              padding: 10,
              backgroundColor: !commentsT ? Colors.pink : Colors.gray,
              borderRadius: 100,
              cursor: "pointer",
            }}
          />
        </div>

        {commentsT ? (
          <TextField
            onChange={handleChange}
            fullWidth
            name="comments"
            placeholder="Comentarios"
            value={comments}
            id="outlined-name"
            multiline
          />
        ) : null}
      </div>

      <div className="d-flex justify-content-center">
        <button className="btn-turq" onClick={handleSave}>
          Guardar
        </button>
      </div>
    </>
  );
};

export default DailyCreateForm;

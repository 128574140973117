import { AiFillDelete } from "react-icons/ai";
import moment from "moment";
import { Colors } from "../../common/Colors";

const CommentCard = ({
  h,
  handleActionDelete = (f) => f,
  handleShowMore = (f) => f,
  showViewMoreButton = true,
}) => {
  return (
    <>
      <div className="card m-1">
        <div className="row no-gutters">
          <div className="col">
            <div className="card-body">
              <p className="card-title">
                <strong>{h.actionType}</strong>{" "}
                <i>
                  {h.postedBy && h.postedBy.name
                    ? `(${h.postedBy.name})`
                    : null}
                </i>
              </p>
              <p>{h.text}</p>
              <p>
                {moment(h.createdAt)
                  .fromNow(false)
                  .replace(/\b[a-z]/, (match) => match.toUpperCase())}
              </p>
              <div className="d-flex justify-content-between h4">
                {showViewMoreButton && (
                  <button
                    onClick={() => handleShowMore(h._id, h.cageMadeFor)}
                    className="btn-turq"
                  >
                    Ver más
                  </button>
                )}
                <AiFillDelete
                  onClick={() => handleActionDelete(h._id)}
                  className="mt-2"
                  style={{ color: Colors.orangeDark }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentCard;

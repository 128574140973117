import { useState } from "react";
import { toast } from "react-toastify";
import { createDaily } from "../actions/control";
import { useSelector } from "react-redux";
import { GrLinkPrevious } from "react-icons/gr";
import DailyCreateForm from "../components/forms/DailyCreateForm";
import { updatePet } from "../actions/pet";
import { Colors } from "../common/Colors";

const NewDaily = ({ match }) => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  // state
  const [toggles, setToggles] = useState({
    generalAppearanceT: false,
    bodyConditionT: false,
    hairAndSkinT: false,
    eyesT: false,
    earsT: false,
    noseT: false,
    mouthT: false,
  });
  const [toggleInfo, setToggleInfo] = useState(false);
  const [commentsT, setCommentsT] = useState(false);
  const [open, setOpen] = useState(false);

  const translation = {
    generalAppearance: "Apariencia general",
    bodyCondition: "Condición corporal",
    hairAndSkin: "Piel y pelo",
    eyes: "Ojos",
    ears: "Oídos",
    nose: "Naríz",
    mouth: "Boca",
  };

  const [values, setValues] = useState({
    generalAppearance: "",
    bodyCondition: "",
    hairAndSkin: "",
    eyes: "",
    ears: "",
    nose: "",
    mouth: "",
    comments: "No",
  });

  const {
    generalAppearance,
    bodyCondition,
    hairAndSkin,
    eyes,
    ears,
    nose,
    mouth,
    comments,
  } = values;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClick = (name) => {
    toast.info(translation[name], {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    let aux = toggles;
    aux[`${name}T`] = !aux[`${name}T`];
    setToggles({ ...aux });
    if (toggles[`${name}T`]) {
      aux = values;
      aux[name] = "Normal";
      setValues({ ...aux });
    } else {
      aux = values;
      aux[name] = "";
      setValues({ ...aux });
    }
  };

  const handleInfo = () => {
    setToggleInfo(!toggleInfo);
  };

  const handleComments = (checked) => {
    let aux = values;
    setCommentsT(checked);
    if (!checked) {
      aux = values;
      aux["comments"] = "No";
      setValues({ ...aux });
    } else {
      aux = values;
      aux["comments"] = "";
      setValues({ ...aux });
    }
  };

  const handleSave = () => {
    for (let [, value] of Object.entries(toggles)) {
      if (value === false) {
        setOpen(true);
        return;
      }
    }

    setOpen(false);
    handleSubmit();
  };

  const handleSubmit = async () => {
    let dailyData = new FormData();
    dailyData.append("madeFor", match.params.petId);
    dailyData.append("generalAppearance", generalAppearance);
    dailyData.append("bodyCondition", bodyCondition);
    dailyData.append("hairAndSkin", hairAndSkin);
    dailyData.append("eyes", eyes);
    dailyData.append("ears", ears);
    dailyData.append("nose", nose);
    dailyData.append("mouth", mouth);
    dailyData.append("comments", comments);

    let petData = new FormData();
    petData.append("checked", !open);
    petData.append("lastDaily", new Date());

    try {
      await createDaily(token, dailyData);
      await updatePet(token, petData, match.params.petId);
      toast.success(`Chequeo diario creado exitosamente!`);
      setTimeout(() => {
        window.location.href = "/pet/" + match.params.petId;
      }, 1000);
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Agregar Chequeo diario</h2>
      </div>
      <div className="container-fluid col-md-8 col-md-offset-4 p-4">
        <div className="row">
          <DailyCreateForm
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            toggles={toggles}
            toggleInfo={toggleInfo}
            handleInfo={handleInfo}
            open={open}
            setOpen={setOpen}
            translation={translation}
            handleClick={handleClick}
            handleComments={handleComments}
            commentsT={commentsT}
            handleSave={handleSave}
          />
        </div>
      </div>
    </>
  );
};

export default NewDaily;

import { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { Avatar } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import { Colors } from "../../common/Colors";
import { isAdmin } from "../../common/Permissions";

const ActionCard = ({
  action,
  petName,
  handleActionDelete = (f) => f,
  handleShowMore = (f) => f,
  showViewMoreButton = true,
}) => {
  const [image, setImage] = useState("");

  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadImage();
  }, []);

  const loadImage = () => {
    setImage(`${process.env.REACT_APP_API}/pet/image/${action.madeFor}`);
    return;
  };

  return (
    <>
      <div className="card m-1">
        <div className="row no-gutters">
          <div className="col m-1 d-flex justify-content-center align-items-center">
            {image ? (
              <Avatar
                src={image}
                style={{
                  width: 120,
                  height: 120,
                }}
              />
            ) : (
              <Avatar
                style={{
                  width: 120,
                  height: 120,
                  fontSize: 15,
                }}
              >
                {petName ? petName : "Sin imagen"}
              </Avatar>
            )}
          </div>
          <div className="col">
            <div className="card-body">
              <p className="card-title font-weight-bold">{action.actionType}</p>
              <p>
                {moment(action.createdAt)
                  .fromNow(false)
                  .replace(/\b[a-z]/, (match) => match.toUpperCase())}
              </p>
              <div className="d-flex justify-content-between h4">
                {showViewMoreButton && (
                  <button
                    onClick={() =>
                      handleShowMore(
                        action._id,
                        action.actionType,
                        action.madeFor
                      )
                    }
                    className="btn-turq text-center"
                  >
                    Ver más
                  </button>
                )}
                {action &&
                  ((auth && isAdmin(auth.user)) ||
                    auth.user._id === action.madeFor) && (
                    <AiFillDelete
                      onClick={() => handleActionDelete(action._id)}
                      className="mt-2"
                      style={{ color: Colors.orangeDark }}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionCard;

import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import { Colors } from "../../common/Colors";
import { isAdmin, isSuperAdmin } from "../../common/Permissions";
import { deleteFile, downloadFile } from "../../actions/drive";
import { isFileAnImage } from "../../utils/strings";
import {
  AiFillDelete,
  AiOutlineDownload,
  AiOutlineFile,
  AiOutlineFileImage,
} from "react-icons/ai";
import { useSelector } from "react-redux";

export default function FileCard({ file, loadFiles }) {
  const [downloading, setDownloading] = useState(false);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleDownloadFile = async (file) => {
    setDownloading(true);
    await downloadFile(token, file);
    setDownloading(false);
  };

  const handleDeleteFile = async (file) => {
    await deleteFile(token, file);
    loadFiles();
  };

  return (
    <>
      {auth && (isAdmin(auth.user) || isSuperAdmin(auth.user)) && (
        <AiFillDelete
          onClick={() => handleDeleteFile(file._id)}
          size={30}
          style={{
            color: Colors.black,
            marginTop: "-1vw",
            marginRight: "20vw",
            zIndex: "1",
          }}
          className="position-absolute pointer"
        />
      )}
      <div className="container">
        <div className="row text-center align-items-center">
          <div className="col">
            <div className="card">
              <div className="card-block p-1 mt-2">
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    lineHeight: "0",
                    letterSpacing: "0",
                  }}
                >
                  <Avatar style={{ backgroundColor: Colors.orange }}>
                    {isFileAnImage(file.title) ? (
                      <AiOutlineFileImage />
                    ) : (
                      <AiOutlineFile />
                    )}
                  </Avatar>
                </div>
                <p className="card-title mx-3 my-3 text-truncate">
                  {file.title}
                </p>
                <p className="card-text mx-auto text-center">
                  <button
                    className={
                      downloading
                        ? "btn-turq ml-0 mb-2 opacity-25"
                        : "btn-turq ml-0 mb-2"
                    }
                    onClick={() => handleDownloadFile(file)}
                    disabled={downloading}
                  >
                    <AiOutlineDownload size={20} />
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

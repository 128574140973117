import { useEffect, useState } from "react";
import { getPet, updatePet } from "../actions/pet";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Checkbox,
  FormGroup,
  FormControlLabel,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { getShowers, deleteAction, createShower } from "../actions/control";
import { GrLinkPrevious } from "react-icons/gr";
import ActionCard from "../components/cards/ActionCard";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsFileSpreadsheet } from "react-icons/bs";
import { Colors } from "../common/Colors";
import { isAdmin, isSuperAdmin } from "../common/Permissions";
import { DatePicker } from "antd";

const Showers = ({ history, match }) => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const { auth } = useSelector((state) => ({ ...state }));

  const [pet, setPet] = useState({});
  const [showers, setShowers] = useState([]);
  const [shower, setShower] = useState({
    comments: "",
    brushBehavior: "",
    dryBehavior: "",
  });
  const [open, setOpen] = useState(false);
  const [addComments, setAddComments] = useState(false);
  const [brushButton, setBrushButton] = useState(0);
  const [dryButton, setDryButton] = useState(0);
  const [showerButton, setShowerButton] = useState(0);
  const [createdAt, setCreatedAt] = useState(null);

  useEffect(() => {
    loadPet();
    loadShowers();
  }, []);

  const loadPet = async () => {
    let res = await getPet(match.params.petId);
    setPet(res.data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setShower({ ...shower, [event.target.name]: event.target.value });
  };

  const getBehavior = (behavior) => {
    if (behavior === 0) return "Normal";
    if (behavior === 1) return "Regular";
    if (behavior === 2) return "Crítico";
  };

  const handleSaveShower = async (e) => {
    e.preventDefault();
    let nextShower = new Date();
    nextShower.setMonth(nextShower.getMonth() + 1);

    let showerData = new FormData();
    if (addComments) {
      showerData.append("comments", shower.comments);
    }
    showerData.append("brushBehavior", getBehavior(brushButton));
    showerData.append("dryBehavior", getBehavior(dryButton));
    showerData.append("showerBehavior", getBehavior(showerButton));
    showerData.append("madeFor", match.params.petId);
    createdAt && showerData.append("createdAt", createdAt);

    let petData = new FormData();
    petData.append("nextShower", nextShower);

    try {
      await createShower(token, showerData);
      await updatePet(token, petData, match.params.petId);
      toast.success(`¡Baño creado exitosamente!`);
      setTimeout(() => {
        window.location.href = "/pet/" + match.params.petId;
      }, 1000);
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data);
    }
  };

  const handleNotClean = async (e) => {
    e.preventDefault();

    if (!window.confirm("¿Estás seguro de que el perro esta sucio?")) return;

    let petData = new FormData();
    petData.append("nextShower", "");

    try {
      await updatePet(token, petData, match.params.petId);
      toast.success(`¡Información actualizada!`);
      setTimeout(() => {
        window.location.href = "/pet/" + match.params.petId;
      }, 1000);
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data);
    }
  };

  const handleActionDelete = async (actionId) => {
    if (!window.confirm("¿Estás seguro de eliminar la Acción?")) return;
    deleteAction(token, actionId).then((res) => {
      toast.success("Acción eliminada");
      loadShowers();
    });
  };

  const handleShowMore = (actionId, _, petId) => {
    history.push({
      pathname: `/shower/${actionId}`,
      state: {
        petId,
      },
    });
  };

  const loadShowers = async () => {
    try {
      const res = await getShowers(match.params.petId);
      setShowers(res.data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const needsShower = (now, next) => {
    return now > next;
  };

  const handleChecked = (event) => {
    setAddComments(event.target.checked);
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Baños</h2>
      </div>

      <div className="m-3 d-flex justify-content-center">
        {needsShower(new Date(), new Date(pet.nextShower)) ||
        !pet.nextShower ? (
          <button className="btn-turq" onClick={() => setOpen(true)}>
            + Agregar Baño
          </button>
        ) : (
          <div>
            <p className="text-center font-weight-bold">
              El perro está limpio{" "}
              <AiFillCheckCircle size={30} style={{ color: "green" }} />
            </p>
            <p className="text-center">
              próximo baño el {new Date(pet.nextShower).toLocaleDateString()}
            </p>
            {isAdmin(auth.user) ? (
              <button className="btn-turq" onClick={handleNotClean}>
                El perro está sucio
              </button>
            ) : null}
            {isAdmin(auth.user) && (
              <button className="btn-turq m-2" onClick={() => setOpen(true)}>
                + Agregar Baño
              </button>
            )}
          </div>
        )}
      </div>

      <div className="m-3 d-flex justify-content-center">
        {isAdmin(auth.user) ? (
          <a
            href={`${process.env.REACT_APP_API}/shower-format/${match.params.petId}`}
            download
            className="btn-turq"
            style={{ color: "white" }}
          >
            <BsFileSpreadsheet size={30} />
          </a>
        ) : null}
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Bañar a {pet.name}</DialogTitle>
        <DialogContent>
          <div className="d-flex justify-content-around flex-wrap mb-3">
            <p className="text-center">
              <strong>
                Comportamiento del perro a los siguientes aspectos
              </strong>
            </p>
            <div>
              <p className="text-center mt-1">Baño</p>
              <ButtonGroup
                size="small"
                orientation="vertical"
                variant="contained"
                color="primary"
                aria-label="small contained primary button group"
              >
                <Button
                  style={{
                    backgroundColor: Colors.greenDark,
                    // showerButton === 0 ? Colors.greenDark : Colors.green,
                    paddingLeft: 0,
                    border: showerButton === 0 ? "0.3rem solid" : null,
                    borderColor: showerButton === 0 ? Colors.black : null,
                  }}
                  onClick={() => setShowerButton(0)}
                >
                  Normal
                </Button>
                <Button
                  style={{
                    backgroundColor: Colors.yellow,
                    //   showerButton === 1 ? Colors.yellowDark : Colors.yellow,
                    paddingLeft: 0,
                    border: showerButton === 1 ? "0.3rem solid" : null,
                    borderColor: showerButton === 1 ? Colors.black : null,
                  }}
                  onClick={() => setShowerButton(1)}
                >
                  Regular
                </Button>
                <Button
                  style={{
                    backgroundColor: Colors.orange,
                    //   showerButton === 2 ? Colors.orangeDark : Colors.orange,
                    paddingLeft: 0,
                    border: showerButton === 2 ? "0.3rem solid" : null,
                    borderColor: showerButton === 2 ? Colors.black : null,
                  }}
                  onClick={() => setShowerButton(2)}
                >
                  Crítico
                </Button>
              </ButtonGroup>
            </div>

            <div>
              <p className="text-center mt-1">Cepillado</p>
              <ButtonGroup
                size="small"
                orientation="vertical"
                variant="contained"
                color="primary"
                aria-label="small contained primary button group"
              >
                <Button
                  style={{
                    backgroundColor: Colors.greenDark,
                    // brushButton === 0 ? Colors.greenDark : Colors.green,
                    paddingLeft: 0,
                    border: brushButton === 0 ? "0.3rem solid" : null,
                    borderColor: brushButton === 0 ? Colors.black : null,
                  }}
                  onClick={() => setBrushButton(0)}
                >
                  Normal
                </Button>
                <Button
                  style={{
                    backgroundColor: Colors.yellow,
                    // brushButton === 1 ? Colors.yellowDark : Colors.yellow,
                    paddingLeft: 0,
                    border: brushButton === 1 ? "0.3rem solid" : null,
                    borderColor: brushButton === 1 ? Colors.black : null,
                  }}
                  onClick={() => setBrushButton(1)}
                >
                  Regular
                </Button>
                <Button
                  style={{
                    backgroundColor: Colors.orange,
                    // brushButton === 2 ? Colors.orangeDark : Colors.orange,
                    paddingLeft: 0,
                    border: brushButton === 2 ? "0.3rem solid" : null,
                    borderColor: brushButton === 2 ? Colors.black : null,
                  }}
                  onClick={() => setBrushButton(2)}
                >
                  Crítico
                </Button>
              </ButtonGroup>
            </div>

            <div>
              <p className="text-center mt-1">Secado</p>
              <ButtonGroup
                size="small"
                orientation="vertical"
                variant="contained"
                color="primary"
                aria-label="small contained primary button group"
              >
                <Button
                  style={{
                    backgroundColor: Colors.greenDark,
                    // dryButton === 0 ? Colors.greenDark : Colors.green,
                    paddingLeft: 0,
                    border: dryButton === 0 ? "0.3rem solid" : null,
                    borderColor: dryButton === 0 ? Colors.black : null,
                  }}
                  onClick={() => setDryButton(0)}
                >
                  Normal
                </Button>
                <Button
                  style={{
                    backgroundColor: Colors.yellow,
                    // dryButton === 1 ? Colors.yellowDark : Colors.yellow,
                    paddingLeft: 0,
                    border: dryButton === 1 ? "0.3rem solid" : null,
                    borderColor: dryButton === 1 ? Colors.black : null,
                  }}
                  onClick={() => setDryButton(1)}
                >
                  Regular
                </Button>
                <Button
                  style={{
                    backgroundColor: Colors.orange,
                    // dryButton === 2 ? Colors.orangeDark : Colors.orange,
                    paddingLeft: 0,
                    border: dryButton === 2 ? "0.3rem solid" : null,
                    borderColor: dryButton === 2 ? Colors.black : null,
                  }}
                  onClick={() => setDryButton(2)}
                >
                  Crítico
                </Button>
              </ButtonGroup>
            </div>
          </div>

          {isAdmin(auth.user) || isSuperAdmin(auth.user) ? (
            <div className="d-flex justify-content-center flex-wrap">
              <DatePicker
                placeholder="Fecha del baño"
                className="form-control mt-3"
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                onChange={(date, dateString) => setCreatedAt(dateString)}
              />
              <span className="font-italic">
                Si no se selecciona la fecha, se asignará la actual
              </span>
            </div>
          ) : null}

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addComments}
                  onChange={handleChecked}
                  color="default"
                />
              }
              label="Comentarios"
            />
          </FormGroup>
          {addComments ? (
            <TextField
              onChange={handleChange}
              fullWidth
              name="comments"
              placeholder="Comentarios"
              multiline
              value={shower.comments}
              id="outlined-name"
            />
          ) : null}
        </DialogContent>

        <DialogActions className="justify-content-around">
          <button className="btn-turq" onClick={handleClose}>
            Cancelar
          </button>
          <button className="btn-turq" onClick={handleSaveShower}>
            Bañar
          </button>
        </DialogActions>
      </Dialog>

      <p className="text-muted m-3">Historial de baños</p>

      <div>
        {showers.map((action) => (
          <ActionCard
            action={action}
            handleActionDelete={handleActionDelete}
            handleShowMore={handleShowMore}
          />
        ))}
      </div>
    </>
  );
};

export default Showers;

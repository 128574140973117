export const Colors = {
  yellow: "#e2c100",
  yelowDark: "#CEB000",
  green: "#00ef61",
  greenDark: "#00CD53",
  orange: "#fc5a00",
  orangeDark: "#D44C00",
  pink: "#e056fd",
  blue: "#3b7cff",
  white: "#ffffff",
  black: "#231f20",
  redPink: "#f90066",
  turquise: "#00c1b7",
  gray: "#95a5a6",
  gold: "#d4af37",
  lightGray: "#EBEDF0",
};

export const ProfileColorList = ["#f56a00", "#7265e6", "#7f8c8d", "#00a2ae"];

import React, { useState, useEffect } from "react";
import { getPet, getPetImages } from "../actions/pet";
import { getLastDaily } from "../actions/control";
import moment from "moment";
import { useSelector } from "react-redux";
import QRCode from "qrcode";
import ClipLoader from "react-spinners/ClipLoader";
import { Avatar, Fab } from "@material-ui/core";
import { FaShower } from "react-icons/fa";
import { GiHypodermicTest, GiMedicines } from "react-icons/gi";
import { RiStethoscopeLine } from "react-icons/ri";
import { MdToday } from "react-icons/md";
import {
  AiOutlineSchedule,
  AiFillCheckCircle,
  AiFillSchedule,
  AiOutlinePlus,
  AiFillCaretLeft,
  AiFillCaretRight,
  AiFillDelete,
} from "react-icons/ai";
import { GrLinkPrevious } from "react-icons/gr";
import { GiWeight } from "react-icons/gi";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { toast } from "react-toastify";
import { Colors } from "../common/Colors";
import { isAdmin } from "../common/Permissions";
import { getStoolRegisters } from "../actions/stool";
import SquareLoader from "react-spinners/SquareLoader";
import ImageIcon from "@material-ui/icons/Image";
import "./pet.styles.css";
import { addPetImage, deletePetImage } from "../actions/pet-images";

const ViewPet = ({ match, history }) => {
  const [pet, setPet] = useState({});
  const [lastDaily, setLastDaily] = useState({});
  const [lastStool, setLastStool] = useState({});
  const [image, setImage] = useState("");
  const [qrImage, setQRImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingPetImages, setLoadingPetImages] = useState(true);
  const [petImages, setPetImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [width, setWidth] = useState(undefined);

  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const setIconSizes = () => {
    if (window.innerWidth < 1024) {
      setWidth(window.innerWidth / 2 - 50);
      return;
    }

    setWidth(window.innerWidth / 9);
  };

  useEffect(() => {
    loadPet();
    setIconSizes();
    loadLastDaily();
  }, [image, width]);

  useEffect(() => {
    loadStoolRegister();
    loadPetImages();
  }, [pet]);

  // useEffect(() => {
  //   console.log(lastStool.stool_register.score);
  // }, [lastStool]);

  const loadStoolRegister = async () => {
    try {
      const res = await getStoolRegisters(match.params.petId, true);

      setLastStool(res.data);
    } catch (error) {
      setLastStool(undefined);
      console.log(error);
    }
  };

  const loadPetImages = async () => {
    try {
      const res = await getPetImages(match.params.petId);

      setPetImages(res.data);

      setLoadingPetImages(false);
    } catch (error) {
      setPetImages([]);
      setLoadingPetImages(false);
      console.log(error);
    }
  };

  const generateQRCode = async () => {
    try {
      const response = await QRCode.toDataURL(`${pet._id}`);

      setQRImage(response);

      window.scrollTo(0, document.body.scrollHeight);
    } catch (error) {
      console.log(error);
    }
  };

  const loadPet = async () => {
    let res = await getPet(match.params.petId);
    setPet(res.data);
    loadImage();
  };

  const loadLastDaily = async () => {
    let res = await getLastDaily(match.params.petId);
    setLastDaily(res.data);
  };

  const loadImage = () => {
    if (pet.image && pet.image.contentType) {
      setImage(`${process.env.REACT_APP_API}/pet/image/${pet._id}`);
      return;
    }
    setImage(null);
    setLoading(false);
  };

  const handleSetImage = (index) => {
    const newIndex = selectedImage + index;

    if (index === "last") {
      setSelectedImage(petImages.length);
    } else if (index === "previous") {
      setSelectedImage(selectedImage === 0 ? 0 : selectedImage - 1);
    } else if (newIndex == -1) {
      setSelectedImage(petImages.length - 1);
    } else {
      setSelectedImage(newIndex % petImages.length);
    }
  };

  const handleAddImage = async (e) => {
    e.preventDefault();
    setLoadingPetImages(true);

    let petData = new FormData();
    petData.append("image", e.target.files[0]);

    try {
      await addPetImage(token, petData, match.params.petId);
      setTimeout(() => {}, 1000);
      await loadPetImages();
      setTimeout(() => {}, 1000);
      handleSetImage("last");
      toast.success(`Nueva imagen de ${pet.name} agregada!`);
    } catch (err) {
      toast.error(err.response.data);
    }

    setLoadingPetImages(false);
  };

  const handleDeleteImage = async (imagePath) => {
    const imageId = imagePath.replace(
      `${process.env.REACT_APP_API}/pet/pet-image/`,
      ""
    );

    try {
      await deletePetImage(token, imageId);
      setTimeout(() => {}, 1000);
      await loadPetImages();
      setTimeout(() => {}, 1000);
      toast.success(`Una imagen de ${pet.name} ha sido eliminada!`);
      handleSetImage("previous");
    } catch (err) {
      toast.error(err);
    }

    setLoadingPetImages(false);
  };

  const handleRoute = (route) => {
    history.push(`/pet/${route}/${pet._id}`);
  };

  const needsShower = (now, next) => {
    if (!pet.nextShower) return true;

    return now > next;
  };

  const hasPassed = (now, past) => {
    if (now < past) return true;

    return false;
  };

  const getVaccineColor = () => {
    if (pet.puppyDp && pet.dhppi && pet.dhppiRl && pet.bordetella)
      return Colors.green;

    if (!pet.puppyDp && !pet.dhppi && !pet.dhppiRl && !pet.bordetella)
      return Colors.orange;

    return Colors.yellow;
  };

  const getDailyColor = () => {
    if (!pet.checked && !pet.lastDaily) return Colors.orange;

    if (!needsDaily(new Date(), new Date(pet.lastDaily)) && pet.checked)
      return Colors.green;

    if (needsDaily(new Date(), new Date(pet.lastDaily))) return Colors.orange;

    if (!needsDaily(new Date(), new Date(pet.lastDaily)) && !pet.checked)
      return Colors.yellow;
  };

  const handleDailyClick = () => {
    if (!pet.checked && !pet.lastDaily) {
      history.push(`newdaily/${match.params.petId}`);
      return;
    }

    if (!needsDaily(new Date(), new Date(pet.lastDaily)) && pet.checked) {
      //all ok
      if (lastDaily) {
        history.push({
          pathname: `/daily/${lastDaily._id}`,
          state: {
            petId: match.params.petId,
          },
        });
      } else {
        toast.info("No se encuentra el documento", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      return;
    }

    //needs daily
    if (needsDaily(new Date(), new Date(pet.lastDaily))) {
      history.push(`newdaily/${match.params.petId}`);
      return;
    }

    //something wrong
    if (!needsDaily(new Date(), new Date(pet.lastDaily)) && !pet.checked) {
      //something wrong
      if (lastDaily) {
        history.push({
          pathname: `/daily/${lastDaily._id}`,
          state: {
            petId: match.params.petId,
          },
        });
      } else {
        toast.info("No se encuentra el documento", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      return;
    }

    history.push(`newdaily/${match.params.petId}`);
  };

  const needsDaily = (now, past) => {
    if (
      now.getDate() > past.getDate() ||
      now.getMonth() > past.getMonth() ||
      now.getFullYear() > past.getFullYear()
    )
      return true;

    return false;
  };

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">{pet.name}</h2>
      </div>
      <div className="container-fluid col-md-8 col-md-offset-4">
        <div className="row">
          <div className="d-flex justify-content-center align-items-center mt-4">
            <br />
            {loading ? (
              <ClipLoader loading={loading} size={200} className="m-3" />
            ) : image ? (
              <Avatar
                src={image}
                style={{
                  width: 310,
                  height: 310,
                }}
              />
            ) : (
              <Avatar
                style={{
                  width: 310,
                  height: 310,
                  fontSize: 20,
                }}
              >
                {pet.name}
              </Avatar>
            )}
          </div>

          {loading ? (
            <ClipLoader loading={true} size={200} className="m-3" />
          ) : (
            <div className="col-md-12">
              <br />
              <h5>
                <b>Comentarios:</b> <i>{pet.comments}</i>
              </h5>
              <h5>
                <b>Edad:</b> {moment(pet.born).fromNow(true)}
              </h5>
              <h5 style={{ color: pet.weight ? null : Colors.orange }}>
                <b>Peso:</b>{" "}
                {pet.weight ? `${pet.weight} kg ` : "Sin registro "}
                <GiWeight
                  size={35}
                  onClick={() => handleRoute("newweight")}
                  style={{
                    marginLeft: 1,
                    padding: 3,
                    backgroundColor: Colors.black,
                    borderRadius: width,
                    cursor: "pointer",
                  }}
                  color="white"
                />
              </h5>
              <h5>
                <b>Tamaño:</b> {pet.size}
              </h5>
              <p className="font-italic ">
                En el Bioterio desde{" "}
                {moment(pet.arrive)
                  .fromNow(false)
                  .replace(/\b[a-z]/, (match) => match.toUpperCase())}
              </p>
              <div className="row mt-3 mb-1">
                <div className="d-flex justify-content-center mt-3 mb-3">
                  {loadingPetImages ? (
                    <SquareLoader />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <h3>Fotos de {pet.name}</h3>
                      {petImages.length > 0 ? (
                        <>
                          <div className="position-relative d-flex justify-content-center align-items-center w-100 h-100">
                            {petImages.length > 1 && (
                              <AiFillCaretLeft
                                className="pointer"
                                size={50}
                                onClick={() => handleSetImage(-1)}
                              />
                            )}
                            <img
                              alt={pet._id}
                              src={petImages[selectedImage]}
                              className="d-block pet-image"
                            />
                            {petImages.length > 1 && (
                              <AiFillCaretRight
                                className="pointer"
                                size={50}
                                onClick={() => handleSetImage(1)}
                              />
                            )}

                            <AiFillDelete
                              onClick={() =>
                                handleDeleteImage(petImages[selectedImage])
                              }
                              size={30}
                              style={
                                petImages.length > 1
                                  ? {
                                      color: Colors.orangeDark,
                                      top: "-2vh",
                                      right: "5vh",
                                    }
                                  : {
                                      color: Colors.orangeDark,
                                      top: "-2vh",
                                      right: "-2vh",
                                    }
                              }
                              className="position-absolute pointer"
                            />
                          </div>
                          <div className="d-flex justify-content-center">
                            <span>
                              {selectedImage + 1} de {petImages.length}
                            </span>
                          </div>
                        </>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center w-100 h-100">
                          <h6>No hay fotos</h6>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-center">
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={handleAddImage}
                    />
                    <Fab
                      component="span"
                      aria-label="add"
                      variant="extended"
                      style={{
                        backgroundColor: Colors.turquise,
                        color: "white",
                      }}
                    >
                      <ImageIcon /> Agregar Imagen
                    </Fab>
                  </label>
                </div>

                <div className="col">
                  <h5 className="text-center">
                    <strong>Color: </strong> {pet.color}
                  </h5>
                </div>
                <div className="col">
                  <h5 className="text-center">
                    <strong>Raza: </strong> {pet.breed}
                  </h5>
                </div>
              </div>
              {pet.locatedIn && pet.locatedIn.name ? (
                <p className="text-center font-weight-bold">
                  Ubicado en {pet.locatedIn.name}
                </p>
              ) : (
                <p className="text-center font-weight-bold">
                  No está ubicado en nunguna jaula!
                </p>
              )}
              <div className="d-flex justify-content-center mb-3">
                {pet.sex === "Hembra" && (
                  <IoMdFemale size={50} color={Colors.pink} />
                )}
                {pet.sex === "Macho" && (
                  <IoMdMale size={50} color={Colors.blue} />
                )}
              </div>
              <div className="d-flex justify-content-center">
                {pet.panel ? (
                  <p className="text-center tag-blue">{pet.panel}</p>
                ) : (
                  <p>Sin panel</p>
                )}
              </div>
              <div className="row mt-3 mb-1">
                {hasPassed(new Date(), new Date(pet.nextDeworming)) ? (
                  <>
                    <h5 className="text-center">
                      <strong>
                        Desparasitado{" "}
                        <AiFillCheckCircle
                          size={30}
                          style={{ color: "green" }}
                        />
                      </strong>
                    </h5>
                    <p className="text-center">
                      Próxima desparasitación en{" "}
                      {moment(pet.nextDeworming).fromNow(true)}
                    </p>
                  </>
                ) : (
                  <p className="text-center">
                    <strong>No desparasitado </strong>
                  </p>
                )}
              </div>

              <div className="stool-holder">
                <h5 className="font-weight-bold">Registro de heces</h5>
                {lastStool ? (
                  <>
                    <div className="position-relative d-flex justify-content-center align-items-center w-100 h-100">
                      <img
                        alt={pet.name}
                        src={lastStool.image_url}
                        className="d-block pet-image"
                      />
                    </div>
                    {lastStool.stool_register && (
                      <div className="d-flex justify-content-center">
                        <span className="font-italic text-center">
                          Registrado hace{" "}
                          {moment(lastStool.stool_register.createdAt).fromNow(
                            true
                          )}{" "}
                          por{" "}
                          {lastStool.stool_register.postedBy.name
                            ? lastStool.stool_register.postedBy.name
                            : "Alguien"}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <span className="font-italic text-center">
                      Aun no se ha hecho ningún registro, ¡agrega alguno!
                    </span>
                  </div>
                )}
                <div className="mt-3 d-flex justify-content-between align-items-center">
                  <AiOutlinePlus
                    size={60}
                    className="icon"
                    onClick={() => history.push(`/new/stool/${pet._id}`)}
                  />
                  <h5
                    className="register"
                    onClick={() => history.push(`/stool/${pet._id}`)}
                  >
                    <AiFillSchedule size={25} className="icon-register" />{" "}
                    Registro
                  </h5>
                </div>
              </div>

              <div className="text-center">
                <AiOutlineSchedule
                  size={width}
                  className="fa fa-xs"
                  onClick={() => handleRoute("actions")}
                  color={Colors.white}
                  style={{
                    margin: 15,
                    padding: 10,
                    backgroundColor: Colors.black,
                    borderRadius: width,
                    cursor: "pointer",
                  }}
                />
                <RiStethoscopeLine
                  size={width}
                  className="fa fa-xs"
                  onClick={() => handleRoute("consultations")}
                  color={Colors.white}
                  style={{
                    margin: 15,
                    padding: 10,
                    backgroundColor: Colors.black,
                    borderRadius: width,
                    cursor: "pointer",
                  }}
                />

                <GiMedicines
                  size={width}
                  className="icon-btn"
                  onClick={() => handleRoute("dewormings")}
                  color={Colors.white}
                  style={{
                    margin: 15,
                    padding: 10,
                    backgroundColor: hasPassed(
                      new Date(),
                      new Date(pet.nextDeworming)
                    )
                      ? Colors.green
                      : Colors.orange,
                    borderRadius: width,
                    cursor: "pointer",
                  }}
                />
                <GiHypodermicTest
                  size={width}
                  className="icon-btn"
                  onClick={() => handleRoute("newvaccine")}
                  color={Colors.white}
                  style={{
                    margin: 15,
                    padding: 10,
                    backgroundColor: getVaccineColor(),
                    borderRadius: width,
                    cursor: "pointer",
                  }}
                />
                <MdToday
                  size={width}
                  className="icon-btn"
                  onClick={() => handleDailyClick()}
                  color={Colors.white}
                  style={{
                    margin: 15,
                    padding: 10,
                    backgroundColor: getDailyColor(),
                    borderRadius: width,
                    cursor: "pointer",
                  }}
                />
                <FaShower
                  size={width}
                  className="icon-btn"
                  onClick={() => handleRoute("showers")}
                  color={Colors.white}
                  style={{
                    margin: 15,
                    padding: 10,
                    backgroundColor: needsShower(
                      new Date(),
                      new Date(pet.nextShower)
                    )
                      ? Colors.orange
                      : Colors.green,
                    borderRadius: width,
                    cursor: "pointer",
                  }}
                />
              </div>

              {auth && isAdmin(auth.user) && (
                <div className="d-flex justify-content-around">
                  <button
                    className="btn-turq mt-3"
                    onClick={() => generateQRCode()}
                  >
                    Generar QR
                  </button>
                  {qrImage ? (
                    <a href={qrImage} download={`${pet.name}-${pet._id}`}>
                      <button className="btn-turq mt-3">Descargar QR</button>
                    </a>
                  ) : (
                    <button className="btn-turq mt-3" disabled>
                      Descargar QR
                    </button>
                  )}
                </div>
              )}
              <div className="d-flex justify-content-center mt-5 mb-5 container-fluid">
                {qrImage ? (
                  <img
                    className="img-fluid"
                    src={qrImage}
                    alt={pet._id}
                    style={{ width: "40%" }}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewPet;

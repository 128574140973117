import React, { useEffect, useState } from "react";
import { Colors } from "../common/Colors";
import { useSelector } from "react-redux";
import { getDriveFiles, uploadFile } from "../actions/drive";
import { Fab, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import SquareLoader from "react-spinners/SquareLoader";
import FileCard from "../components/cards/ FileCard";
import { GrLinkPrevious } from "react-icons/gr";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    marginTop: "40px",
    justifyContent: "center",
  },
});

const DrivePage = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit: ", handleSubmit);
  };

  const loadFiles = async () => {
    setLoading(true);
    let res = await getDriveFiles(token);
    const newFiles = res.data;
    console.log("newFiles: ", newFiles);
    setFiles(newFiles);
    setLoading(false);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();

    try {
      let fileData = new FormData();
      fileData.append("file", e.target.files[0]);
      await uploadFile(token, fileData);
      await loadFiles();
      toast.success(`Nueva archivo agregado!`);
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  useEffect(() => {
    loadFiles();
  }, []);

  return (
    <>
      <div
        className="container-fluid p-3 mb-2 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: Colors.orange, position: "relative" }}
      >
        <GrLinkPrevious
          size={40}
          onClick={() => window.history.back()}
          color={Colors.white}
          style={{
            cursor: "pointer",
            position: "absolute",
            left: 20,
            borderRadius: 50,
            padding: 5,
            backgroundColor: Colors.white,
          }}
        />
        <h2 className="text-white text-center">Drive</h2>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center flex-wrap mt-5">
          <SquareLoader color={Colors.orange} loading={loading} size={100} />
          <h1 className="m-3">Cargando...</h1>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center mt-5">
            <label htmlFor="upload-file">
              <input
                style={{ display: "none" }}
                id="upload-file"
                name="upload-file"
                type="file"
                onChange={handleUploadFile}
              />
              <Fab
                component="span"
                aria-label="add"
                variant="extended"
                style={{
                  backgroundColor: Colors.turquise,
                  color: "white",
                }}
              >
                + Subir nuevo archivo
              </Fab>
            </label>
          </div>
          {files.length > 0 ? (
            <Grid
              container
              spacing={4}
              className={classes.gridContainer}
              justifyContent="center"
              alignItems="center"
            >
              {files.map((file, index) => {
                return (
                  <Grid
                    key={`file-card-${index}`}
                    item
                    container
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <FileCard token={token} file={file} loadFiles={loadFiles} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <h1 className="text-center mt-5">No hay ningun archivo</h1>
          )}
        </>
      )}
    </>
  );
};

export default DrivePage;

import { TextField, Fab } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { Colors } from "../../common/Colors";

const CageEditForm = ({ values, handleChange, handleSubmit }) => {
  const { name, comments, capacity } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <TextField
          onChange={handleChange}
          fullWidth
          name="name"
          placeholder="Nombre"
          value={name}
          id="outlined-name"
          className="mt-3"
        />

        <TextField
          onChange={handleChange}
          fullWidth
          name="comments"
          placeholder="Comentarios"
          value={comments}
          id="outlined-name"
          className="mt-3"
        />

        <TextField
          onChange={handleChange}
          type="number"
          fullWidth
          name="capacity"
          placeholder="Capacidad"
          value={capacity}
          id="outlined-name"
          className="mt-3"
        />
      </div>

      <button type="submit" className="btn-turq">
        Guardar
      </button>
    </form>
  );
};

export default CageEditForm;

import { useHistory, Link } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { Avatar } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPetsInCage } from "../../actions/pet";
import ClipLoader from "react-spinners/ClipLoader";
import { Colors } from "../../common/Colors";
import { isAdmin } from "../../common/Permissions";

const CageCard = ({
  cage,
  handleCageDelete = (f) => f,
  showViewMoreButton = true,
}) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const history = useHistory();
  const [pets, setPets] = useState([]);
  const [isLoadingPets, setIsLoadingPets] = useState(true);

  useEffect(() => {
    loadPetsInCage(cage._id);
  }, []);

  const loadPetsInCage = async (cageId) => {
    const res = await getPetsInCage(cageId);

    setPets(res.data);
    setIsLoadingPets(false);
  };

  return (
    <>
      {cage.isDiningRoom ? (
        <DiningRoomCard
          auth={auth}
          history={history}
          pets={pets}
          isLoadingPets={isLoadingPets}
          cage={cage}
          handleCageDelete={handleCageDelete}
          showViewMoreButton={showViewMoreButton}
        />
      ) : (
        <DefaultCard
          auth={auth}
          history={history}
          pets={pets}
          isLoadingPets={isLoadingPets}
          cage={cage}
          handleCageDelete={handleCageDelete}
          showViewMoreButton={showViewMoreButton}
        />
      )}
    </>
  );
};

const DiningRoomCard = ({
  auth,
  history,
  pets,
  setPets,
  isLoadingPets,
  cage,
  handleCageDelete,
  showViewMoreButton,
}) => {
  return (
    <div className="card mb-3">
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="card-body">
            <h3 className="card-title">
              {" "}
              {cage.name} <span className="float-right text-primary"> </span>{" "}
            </h3>

            <p className="card-text">
              {cage.comments.length > 50
                ? `${cage.comments.substring(0, 50)}...`
                : cage.comments}
            </p>

            <p className="card-text">
              {" "}
              Activa desde {moment(cage.activeFrom).fromNow()} (
              {new Date(cage.activeFrom).toLocaleDateString()}){" "}
            </p>

            <div className="d-flex justify-content-between h4">
              <Link to={{ pathname: "/cages" }} className="btn-turq invisible">
                + Ingresar Perro
              </Link>
              {auth && isAdmin(auth.user) && (
                <AiFillEdit
                  style={{ color: Colors.yellow }}
                  onClick={() => history.push(`/cage/edit/${cage._id}`)}
                />
              )}
              {auth && isAdmin(auth.user) && (
                <AiFillDelete
                  onClick={() => handleCageDelete(cage._id)}
                  style={{ color: Colors.orangeDark }}
                />
              )}
              {showViewMoreButton && (
                <button
                  onClick={() => history.push(`/cage/${cage._id}`)}
                  className="btn-turq"
                >
                  Ver Más
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DefaultCard = ({
  auth,
  history,
  pets,
  setPets,
  isLoadingPets,
  cage,
  handleCageDelete,
  showViewMoreButton,
}) => {
  return (
    <div className="card mb-3">
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="card-body">
            <h3 className="card-title">
              {" "}
              {cage.name} <span className="float-right text-primary"> </span>{" "}
            </h3>

            <p className="card-text">
              {cage.comments.length > 50
                ? `${cage.comments.substring(0, 50)}...`
                : cage.comments}
            </p>

            <p className="card-text">
              {" "}
              Activa desde {moment(cage.activeFrom).fromNow()} (
              {new Date(cage.activeFrom).toLocaleDateString()}){" "}
            </p>

            <div className="d-flex">
              Capacidad Total
              <Avatar
                style={{
                  fontSize: 12,
                  backgroundColor: "black",
                  width: 24,
                  height: 24,
                  marginLeft: 5,
                }}
              >
                {" "}
                {cage.capacity}{" "}
              </Avatar>
            </div>

            <div className="d-flex justify-content-between h4">
              <Link
                to={{ pathname: "/pets/new", cageId: cage._id }}
                className="btn-turq"
                style={{ textDecoration: "none" }}
              >
                + Ingresar Perro
              </Link>
              {auth && isAdmin(auth.user) && (
                <AiFillEdit
                  style={{ color: Colors.yellow }}
                  onClick={() => history.push(`/cage/edit/${cage._id}`)}
                />
              )}
              {auth && isAdmin(auth.user) && (
                <AiFillDelete
                  onClick={() => handleCageDelete(cage._id)}
                  style={{ color: Colors.orangeDark }}
                />
              )}
              {showViewMoreButton && (
                <button
                  onClick={() => history.push(`/cage/${cage._id}`)}
                  className="btn-turq"
                >
                  Ver Más
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-4 d-flex justify-content-center align-items-center">
          {isLoadingPets ? (
            <ClipLoader loading={isLoadingPets} size={100} />
          ) : (
            <div className="text-center">
              <h4>Perros en {cage.name}</h4>
              {pets.map((pet, index) => {
                return <h6 key={`pet-list-${index}`}>{pet.name}</h6>;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CageCard;
